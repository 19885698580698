import { useControllableValue } from 'ahooks';
import * as React from 'react';

export interface ICheckBoxProps {
  checked: boolean;
  children?: any;
  onChange?: (checked: boolean) => void;
  icon?: any;
  checkedIcon?: any;
  onAfterClick?: (val: any) => void;
  align?: 'center' | 'flex-start' | 'baseline' | 'flex-end';
  justify?: 'center' | 'flex-start' | 'baseline' | 'flex-end' | 'space-between';
  style?: React.CSSProperties;
}

const classPrefix = 'qk-checkbox';

export function CheckBox(props: ICheckBoxProps) {
  const { icon, checkedIcon, align = 'center', justify, style } = props;
  const [checked, setChecked] = useControllableValue(props, {
    defaultValue: props.checked,
    valuePropName: 'checked',
    trigger: 'onChange',
  });

  return (
    <div
      className={`${classPrefix}`}
      onClick={() => {
        setChecked(!checked);
        if (!checked) {
          props.onAfterClick?.(1);
        } else {
          props.onAfterClick?.(0);
        }
      }}
      style={{ ...style, alignItems: align, justifyContent: justify }}
    >
      <img id="icon" src={checked ? checkedIcon || icon : icon} alt="" />
      {props.children}
    </div>
  );
}
