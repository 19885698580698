import { Toast } from '@quark-base-plugin/core/index';
import { useForm, DefaultFormItemWrapper } from '@quark-base-plugin/core/src/components/form';
import { Http, HttpResponse } from '@quark-base-plugin/net';
import { useRequest } from 'ahooks';
import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { BasicDictResult } from './types';
import { Analysis, AnalysisThirdEventName } from '@quark-base-plugin/analysis';
import { storage } from '@quark-base-plugin/storage';

export type UseApplyStepModuleProps<T> = {
  code?: string;

  onLoad?: () => Promise<T>;
  onSave?: (v: T) => Promise<HttpResponse<T> | void> | void;

  /**
   * 加载数据完成之后自定义转换数据
   */
  loadDataTransformer?: (v: any) => Record<string, any> | Promise<Record<string, any>>;
  /**
   * 保存数据之前自定义转换需提交的数据
   */
  saveDataTransformer?: (v: any) => Record<string, any> | Promise<Record<string, any>>;

  nextPage?: string;
  callback?: () => void;

  defaultValues?: Record<string, any>;
};

function isPromise(obj: unknown): obj is Promise<unknown> {
  return !!obj && typeof obj === 'object' && typeof (obj as any).then === 'function';
}

//查询基本信息字典
export async function loadBasicDict(): Promise<HttpResponse<BasicDictResult>> {
  return await Http.post('/dictInfo');
}

export function useApplyStepModule<T = any>(props: UseApplyStepModuleProps<T>) {
  const {
    code,
    onLoad,
    onSave,
    loadDataTransformer,
    saveDataTransformer,
    defaultValues,
    nextPage,
    callback,
  } = props;

  const history = useHistory();

  // 加载已填写的数据
  const loadResult = useRequest(
    async () => {
      if (onLoad) {
        const data = await onLoad?.();
        if (loadDataTransformer) {
          const mapData = loadDataTransformer(data);
          // 如果是异步函数的处理
          if (isPromise(mapData)) {
            try {
              const result: any = await mapData;
              return { ...defaultValues, ...result };
            } catch (e) {
              return { ...defaultValues };
            }
          }
          return { ...defaultValues, ...mapData };
        }
        return { ...defaultValues, ...data };
      } else {
        if (loadDataTransformer) {
          const mapData = loadDataTransformer(defaultValues);
          // 如果是异步函数的处理
          if (isPromise(mapData)) {
            try {
              const result: any = await mapData;
              return { ...defaultValues, ...result };
            } catch (e) {
              return { ...defaultValues };
            }
          }
          return { ...defaultValues, ...mapData };
        }
        return { ...defaultValues };
      }
    },
    {
      cacheKey: `apply-step-load-data-${code}`,
      cacheTime: 500,
      manual: true,
      debounceWait: 300,
    }
  );

  const dictResult = useRequest(loadBasicDict, {
    cacheKey: 'apply-step-load-dict-info',
    staleTime: 50000,
  });

  const saveRequest = useRequest(async (params) => onSave?.(params), { manual: true });

  const { Form, errors, getErrorMessageInfo, values, ...formRef } = useForm<any>({
    defaultValues: loadResult.runAsync,
    renderComponent: DefaultFormItemWrapper,
    mode: 'onBlur',
  });

  useEffect(() => {
    const message = getErrorMessageInfo();
    if (message) {
      Toast.show(message);
    }
  }, [/* JSON.stringify(errors || {}), */ getErrorMessageInfo]);


  const hasNullKeys = useMemo(() => {
    const value = values;
    const arr = [];
    for (const key in value) {
      if (!value[key] && value[key] !== 0) {
        arr.push(key);
      }
    }
    return arr;
  }, [values]);

  /**
   * 绑定于 onClick 的提交信息事件
   * @param values
   */
  async function triggerSubmit(values?: any): Promise<boolean> {
    formRef.formRef.clearErrors();
    // TODO: 暂时取消
    const validationResult = await formRef.formRef.trigger(undefined, {
      shouldFocus: true,
    });
    if (!validationResult) {
      return false;
    }

    let newValues = values || formRef.formRef.getValues();
    const attachmentIds = await storage.get('attachmentsIds');
    if (saveDataTransformer) {
      const mappedValues = saveDataTransformer({ ...newValues, attachmentIds });
      // 处理 promise
      if (isPromise(mappedValues)) {
        try {
          newValues = await mappedValues;
        } catch (e) {
          console.log('saveDataTransformer data error: ', e);
          return false;
        }
      } else {
        newValues = mappedValues;
      }
    }

    const events: { [key: string]: AnalysisThirdEventName[] } = {
      ['useApplyStepBasic']: [
        AnalysisThirdEventName.CustomCommitBaseInfo,
        AnalysisThirdEventName.CustomCommitBaseInfoSuccess,
      ], // 基本信息
      ['useApplyStepWork']: [
        AnalysisThirdEventName.CustomCommitWorkInfo,
        AnalysisThirdEventName.CustomCommitWorkInfoSuccess,
      ], // 工作信息
      ['useApplyStepContact']: [
        AnalysisThirdEventName.CustomCommitContactInfo,
        AnalysisThirdEventName.CustomCommitContactInfoSuccess,
      ], // 紧急联系人信息
      ['useApplyStepWithdraw']: [
        AnalysisThirdEventName.CustomCommitBankInfo,
        AnalysisThirdEventName.CustomCommitBankInfoSuccess,
      ], // 银行信息
    };
    const [event, eventSuccess] = code && events[code] ? events[code] : [];
    if (event) {
      Analysis.logEvent({ event }); // 埋点：提交【基本信息/工作信息/紧急联系人信息/银行信息】
    }
    const res = await saveRequest.runAsync?.(newValues);

    if (res && res.success) {
      if (eventSuccess) {
        Analysis.logEvent({ event: eventSuccess }); // 埋点：提交【基本信息/工作信息/紧急联系人信息/银行信息】成功
      }
      if (!callback) {
        if (nextPage) {
          // 进入下一步
          history.push(nextPage);
          // 埋点操作
          const eventsNext: { [key: string]: AnalysisThirdEventName } = {
            ['useApplyStepBasic']: AnalysisThirdEventName.CustomGoToWorkInfo, // 基本信息 下一页
            ['useApplyStepWork']: AnalysisThirdEventName.CustomGoToContactInfo, // 工作信息 下一页
            ['useApplyStepContact']: AnalysisThirdEventName.CustomGoToBankInfo, // 紧急联系人信息 下一页
          };
          const eventNextPage = code && eventsNext[code] ? eventsNext[code] : undefined;
          if (eventNextPage) {
            Analysis.logEvent({ event: eventNextPage }); // 埋点：进入【工作信息/紧急联系人信息/银行信息】页面
          }
        }
      } else {
        // 返回
        callback();
      }
    }
    return !!res?.success;
  }

  return {
    Form,
    triggerSubmit,
    dictResult,
    values,
    ...formRef,
    saveRequest,
    hasNullKeys,
    loadResult,
  };
}
