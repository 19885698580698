import { Http, HttpResponse } from '@quark-base-plugin/net';
import { useRequest, useMemoizedFn, useUpdateEffect, useLocalStorageState } from 'ahooks';
import momentTimezone from 'moment-timezone';
import React, { useEffect } from 'react';

import { getProductList, Product } from '../product';

import { useUserContext } from './user';

const _CACHE_PRODUCT_KEY = '_CACHE_SUBMIT_PRODUCT_KEY';

export type TimeZoneInfo = {
  timeZone: string;
};

//获取时区
export async function getTimeZone(): Promise<HttpResponse<TimeZoneInfo>> {
  return Http.post('/app-config/get-time-zone');
}

export type GlobalContextValue = {
  timeZone: string | undefined;
  products: Product[];
  formatDate: (timeinterval: any, format?: string) => string;
  loadingProducts?: boolean;
};

const GlobalContext = React.createContext<GlobalContextValue | null>(null);
GlobalContext.displayName = 'GlobalContext';

export type GlobalProviderProps = {
  children: React.ReactNode;
  /**
   * 自定义加载组件
   */
  loadingComponent?: React.ReactNode;
};

const GlobalProvider = (props: GlobalProviderProps) => {
  const { children, loadingComponent } = props;

  const {
    data: timeZone,
    // run: runGetTimeZone,
    // loading: loadingTimeZone,
  } = useRequest(getTimeZone, {
    // manual: true,
    cacheKey: 'qk-time-zone',
  });

  const {
    data: products,
    run: runGetProductList,
    loading: loadingProducts,
  } = useRequest(getProductList, {
    manual: true,
    // retryCount: 3,
    cacheKey: 'product-getProductList',
    staleTime: 50000,
  });

  const { user } = useUserContext();

  useEffect(() => {
    if (user) {
      runGetProductList();
    }
  }, [user]);

  const [product, setProduct] = useLocalStorageState<Product | undefined>(_CACHE_PRODUCT_KEY);
  
  // useUpdateEffect(() => {
  //   if (products && products.data && products.data.length >= 0) {
  //     setProduct(products.data[0]);
  //   } else {
  //     setProduct(undefined);
  //   }
  // }, [products]);

  const formatDate = useMemoizedFn(
    (timeinterval: any, format: 'EN' | 'IN' | 'MX' | string = 'EN'): string => {
      // let f = 'DD/MM/YYYY';
      let f = 'DD-MM-YYYY';
      switch (format) {
        case 'EN':
          f = 'DD-MM-YYYY';
          break;
        case 'IN':
          f = 'DD-MM-YYYY';
          break;
        case 'MX':
          f = 'DD-MM-YYYY';
          break;
        default:
          if (format) {
            f = format;
          }
          break;
      }
      if (timeZone?.data?.timeZone) {
        return momentTimezone(timeinterval).tz(timeZone?.data?.timeZone)?.format(f);
      }
      return '';
    }
  );

  const value = React.useMemo(() => {
    return {
      timeZone: timeZone?.data?.timeZone,
      products: products?.data || [],
      formatDate,
      loadingProducts,
    };
  }, [timeZone, products, formatDate, loadingProducts]);

  const loading = loadingProducts;

  if (loading) {
    return loadingComponent ? <>{loadingComponent}</> : <div></div>;
  }
  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};

function useGlobalContext() {
  const context = React.useContext(GlobalContext);
  if (!context) {
    throw new Error(`GlobalContext must be used within an GlobalProvider`);
  }
  return context;
}

export { GlobalProvider, useGlobalContext };
