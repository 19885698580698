export default {
  homeTab: 'Préstamo',
  repayTab: 'Registros de préstamos',
  messageTab: 'Notificaciones',
  myTab: 'A mí',
  applyAgain: 'Aplicar nuevamente',
  chooseDiffTerms: 'Clic para elegir diferentes términos',
  applyNow: 'Prestar dinero',
  withdraw: 'Retirar',
  days: 'días',
  day: 'día',
  applyGuide: 'Guía de Préstamos',
  clickToView: 'Click para ver',
  learnAbout: 'Requisitos y Documentación para la Solicitud de Préstamo',
  choosePollo: 'Elige N Money',
  obtainCash: 'Obtenga efectivo en cinco minutos',
  financialQualifications: 'Acreditación Financiera',
  safeAndReliable: 'Seguro y Confiable',
  digitalTechnology: 'Tecnología Digital',
  aIApproval: 'Aprobación Rápida con IA',
  onlineApproval: 'Solicitud en Línea',
  realtime: 'Desembolso Inmediato',
  anytime: 'Préstamos Flexibles',
  warning:
    'Advertencia: Proporcione información de préstamo válida y real. La información falsa no pasará la revisión.',
  upTo: 'Monto Máximo',
  chooseWarn:
    'Debido a comportamientos de pago tardío, podrá solicitar un préstamo después de 14 días.',
  chooseWarn2: 'Después de realizar el pago, puedes solicitar más préstamos',
  whyDesc1:
    'N Money: ¡Su socio de crédito! Nos enorgullece tener calificaciones financieras legítimas y ofrecerle soluciones de préstamos personales seguros y confiables. Preste con tranquilidad, confíe en nosotros, ya que su seguridad financiera es nuestra máxima prioridad.',
  whyDesc2:
    'N Money: ¡Liderando el futuro de los préstamos tecnológicos! Con nuestras sólidas capacidades tecnológicas, brindamos aprobaciones inteligentes con la ayuda de la inteligencia artificial, lo que agiliza el proceso de préstamo. Confíe en nuestra IA y experimente la nueva era de los préstamos de crédito.',
  home_1: 'Aviso de vacaciones',
  home_2:
    'Estimados usuarios, debido al inicio de las vacaciones de Año Nuevo, habrá un cierto retraso en la llegada del préstamo que solicitaron durante el período de vacaciones (1 de enero al 3 de enero). Consulte la hora del depósito bancario para conocer la hora específica de llegada. Gracias por su apoyo y comprensión. Les deseo unas felices vacaciones.',
  home_3: 'Entendido',
  welcomeHome: 'Hola y bienvenido/a a N Money.',
  currentLimit: 'Tu límite actual',
  howMuch: '¿Cuánto dinero necesitas?',
  loanAmountMsg: 'Por favor, ingresa la cantidad del préstamo.',
  howLong: '¿Cuánto tiempo deseas utilizar este préstamo?',
  agreedMsg: 'He leído y acepto el',
  Agreement: 'Contrato de Préstamo',
  fillForm: 'Completar el formulario',
  getCash: 'Conseguir dinero en efectivo',
  yourLoanApplication: 'Detalles de tu solicitud de préstamo:',
  loanAmount: 'Monto del préstamo',
  totalloanAmount: 'Monto total del préstamo',
  loanTerm: 'Plazo del préstamo',
  maximumLoanAmount: 'Monto máximo del préstamo',
  minute: 'minuto',
  whatloan: '¿Cuál es la duración deseada para este préstamo?',
  creditAssessment: 'Asesor de crédito',
  verification: 'Verificación',
  completed: 'Terminado',
  notCompleted: 'Sin completar',
  // 新
  homeStartInfo1: 'Solicitud online en ',
  homeStartInfo2: 'minuto/aprobación en ',
  homeStartInfo3: 'minutos/préstamo en',
  homeStartInfo4: 'minutos',
  applyImmediately: 'Aplicar inmediatamente',
  productDescription: 'Descripción del Producto',
  sufficientAmount: 'Monto Suficiente',
  loanAmountUpto: 'Monto del préstamo hasta',
  flexibleMultipleTerms: 'Flexible y Multicondicional',
  homeDescriptionInfo1: 'El número de periodos de amortización es flexible y opcional, hasta',
  periods: 'periodos',
  lowCost: 'Bajo costo',
  homeServiceFee: 'Tarifa de servicio Capital*0.21',
  serviceFee: 'Cargo por servicio',
  homeInterest: 'Interés: 0.005*número de días',
  homePenaltyInterest: 'Intereses de penalización: 0.005*número de días',
  homeStartInfo5: 'El monto del pago final estará sujeto al monto total real a pagar.',
  userStories: 'Historias de usuarios, nos eligieron',
  chooseLoanProduct: 'Elija el producto de préstamo que más le convenga',
  afterLogging: 'Más emocionante después de iniciar sesión',
  homeModalServiceFee: 'La tarifa del servicio incluye:',
  laborAndPlatform: 'Tarifas de servicio de mano de obra y plataforma',
  dataServiceFee: 'Tarifa de servicio de datos',
  bankPaymentFees: 'Comisiones de pago bancario ',
  taxes: 'Impuesto  ',
  capital: ' principal',
  iSee: 'Veo',
  creditEvaluation: 'Evaluación crediticia',
  creditEvaluationInfo:
    'Aún no se ha completado, complétela inmediatamente y el préstamo se aprobará en segundos',
  second: 'Segundo',
  loan: 'préstamo',
  totalLoan: 'Monto total del préstamo solicitado',
  applicationTime: 'Tiempo de aplicación',
  homeRepayCard: {
    info1: 'Próxima fecha de pago',
    info2: 'El pago está en curso',
    info3:
      'La información de pago de su préstamo ha sido enviada al banco. Consulte el horario de servicio del banco local para conocer la hora específica de llegada.',
    info4: 'Cuentas por cobrar',
    info5: 'Nombre del banco',
    info6: 'Titular de la cuenta',
    info7: 'Cuentas por cobrar',
    info8: 'Cuenta de transferencia intermedia',
    info9: 'Pago pendiente',
    info10: 'Monto total a pagar este período',
    info11: 'Última Fecha de Pago',
    info12: 'Pagar',
    info13:
      'Pague el préstamo a tiempo y podrá seguir pidiendo dinero prestado una vez que se liquide el préstamo.',
    info14: 'cargo del pago atrasado',
    info15: 'días de retraso',
    info16: 'Caducado',
  },
  homeRepay: {
    loanDisbursed: 'Préstamo en proceso de desembolso',
    repaymentBtnTitle: 'Pagar',
    repayInfo1:
      'La información de desembolso de su préstamo ha sido enviada al banco. Consulte el horario de servicio del banco local para conocer la hora específica de llegada.',
    repayInfo2: 'Próxima fecha de pago',
    repayInfo3: 'Factura impaga',
    repayInfo4: 'Quedan días',
    repayInfo5: 'Total de la factura',
    repayInfo6: 'Fecha final de amortización',
    repayInfo7: 'Servicios de préstamo disponibles después del pago',
    repayInfo8:
      '*Al utilizar el servicio de préstamo, el efectivo se puede esperar en aproximadamente 5 minutos.No disponible después de que la factura esté vencida.',
    repayInfo9: 'Cantidad',
    repayInfo10: 'Semestre',
    repayInfo11: 'días de retraso',
    repayInfo12: 'Fecha final de amortización',
    repayInfo13: 'Revisión',
    repayInfo14: 'Importe restante a pagar',
    repayInfo15: 'Monto reembolsado',
    repeyInfo16:
      'Pague el préstamo a tiempo y podrá continuar pidiendo dinero prestado una vez que se liquide el préstamo.',
    repayInfo17: 'Paga inmediatamente',
  },
};
