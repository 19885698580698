import { uuid } from './uuid';

declare const window: Window & { ios_native: any; android_native: any } & any;

export interface NativeCallParams {
  pluginName: string;
  uid: string;
  method: string;
  params: string;
  callback?: string;
}

export interface NativeResult<T> {
  code: number;
  message: string;
  data: T;
  uid?: string; // 兼容旧的
  success: boolean; // 兼容旧的
  exception?: string; // 兼容旧的
}

function __nativeCallBack(result: any) {
  const { uid } = result;
  const resolve = window.__nativeCallBackIds[uid];
  if (!resolve) {
    return;
  }
  delete window.__nativeCallBackIds[uid];
  resolve(result);
}

const LastTrigger = {
  method: '',
  time: 0,
};

class NativeCall {
  //TODO 这里分别设置iOS和Android的交互对象
  private static iosNativeBridge = window.ios_native;
  private static androidNativeBridge = window.android_native;

  public static callPlugin<T>(
    pluginName: string,
    method: string,
    params?: any
  ): Promise<NativeResult<T>> {
    //!兼容的旧方法
    if (window.WebViewJavascriptBridge || window.__android) {
      if (!window.__nativeCallBack) {
        window.__nativeCallBack = __nativeCallBack;
        window.__nativeCallBackIds = {};
      }

      const uid = uuid();
      const data = {
        uid,
        pluginName,
        method,
        params,
        callback: 'window.__nativeCallBack',
      };

      const promise = new Promise<any>((resolve, reject) => {
        LastTrigger.method = method;
        LastTrigger.time = new Date().getTime();
        if (window.WebViewJavascriptBridge) {
          window.WebViewJavascriptBridge.callHandler('call', data, (r: any) => {
            resolve(r);
          });
        } else if (window.__android) {
          window.__nativeCallBackIds[uid] = resolve;
          window.__android.call(JSON.stringify(data));
        } else {
          // resolve(call(data));
          reject('current platform is WEB, method not supported');
        }
      });
      return promise;
    }

    if (
      this.iosNativeBridge &&
      this.iosNativeBridge[pluginName] &&
      typeof this.iosNativeBridge[pluginName][method] === 'function'
    ) {
      // console.log('method ' + pluginName + '.' + method + ' native callBack:', params);
      return this.callIosPlugin(pluginName, method, params);
    } else if (this.androidNativeBridge) {
      return this.callAndroidPlugin(pluginName, method, params);
    } else {
      return new Promise((resolve, rejct) => {
        console.warn(`pluginName: 【${pluginName}】 not method: 【${method}】`);
        resolve({} as NativeResult<T>);
        // rejct({ code: -10, message: 'pugin or method nor found' });
      });
    }
  }

  public static isNative() {
    if (this.iosNativeBridge) {
      return true;
    }
    if (this.androidNativeBridge) {
      return true;
    }
    if (window.WebViewJavascriptBridge || window.__android) {
      return true;
    }
    return false;
  }

  public static getPlatform(): 'ios' | 'android' | 'h5' {
    if (window.ios_native || window.WebViewJavascriptBridge) {
      return 'ios';
    }
    if (this.androidNativeBridge || window.__android) {
      return 'android';
    }
    return 'h5';
  }

  private static callAndroidPlugin<T>(
    pluginName: string,
    method: string,
    params?: any
  ): Promise<NativeResult<T>> {
    let callBackFn: (data: NativeResult<T>) => void;
    const promise = new Promise((resolve: (data: NativeResult<T>) => void, rejct) => {
      callBackFn = resolve;
      // rejct({ code: -10, message: 'pugin or method nor found' });
    });
    const callBackMethod = (data: any) => {
      console.log('method ' + pluginName + '.' + method + ' native callBack:', data);
      // console.log('method ' + pluginName + '.' + method + ' native callBack:' + str);
      // const data = JSON.parse(str) as NativeResult<T>;
      data.data = data?.code != -2 && data?.data ? JSON.parse(data.data) : data.data;
      callBackFn(data);
    };
    const callBackMethodName = method + uuid().substring(0, 4);
    // @ts-ignore
    window[callBackMethodName] = callBackMethod;
    const callParams: NativeCallParams = {
      callback: callBackMethodName,
      uid: '',
      pluginName: pluginName,
      method: method,
      params: params,
    };
    console.log('method ' + pluginName + '.' + method + ' calling==>');
    this.androidNativeBridge && this.androidNativeBridge.call(JSON.stringify(callParams));

    return promise;
  }
  private static callIosPlugin<T>(
    pluginName: string,
    method: string,
    params?: any
  ): Promise<NativeResult<T>> {
    let callBackFn: (data: NativeResult<T>) => void;
    let rejectFn: (resea: any) => void;
    const promise = new Promise((resolve: (data: NativeResult<T>) => void, rejct) => {
      callBackFn = resolve;
      rejectFn = rejct;
      // rejct({ code: -10, message: 'pugin or method nor found' });
    });
    const runIosCall = async () => {
      try {
        console.log('Ios method ' + pluginName + '.' + method + ' calling==>');
        const result = await this.iosNativeBridge[pluginName][method](params);
        console.log('Ios method ' + pluginName + '.' + method + ' callBack==>', result);
        callBackFn(result);
      } catch (e) {
        console.log('Ios method ' + pluginName + '.' + method + ' exception:', e);
        rejectFn(e);
      }
    };
    runIosCall();
    return promise;
  }
}

export default NativeCall;
