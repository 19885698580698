import { Plugins } from '@capacitor/core';
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';
import { setupIonicReact, createAnimation } from '@ionic/react';
import { Analysis } from '@quark-base-plugin/analysis';
import { Spinner, Toast } from '@quark-base-plugin/core';
import { AppConfigration, AppContextProvider } from '@quark-base-plugin/modules/app/app';
import { AppDeviceInfo, AppDevicePlatform } from '@quark-base-plugin/modules/app/types';
import { Http } from '@quark-base-plugin/net';
import { notificationStore } from '@quark-base-plugin/storage';
import { useMemoizedFn } from 'ahooks';
import React from 'react';
import { NativeBridge } from '@/native/index';
import { isPlatform } from '@/judgmentSystem';

import { config } from './config';
import { AppRoutes } from './routes';

import './i18n';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.scss';
import './theme/global.scss';
import { CustomErrorBlock } from './components/errorBlock';

import { useTranslation } from 'react-i18next';

import { languages, useStoredLanguage } from './languages';
// import '@quark-base-plugin/finger-print-id';
/** Provider */

const IS_RELOAD = '_IS_RELOAD';

setupIonicReact({
  mode: 'ios',
  //TODO: 去除导航动画
  navAnimation: () => createAnimation('qk-nav-animation'),
  hardwareBackButton: false,
});

// 设置 api base url
Http.setBaseUrl(config.h5_base_url[config.env]);
// Analysis.register([FirebaseAnalyticsProvider, BranchAnalyticsProvider, FBAnalyticsProvider]);

// 注册通知、Toast提示
notificationStore.registerSubscription((notification) => {
  if (notification.type === 'toast') {
    if (notification.message) {
      Toast.show({
        message: notification.message,
      });
    }
  }
});

Toast.config({
  position: 'middle',
});

//TODO:
// Analysis.register(...providers);
Analysis.init({});

// 配置 app 马甲包id
const configuration: AppConfigration = {
  appInfo: {
    appInfoId: isPlatform() === 'android' ? 14516 : 14016,
    merchantId: '14',
    appVersion: '1.0',
  },
  specifyLanguage: 'es-PE',
  loadingComponent: <Spinner />,
  errorComponent: (
    <CustomErrorBlock
      status="error"
      fullPage
      description="Error del sistema, por favor, inténtalo de nuevo～"
    />
  ),
  networkErrorComponent: (
    <CustomErrorBlock
      status="disconnected"
      fullPage
      description="Error de red, inténtalo de nuevo～"
    />
  ),
};

const App: React.FC = () => {
  const { i18n } = useTranslation();
  const { storedLannguage, setStoredLannguage } = useStoredLanguage();

  //获取设备信息
  async function PluginGetDeviceInfo() {
    return await NativeBridge.Plugins.appPlugin.getEquipmentInfo();
  }
  // const [getDeviceInfoNums, setGetDeviceInfoNums] = useState(0)

  // const fcmTimer = setInterval(()=>{
  //   // setGetDeviceInfoNums(getDeviceInfoNums+1)
  //   getDeviceInfoFn()
  // },10000)

  const onInited = useMemoizedFn(async () => {
    if (storedLannguage) {
      i18n.changeLanguage(storedLannguage);
    }
  });

  const getDeviceInfoFn = useMemoizedFn(async () => {
    // const H5DeviceInfo = await Plugins.QKDevice.getInfo();
    let info = {};
    info = (await PluginGetDeviceInfo()) as any;
    //@ts-ignore
    if (!info?.data?.fcmToken) {
      setTimeout(async () => {
        info = (await PluginGetDeviceInfo()) as any;
      }, 10000);
    }
    let FPInfo: { id?: string } = {};
    // if (isPlatform() === 'ios'){
    //   // FPInfo = await Plugins.QKFPID.getFPInfo({
    //   //   key: "YBLnTNDVaniti1cMeRso"
    //   // })
    //   const fp = await FingerprintJS.load({
    //     apiKey: 'mvlqxOew4dGAi1wxGrZZ'
    //   })
    //   const result = await fp.get();
    //   FPInfo ={
    //     id: String(result?.visitorId) as any
    //   }
    // }else{
    //   FPInfo = {
    //     id: info?.data?.deviceId
    //   }
    // }

    FPInfo = {
      //@ts-ignore
      // id: info?.data?info?.data?.deviceId:H5DeviceInfo?.deviceId
    };

    let platform = AppDevicePlatform.H5;
    //@ts-ignore
    if (info?.data) {
      if (isPlatform() === 'ios') {
        platform = AppDevicePlatform.IOS;
      } else if (isPlatform() === 'android') {
        platform = AppDevicePlatform.ANDROID;
      }
    } else {
      if (isPlatform() === 'ios') {
        platform = AppDevicePlatform.H5IOS;
      } else if (isPlatform() === 'android') {
        platform = AppDevicePlatform.H5Android;
      }
    }

    const deviceInfo = {
      // langType: info?.data?.languageName,
      platform,
      //@ts-ignore
      ...info?.data,
      // ...FPInfo
      deviceId: FPInfo?.id,
    } as AppDeviceInfo;

    if (configuration.specifyLanguage) {
      deviceInfo.langType = configuration.specifyLanguage;
      deviceInfo.languageName = configuration.specifyLanguage;
    }
    if (storedLannguage) {
      const l = languages.find((l) => l.language === storedLannguage);
      if (l) deviceInfo.langType = l?.code;
      if (l) deviceInfo.languageName = l?.code;
    }
    // const id = await Device.getId();
    return deviceInfo;
  });
  return (
    <AppContextProvider
      configuration={{
        ...configuration,
        getDeviceInfoFn,
        onInited,
      }}
    >
      {/* <UserContextProvider> */}
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      <AppRoutes />
      {/* </UserContextProvider> */}
    </AppContextProvider>
  );
};

export default App;
