// import { Http as CHttp } from '@capacitor-community/http';

import { userStorage, notificationStore } from '@quark-base-plugin/storage';
// const secreKey = 'quark-whpassword' ;
// const ivStr = 'sdfsdf12345sfsdf'
import { Encrypt } from '@quark-base-plugin/utils/aes';
import { isEncyptedResult, isDecryptResult } from './isEncrypted';

// import { decrypt, encrypt, encryptUrl } from './httpEncoding';
import { HttpResponse } from './types';
// import { uuid } from './uuid';
import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

const secreKey = 'aajiaozicashmeh5';
const ivStr = 'hajiaozicashmeh5';
let isEncode = false;  // 是否启用加密解密逻辑
export const prodEnvList=['pro']; // 武汉、成都生产环境名单
if (prodEnvList.includes(process.env.REACT_APP_ENV as string)) {
  isEncode = true;
}

enum ErrorType {
  TOKEN_IS_EMPTY = 'AG20000002',
  TOKEN_IS_INVALID = 'AG20000003',
  TOKEN_OUT_OF_DATE = 'AG20000004',
  REFRESH_TOKEN_OUT_OF_DATE = 'MB20000008',
}

export type HttpExtraOptions = {
  /**
   * 是否当接口错误时自动弹出提示，默认 true
   */
  shouldToastOnError?: boolean;
};


const defaultHttpExtraOptions: HttpExtraOptions = {
  shouldToastOnError: true,
};

class HttpInstance {
  private _baseUrl: string;
  private _defaultHeaders: any;
  private _axios!: AxiosInstance;

  constructor() {
    this._baseUrl = '/';
    this._defaultHeaders = {};
    // this.createAxios();
    this._axios = Axios.create();
    this._axios.interceptors.request.use(
      async (config: AxiosRequestConfig): Promise<AxiosRequestConfig<any>> => {
        return config;
      }
    );
    this._axios.interceptors.response.use(
      async (response) => response,
      (error) => {
        console.log('response error: ', error);
        const message = error.response?.data?.message || error.message;
        setTimeout(() => {
          notificationStore.addToastNotification(message);
        }, 1000);
        return Promise.reject(error);
      }
    );
  }


  public setBaseUrl(baseURL: string) {
    this._baseUrl = baseURL;
  }

  public appendDefaultHeaders(newHeaders: any) {
    this._defaultHeaders = {
      ...this._defaultHeaders,
      ...newHeaders,
    };
  }

  private async handleResponse(
    response: any,
    extraOptions?: HttpExtraOptions
  ): Promise<HttpResponse> {
    const data: any = isDecryptResult({ data: response.data, flag: isEncode, secreKey, ivStr });
    const isErrorCode = data?.errCode === ErrorType.TOKEN_IS_INVALID ||
      data?.errCode === ErrorType.TOKEN_IS_EMPTY ||
      data?.errCode === ErrorType.TOKEN_OUT_OF_DATE ||
      data?.errCode === ErrorType.REFRESH_TOKEN_OUT_OF_DATE
    if (response.status !== 200) {
      if (extraOptions?.shouldToastOnError && !isErrorCode) {
        setTimeout(() => {
          notificationStore.addToastNotification(
            response?.data?.errMsg || response.statusText || `Error Code ${response.status}`
          );
        }, 1000);
      }
      return response.data;
    }
    if (!data.success) {
      if (data.errCode === ErrorType.TOKEN_IS_INVALID || data.errCode === ErrorType.TOKEN_IS_EMPTY) {
        await userStorage.clearUser();
        await userStorage.clearToken();
        // window.location.assign('/login' as unknown as string);
        window.location.assign('/app/home' as unknown as string);
      } else if (data.errCode === ErrorType.TOKEN_OUT_OF_DATE) {
        this.refreshToken();
      } else if (data.errCode === ErrorType.REFRESH_TOKEN_OUT_OF_DATE) {
        await userStorage.clearUser();
        await userStorage.clearToken();
        // window.location.assign('/login' as unknown as string);
        window.location.assign('/app/home' as unknown as string);
      }
      if (extraOptions?.shouldToastOnError && !isErrorCode) {
        setTimeout(() => {
          notificationStore.addToastNotification(data.errMsg);
        }, 1000);
      }
    }
    return data;
  }

  private async refreshToken() {
    const user = await userStorage.loadUser();
    if (!user || !user.accessToken) {
      return false;
    }
    if (!user.refreshToken) {
      await userStorage.clearUser();
      await userStorage.clearToken();
      window.location.assign('/' as unknown as string);
      return false;
    }
    const r = await this.post('/member/refresh-token', { refreshToken: user.refreshToken }, {
      shouldToastOnError: false
    });
    if (r.data) {
      user.accessToken = r.data;
      user.refreshToken = undefined;
      await userStorage.setUser(user);
      await userStorage.setToken(user.accessToken);
      // lastRequest();
    }

    return r;
  }

  private async buildHeaders({ path }: any) {
    const deafultHeaders = this._defaultHeaders;
    Object.keys(deafultHeaders).forEach((key) => {
      if (deafultHeaders[key]) {
        deafultHeaders[key] = deafultHeaders[key] + '';
      }
    });

    // const form = data instanceof FormData;
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      "x_x_path": path,
      ...deafultHeaders,
    } as any;

    const token = await userStorage.getToken();
    if (token) {
      headers.accessToken = `${token}`;
    }
    return headers;
  }

  public async post<Result = any>(
    url: string,
    data?: any,
    extraOptions?: HttpExtraOptions
  ): Promise<HttpResponse<Result>> {
    const extra = {
      ...defaultHttpExtraOptions,
      ...extraOptions,
    };
    // 对url加密
    const newurl = isEncyptedResult({ data: `/app${url}`, flag: isEncode, secreKey, ivStr });
    // 对参数加密
    const options = isEncyptedResult({ data: JSON.stringify(data), flag: isEncode, secreKey, ivStr });
    // 传入headers中的 x_xpath 参数
    const headers = await this.buildHeaders({ path: newurl });

    return this._axios.post<HttpResponse>(combineURLs(this._baseUrl, `${newurl}`), options,
      {
        headers
      }
    ).then(async (response) => {
      const data = await this.handleResponse(response, extra);
      return data;
    })
  }

  public async getJson<Result = any>(
    url: string,
    data?: any,
    extraOptions?: HttpExtraOptions
  ): Promise<HttpResponse<Result>> {
    const newurl = isEncyptedResult({ data: `/app${url}`, flag: isEncode, secreKey, ivStr });
    const headers = await this.buildHeaders({ path: newurl });
    const extra = {
      ...defaultHttpExtraOptions,
      ...extraOptions,
    };

    return this._axios.get<HttpResponse>(url,
      {
        headers
      }
    ).then(async (response) => {
      const data = await this.handleResponse(response, extra);
      return data;
    })
  }

  public async upload<Result = any>(
    url: string,
    blob?: Blob,
    params?: any,
    name: string = 'file',
    extraOptions?: HttpExtraOptions
  ): Promise<HttpResponse<Result>> {
    const newurl = isEncyptedResult({ data: `/app${url}`, flag: isEncode, secreKey, ivStr });
    const headers = await this.buildHeaders({ path: newurl });

    const options = {
      url: combineURLs(this._baseUrl, `/app${url}`),
      headers: { ...headers, 'Content-Type': 'multipart/form-data', },
    } as any;
    if (blob) {
      const formData = new FormData();
      formData.append(name, blob || 'undefined');
      for (const key in params) {
        if (params[key] != undefined) {
          formData.append(key, params[key]);
        }
      }
      options.data = formData;
    }

    const extra = {
      ...defaultHttpExtraOptions,
      ...extraOptions,
      ...options.headers,
    };
    return this._axios.post<HttpResponse>(options.url, options.data,
      {
        headers: options.headers,
      }
    ).then(async (response) => {
      const data = await this.handleResponse(response, extra);
      return data;
    })
  }
}

function combineURLs(baseURL: string, relativeURL: string) {
  return relativeURL
    ? baseURL.replace(/\/+$/, '') + '/' + relativeURL.replace(/^\/+/, '')
    : baseURL;
}

export const Http = new HttpInstance();


function converParamsToString(params: any) {
  const result = params;
  Object.keys(result).forEach((key) => {
    if (result[key]) {
      result[key] = result[key] + '';
    }
  });
  return result;
}