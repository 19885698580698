import { useEffect, useRef } from 'react';

export type Notification = {
  /**
   * 暂时没用
   */
  id?: string;
  /**
   * 消息订阅者根据此类型来判断是否需要处理
   */
  type: 'toast' | string;
  /**
   * 暂时没用
   */
  title?: string;
  /**
   * 发送的消息数据，可以是任意类型
   */
  message?: any;
};

type Subscription = (val: Notification) => void;

class NotificationStore {

  private subscriptions = new Set<Subscription>();

  addNotification = (notification: Notification) => {
    //@ts-ignore
    for (const subscription of this.subscriptions) {
      subscription(notification);
    }
  };

  addToastNotification = (message: string) => {
    const notification = {
      // id: nanoid(),
      type: 'toast',
      title: 'Toast',
      message,
    } as Notification;
    //@ts-ignore
    for (const subscription of this.subscriptions) {
      subscription(notification);
    }
  };

  // 用于单例中
  registerSubscription = (subscription: Subscription) => {
    this.subscriptions.add(subscription);
  }

  // 用于 hooks 中
  useSubscription = (callback: Subscription) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const callbackRef = useRef<Subscription>();
    callbackRef.current = callback;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      function subscription(notification: Notification) {
        if (callbackRef.current) {
          callbackRef.current(notification);
        }
      }
      this.subscriptions.add(subscription);
      return () => {
        this.subscriptions.delete(subscription);
      };
    }, []);
  };

}

/**
 * 单例模式的通知
 */
const notificationStore = new NotificationStore();

/**
 * hook形式，会和上面的单例模式区分开
 */
function useNotificationStore() {
  const ref = useRef<NotificationStore>();
  if (!ref.current) {
    ref.current = new NotificationStore();
  }
  return ref.current;
}

export { notificationStore, useNotificationStore };