import moment from 'moment';
import React, { useEffect, useImperativeHandle, useState } from 'react';

import { convertDate } from '../../../utils/moment';
import './index.scss';
import { Modal } from '../../modal';
import { DatePickerView } from '../../pickerView';
import { DatePickerViewProps } from '../../pickerView/antd-date-picker-view/date-picker-view';

import { useCreation, useUpdateEffect } from 'ahooks';

type Props = DatePickerViewProps & {
  value?: string;
  className?: string;
  title?: React.ReactNode;
  onBlur?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  cancelText?: React.ReactNode;
  okText?: React.ReactNode;
  disabled?: boolean;
  onChange?: (value?: string | null) => void;
};

const classPrefix = `qk-datePicker`;

export default React.forwardRef(function Picker(props: Props, onRef: any) {
  const {
    value,
    onChange,
    onBlur,
    title,
    cancelText = 'Cancel',
    okText = 'Confirm',
    min,
    max,
    disabled,
    defaultValue,
    ...others
  } = props;

  const [currentValue, setCurrentValue] = useState<number | undefined>(
    defaultValue ? defaultValue.getTime() : undefined
  ); //当前时间戳
  const [visible, setVisible] = React.useState<boolean>(false);

  useUpdateEffect(() => {
    const timeNum = moment(value, 'DD-MM-YYYY').valueOf();
    setCurrentValue(timeNum);
  }, [value]);

  useImperativeHandle(onRef, () => ({
    //实现聚焦显示Modal
    focus: () => {
      if (!disabled) {
        setVisible(true);
      }
    },
  }));

  function handleChange(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation();
    const str = moment(currentValue).format('DD-MM-YYYY');
    onChange && onChange(str);
    setVisible(false);
  }

  function handleClose(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation();
    onBlur && onBlur(e);
    setVisible(false);
  }

  function handleClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (!disabled) {
      setVisible(true);
    }
  }

  function renderHeader() {
    return (
      <div className={`${classPrefix}-modal-header`}>
        <div onClick={handleClose}>{cancelText}</div>
        <div className={`${classPrefix}-modal-header-confirm`} onClick={handleChange}>
          {okText}
        </div>
      </div>
    );
  }

  function renderModal() {
    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 61);

    return (
      <Modal
        className={`${classPrefix}-modal`}
        visible={visible}
        showCloseButton={false}
        align="bottom"
      >
        {renderHeader()}
        <div className={`${classPrefix}-modal-date-picker-view`}>
          <DatePickerView
            className="qk-custom-date-picker"
            mouseWheel={true}
            onChange={(v: Date) => {
              const timeNum = moment(v, 'DD-MM-YYYY').valueOf();
              setCurrentValue(timeNum);
            }}
            value={new Date(currentValue as number)}
            min={min || minDate}
            max={max || new Date()}
            {...others}
          />
        </div>
      </Modal>
    );
  }

  return (
    <div className={classPrefix}>
      <span onClick={handleClick}>{value}</span>
      {renderModal()}
    </div>
  );
});
