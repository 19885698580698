import { useIsomorphicLayoutEffect } from 'ahooks';
import { useState } from 'react';

export function useInnerVisible(outerVisible: boolean) {
  const [innerVisible, setInnerVisible] = useState(outerVisible);
  useIsomorphicLayoutEffect(() => {
    setInnerVisible(outerVisible);
  }, [outerVisible]);
  return innerVisible;
}
