import { Analysis } from '@quark-base-plugin/analysis';

import { Urls } from '@/routes/config';
import { H5logEvent } from '../providers/H5AnalyticsProvider'

function getMap() {
  const map: any = {};
  map[Urls.APP_HOME] = 'Home';
  map[Urls.APP_ME] = 'Me';
  map[Urls.APP_REPAY] = 'Repay';
  map[Urls.LOGIN] = 'LoginEntrance';

  map[Urls.APPLY_STEP_ID] = 'KTP';
  map[Urls.APPLY_STEP_BASIC] = 'UserInfo';
  map[Urls.APPLY_STEP_WORK] = 'WorkInfo';
  map[Urls.APPLY_STEP_CONTACT] = 'EmergencyContact';
  map[Urls.APPLY_STEP_WITHDRAW] = 'SetBankCard';
  map[Urls.APPLY_STEP_ALL] = 'ApplyStepAll';

  map[Urls.RECORD_LOAN] = 'RecordLoan';
  map[Urls.RECORD_REPAY] = 'RecordRepay';

  map[Urls.HOME_REPAY] = 'RepayHome';
  map[Urls.REPAY_DETAIL] = 'RepayDetail';
  map[Urls.REPAY_DETAIL_INSTALLMENT] = 'RepayDetailInstallment';
  map[Urls.APP_REPAY_INVOICE] = 'RepayInvoice';
  map[Urls.APP_REPAY_INVOICE_PAID] = 'RepayInvoicePaid';
  map[Urls.APP_REPAY_HISTORY] = 'RepayHistory';

  map[Urls.PRODUCT_CREDIT] = 'ProductQuota';
  map[Urls.PRODUCT_CONFIRM] = 'LoanProduct';
  map[Urls.PRODUCT_REPAYPLAN] = 'LoanProductRepayPlan';
  map[Urls.PRODUCT_FACE] = 'FaceVerify';

  map[Urls.RECORD_INSTALLMENT_DETAIL] = 'RecordInstallmentDetail';

  map[Urls.APP_ME_SETTING] = 'Settings';
  map[Urls.APP_ME_MATERIAL] = 'MeMaterial';
  map[Urls.APP_ME_PROTOCOL] = 'MeProtocol';
  map[Urls.APP_ME_DELETE_USER] = 'MeDeleteUser';

  map['/protocol/privacy'] = 'ProtocolPrivacy';
  map['/protocol/loan'] = 'ProtocolLoan';
  map['/protocol/register'] = 'ProtocolRegister';
  // map[Urls.LANDING] = 'Landing';

  return map;
}

class RouteAnalysis {
  private _lastPath: string | undefined;
  private _map: any;

  constructor() {
    this._map = getMap();
  }

  /**
   * onRouteChange
   */
  public onRouteChange(pathname: string) {
    if (this._lastPath && this._map[this._lastPath]) {
      const lastPage = this._map[this._lastPath];
      H5logEvent(`${lastPage}_Leave`)
      // Analysis.logEvent({
      //   event: `${lastPage}:Leave`,
      //   params: {
      //     pageCode: `${lastPage}`,
      //     actionCode: `${lastPage}:Leave`,
      //     actionTime: Date.now(),
      //   },
      // });
      // console.log('Analysis Leave: ', lastPage);
    }

    if (this._map[pathname]) {
      const currentPage = this._map[pathname];
      H5logEvent(`${currentPage}_Enter`)
      // Analysis.logEvent({
      //   event: `${currentPage}:Enter`,
      //   params: {
      //     pageCode: `${currentPage}`,
      //     actionCode: `${currentPage}:Enter`,
      //     actionTime: Date.now(),
      //   },
      // });
      // console.log('Analysis Enter: ', currentPage);
      if (pathname !== this._lastPath) {
        this._lastPath = pathname;
      }
    }
  }
}

export const routeAnalysis = new RouteAnalysis();
