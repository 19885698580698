import { useIonRouter } from '@ionic/react';
import { useCallback } from 'react';
import { useHistory } from 'react-router';

export function useCustomRouter() {
  const history = useHistory();
  const ionRouter = useIonRouter();
  const goBack = useCallback(() => {
    console.log('ionRouter.canGoBack(): ', ionRouter.canGoBack());
    if (ionRouter.canGoBack()) {
      ionRouter.goBack();
    } else {
      history.replace('/');
    }
  }, [history, ionRouter]);
  const canGoBack = ionRouter.canGoBack;
  const push = ionRouter.push;
  return { goBack, canGoBack, push, history, ionRouter };
}
