import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { useCreation, useMount, useSafeState } from 'ahooks';

// Initialize an agent at application startup.
const fpPromise = FingerprintJS.load();

export type UseDetectOSPlatformProps = {
  detectFn: (platform: string) => boolean;
};

export function useDetectOSPlatform(props: UseDetectOSPlatformProps) {
  const { detectFn } = props;
  const fingerprintFn = useCreation(async () => {
    const fp = await fpPromise;
    const fingerprintResult = await fp.get();
    return fingerprintResult;
  }, []);

  const [result, setResult] = useSafeState<any>();

  const isDectectPassed = useCreation(() => {
    if (
      result &&
      result.components &&
      result.components.platform &&
      result.components.platform.value
    ) {
      const platform = result.components.platform.value as string;
      return detectFn(platform);
    }
    return false;
  }, [result]);

  useMount(async () => {
    const result = await fingerprintFn;
    setResult(result);
  });

  return {
    fingerprintResult: result,
    isDectectPassed: result && isDectectPassed,
  };
}
