import { Plugins } from '@capacitor/core';
// import { isPlatform } from '@ionic/core';
import { Analysis, AnalysisThirdEventName } from '@quark-base-plugin/analysis';
import { ErrorBlock } from '@quark-base-plugin/core';
import { Http } from '@quark-base-plugin/net';
import { storage } from '@quark-base-plugin/storage';
import {
  QueryClientProvider,
  QueryClient,
  useQuery,
  // UseMutateAsyncFunction,
  // useMutation,
  RefetchOptions,
  QueryObserverResult,
} from '@tanstack/react-query';
import { useMemoizedFn, useUpdateEffect } from 'ahooks';
import React, { useRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { useNetwork } from '../hooks';
import { useFCMToken } from '../hooks/useFCMToken';

import { AuthProvider } from './auth';
import { AppBasicInfo, AppDeviceInfo, AppDevicePlatform } from './types';
import { Toast } from '@quark-base-plugin/core';
import { NativeBridge } from "@/native/index";


// import '@quark-base-plugin/device';
import { useDetectOSPlatform } from './detection';
import { GlobalProvider } from './global';
import { UserContextProvider } from './user';
import PluginHelper from '../../core/src/inject';
import { isPlatform } from '../../core/src/judgmentSystem';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export type AppProviderProps = {
  children: React.ReactNode;
  getAppInfoFunc: () => Promise<AppBasicInfo>;
  getDeviceInfoFunc: () => Promise<AppDeviceInfo>;
  detectPlatform?: (platform: string) => boolean;
  onAppInit?: () => void;
  loadingComponent?: React.ReactNode;
  networkErrorComponent?: React.ReactNode;
};

export type AppContextValue = {
  appInfo: AppBasicInfo | undefined;
  deviceInfo: AppDeviceInfo | undefined;
  refetchDeviceInfo: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<AppDeviceInfo>>;
};

const AppContext = React.createContext<AppContextValue | null>(null);
AppContext.displayName = 'AppContext';

const AppProvider = (props: AppProviderProps) => {
  const {
    children,
    getAppInfoFunc,
    getDeviceInfoFunc,
    detectPlatform = () => true,
    onAppInit,
    loadingComponent,
    networkErrorComponent,
  } = props;
  const appInited = useRef(false);

  const submitFCM = useMemoizedFn(async (deviceId, fcmKey) => {
    const result = await Http.post('/device/fcm', { deviceId, fcmKey });
  });
  
  const {
    data: appInfo,
    // error,
    // status,
    isLoading,
    // isSuccess,
    // refetch,
  } = useQuery<AppBasicInfo, Error>({
    queryKey: ['fn-getAppInfo'],
    queryFn: getAppInfoFunc,
  });

  const {
    data: deviceInfo,
    // error,
    // status,
    isLoading: isLoadingDeviceInfo,
    // isSuccess,
    refetch: refetchDeviceInfo,
  } = useQuery<AppDeviceInfo, Error>({
    queryKey: ['fn-getDeviceInfo'],
    queryFn: getDeviceInfoFunc,
  });

  const { fcmToken } = useFCMToken();

  useUpdateEffect(() => {
    const headers = {
      ...appInfo,
      deviceId: deviceInfo?.deviceId,
      platform: deviceInfo?.platform,
      google_referrer: deviceInfo?.google_referrer,
      af_referrer: deviceInfo?.af_referrer,
      IOS_Referrer: deviceInfo?.IOS_Referrer,
      langType: isPlatform() === 'android' ? 'es-PE' : deviceInfo?.langType,
      // langType:'es-PE', // 固定西语
      fcm: deviceInfo?.fcmToken,
      // passFlag:'1'
    };
    Http.appendDefaultHeaders(headers);
    if (appInfo && deviceInfo && !appInited.current) {
      onAppInit?.();
      appInited.current = true;
      if(deviceInfo?.fcmToken){
        submitFCM(deviceInfo?.deviceId, deviceInfo?.fcmToken);
      }  
    }
  }, [appInfo, deviceInfo, deviceInfo?.fcmToken]);

  const { fingerprintResult, isDectectPassed } = useDetectOSPlatform({
    detectFn: detectPlatform,
  });

  const { networkStatus } = useNetwork();

  const value = React.useMemo(() => {
    return {
      appInfo,
      deviceInfo,
      refetchDeviceInfo,
    };
  }, [appInfo, deviceInfo, refetchDeviceInfo]);

  if (isLoading || isLoadingDeviceInfo || !fingerprintResult || !networkStatus) {
    if (loadingComponent) return <>{loadingComponent}</>;
    return <div>Loading...</div>;
  }

  if (!networkStatus.connected) {
    if (networkErrorComponent) {
      return <>{networkErrorComponent}</>;
    }
    return (
      <ErrorBlock status="disconnected" description="Network issues, please try again～" fullPage />
    );
  }

  if (!isDectectPassed) {
    return <div>Please use mobile browser to access</div>;
  }

  return (
    <AppContext.Provider value={value}>
      <AuthProvider>{children}</AuthProvider>
    </AppContext.Provider>
  );
};

function useAppContext() {
  const context = React.useContext(AppContext);
  if (!context) {
    throw new Error(`useAppContext must be used within an AppProvider`);
  }
  return context;
}

export type AppConfigration = {
  /**
   * 配置 app 马甲包信息
   */
  appInfo: AppBasicInfo;
  /**
   * 指定语言
   */
  specifyLanguage?: string;
  /**
   * 检测平台是否能使用
   */
  detectPlatform?: (platform: string) => boolean;
  /**
   * 自定义设备信息获取
   */
  getDeviceInfoFn?: () => Promise<AppDeviceInfo>;
  /**
   * 自定义加载组件
   */
  loadingComponent?: React.ReactNode;
  /**
   * 自定义error组件
   */
  errorComponent?: React.ReactNode;
  /**
   * 自定义无网络error组件
   */
  networkErrorComponent?: React.ReactNode;
  /**
   * app 初始化完成
   */
  onInited?: () => void;
  /**
   * on error
   */
  onError?: (error: any, info: any) => void;
};

export type AppContextProviderProps = {
  children?: React.ReactNode;

  configuration: AppConfigration;
};

const AppContextProvider = ({ configuration, children }: AppContextProviderProps) => {
  const {
    appInfo,
    detectPlatform,
    getDeviceInfoFn,
    loadingComponent,
    errorComponent,
    networkErrorComponent,
    onError,
    onInited,
  } = configuration;
  // const { Device, QKDevice } = Plugins;

  async function PluginGetDeviceInfo() {
	  // return PluginHelper.callPlugin('ccDevicePlugin', 'getInfo', {}, 'ccDevicePlugin_getInfo_result').then((r : any)=>{
    //   return r
	  // }).catch((e : any)=>{
    //   console.log(e)
	  // })
    return await NativeBridge.Plugins.appPlugin.getEquipmentInfo()
	}

  const getAppInfoFunc = useMemoizedFn(async () => {
    // if (isPlatform() !== 'h5') {
    //   // const info = await Device.getInfo();
    //   let info = {}
    //   setTimeout(async()=>{
    //     info = await PluginGetDeviceInfo() as any;
    //   },500)
    //   return {
    //     ...appInfo,
    //     // appVersion: info?.data?.appVersion?info?.data?.appVersion:'1.0',
    //   };
    // }
    return {
      ...appInfo,
    };
  });


  const defaultGetDeviceInfoFn = useMemoizedFn(async () => {
    const info = await PluginGetDeviceInfo() as any;
    function getPlatform() {
      if(info?.data){
        if (isPlatform() === 'ios') {
          return AppDevicePlatform.IOS;
        } else if (isPlatform() === 'android') {
          return AppDevicePlatform.ANDROID;
        }
      }else{
        if (isPlatform() === 'ios') {
          return AppDevicePlatform.H5IOS;
        } else if (isPlatform() === 'android') {
          return AppDevicePlatform.H5Android;
        }
      }
    }

    // const info = await QKDevice.getInfo();
    
    const deviceInfo = {
      langType: info?.data?.languageName,
      platform: getPlatform(),
      ...info?.data,
    } as AppDeviceInfo;

    if (configuration.specifyLanguage) {
      deviceInfo.langType = configuration.specifyLanguage;
      deviceInfo.languageName = configuration.specifyLanguage;
    }
    // const id = await Device.getId();
    return deviceInfo;
  });

  const onAppInit = useMemoizedFn(async () => {
    const _KEY_FIRST_OPEN = 'JHUSYFQWJNCSFIRSTOPENJKJ12JCSJCvndsjh14812931ucjsakjc';
    // Analysis.logEvent({ event: AnalysisThirdEventName.CustomAppStart }); // 埋点：app启动
    const result = await storage.get(_KEY_FIRST_OPEN);
    if (!result) {
      // Analysis.logEvent({ event: AnalysisThirdEventName.CustomFirstOpen });
      await storage.set(_KEY_FIRST_OPEN, 'AKJBDVKSUHFIUWHRKJN131NFKJWEHIU124KNSCKJ');
    }
    onInited?.();
  });

  const loading = <>{loadingComponent ? loadingComponent : 'Loading...'}</>;

  return (
    <React.Suspense fallback={loading}>
      <ErrorBoundary
        FallbackComponent={() => (errorComponent ? <>{errorComponent}</> : <div>error</div>)}
        onError={onError}
      >
        <QueryClientProvider client={queryClient}>
          <AppProvider
            getAppInfoFunc={getAppInfoFunc}
            getDeviceInfoFunc={getDeviceInfoFn || defaultGetDeviceInfoFn}
            onAppInit={onAppInit}
            loadingComponent={loading}
            networkErrorComponent={networkErrorComponent}
            detectPlatform={detectPlatform}
          >
            <UserContextProvider>
              <GlobalProvider loadingComponent={loadingComponent}>{children}</GlobalProvider>
            </UserContextProvider>
          </AppProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    </React.Suspense>
  );
};

export { AppContextProvider, useAppContext };
