import { IonLabel } from '@ionic/react';
import clsx from 'clsx';
import { CSSProperties } from 'react';
import './Label.scss';

export type LabelProps = {
  display?: string;
  size?: 'large' | 'title' | 'subtitle' | 'text' | 'desc' | 'tiny';
  type?:
    | 'danger'
    | 'dark'
    | 'light'
    | 'medium'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'tertiary'
    | 'warning'
    | (string & Record<never, never>)
    | undefined;
  children?: React.ReactNode;
  style?: CSSProperties;
  medium?: boolean;
  bold?: boolean;
  className?: string;
} & React.HTMLAttributes<HTMLIonLabelElement>;

export function Label(props: LabelProps) {
  const {
    children,
    color,
    type = 'dark',
    size,
    display = 'block',
    style,
    medium,
    bold,
    className,
  } = props;
  const customStyles = {
    color,
    display: display,
    ...style,
  } as CSSProperties;
  return (
    <IonLabel
      color={type}
      className={clsx(
        `qk-label`,
        { [`qk-label-size-${size}`]: !!size },
        { 'qk-label-medium': medium },
        { 'qk-label-bold': bold },
        className
      )}
      style={customStyles}
    >
      <>{children}</>
    </IonLabel>
  );
}
