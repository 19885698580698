import { isPlatform } from '../../judgmentSystem';
import PluginHelper from '../../inject';
import { CameraUtils } from '@quark-base-plugin/utils/cameraUtils';
import fileUtils from '@quark-base-plugin/utils/fileUtils';
import clsx from 'clsx';
import React, { CSSProperties, useEffect, useImperativeHandle, useRef, useState } from 'react';
import './TakePhoto.scss';
import { Http } from '@quark-base-plugin/net';
import { useBoolean, useMemoizedFn, useRequest, useUpdateEffect } from 'ahooks';
import { mergeProps } from '../../utils/with-default-props';
import { Mask } from '../popup/mask';
import { Spinner } from '../spinner';
import TakePhotoTips from './take-photo-tips';

import iconTake from './take-photo.png';
import idSuccess from './id_success.png';

type Props = {
  takePhotoIcon?: string;
  exampleImg?: string;
  iconStyle?: CSSProperties & Partial<Record<'--width' | '--height', string>>;
  uploadUrl?: string;
  value?: any;
  isValidFn?: (a: any) => boolean;
  backgroundImg?: string;
  /**
   * 在原生下拍照的逻辑实现, base64 string
   */
  takePhotoFn?: () => Promise<string | undefined>;
  takePhotoPosition?: 'front' | 'rear' | 'all';
  /**
   * 识别结果
   */
  onOCRRecognition?: (data?: any) => void;
  onChange?: (v?: any) => void;
  title?: string;
  desc?: string;
  params?: {
    [key: string]: any;
  };
  style?: CSSProperties &
    Partial<
      Record<
        | '--width'
        | '--height'
        | '--padding'
        | '--title-height'
        | '--title-line-height'
        | '--title-padding',
        string
      >
    >;
  error?: any;
};

const defaultParams = {
  ocrCheck: false,
};

const classPrefix = 'qk-TakePhoto';

const defaultUploadUrl = '/member/certificate-info/pe/save-attachment';

export type TakePhotoRef = {
  nativeElement: HTMLDivElement | null;
};

export default React.forwardRef(function TakePhoto(
  props: Props,
  ref: React.ForwardedRef<TakePhotoRef>
) {
  const {
    takePhotoIcon,
    exampleImg,
    iconStyle,
    value,
    uploadUrl = defaultUploadUrl,
    takePhotoFn,
    takePhotoPosition = 'rear',
    backgroundImg,
    params: p,
    onChange,
    title,
    desc,
    style,
    error,
  } = props;
  const nativeRef = useRef<HTMLDivElement>(null);
  const params = mergeProps(defaultParams, p);
  const [loadImg, { setFalse, setTrue }] = useBoolean(true);

  const { runAsync: uploadImage, loading } = useRequest(
    (blob?: Blob, params?: any) => Http.upload(uploadUrl, blob, params),
    {
      manual: true,
    }
  );

  // const { present } = useCameraPreview({ onTakedPhoto: handleTakePhoto });

  const [url, setUrl] = useState<string | null>(null);
  const [tipsVisible, setTipsVisible] = useState<boolean>(false);
  const [tipsConfirm, setTipsConfirm] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    focus: () => {
      nativeRef.current?.focus();
      //@ts-ignore
      props?.onFocus?.();
    },
    blur: () => {
      nativeRef.current?.blur();
    },
    get nativeElement() {
      return nativeRef.current;
    },
  }));

  useEffect(() => {
    setTrue();
    getUrl();
  }, [value]);

  async function getCamera() {
    return PluginHelper.callPlugin(
      'ccOpenCameraPlugin',
      'takePhoto',
      {},
      'ccOpenCameraPlugin_takePhoto_result'
    )
      .then((r: any) => {
        return r;
      })
      .catch((e: any) => {
        console.log(e);
      });
  }

  /* 点击拍照 */
  async function takePhotoAction() {
    if (takePhotoFn) {
      const photoBase64String = await takePhotoFn();
      if (photoBase64String) handleTakePhoto(photoBase64String);
    } else {
      if (isPlatform() !== 'h5') {
        try {
          const photoBase64String = (await getCamera()) as any;
          if (photoBase64String) handleTakePhoto(photoBase64String?.data?.imageBase64);
        } catch (error) {
          console.log('take photo failed, error: ', error);
        }
      } else {
        const photoBase64String = await CameraUtils.takeOriginPhoto(takePhotoPosition);
        if (photoBase64String) handleTakePhoto(photoBase64String);
      }
    }
  }

  async function getUrl() {
    if (typeof value === 'string') {
      setUrl(value);
    } else {
      setUrl(value?.previewUrl);
    }
  }

  async function deleteImageById(attachId: number | string) {
    await Http.post(`/member/certificate-info/pe/delete/${attachId}`);
  }

  async function handleTakePhoto(url: string) {
    try {
      let fileUrl = url;
      if (!url.startsWith('data:image/jpeg;base64')) {
        fileUrl = 'data:image/jpeg;base64,' + url;
      }
      // @ts-ignore
      const file = fileUtils.convertBase64ToFile(fileUrl);
      // const file = dataURLtoFile(fileUrl) as Blob;
      const { data } = await uploadImage(file, params);
      props.onOCRRecognition?.(data);
      onChange && onChange(data);
    } catch (error) {
      console.log(error);
    }
  }

  const confirmTakePhotoTips = useMemoizedFn(() => {
    setTipsConfirm(true);
    setTipsVisible(false);
  });

  useUpdateEffect(() => {
    if (tipsConfirm) {
      takePhotoAction();
      setTipsConfirm(false);
    }
  }, [tipsConfirm]);

  return (
    <div
      className={clsx(`${classPrefix}-container`, {
        [`${classPrefix}-container-error`]: error,
      })}
      ref={nativeRef}
      style={style}
    >
      <div className={`${classPrefix}-placehodler`} onClick={() => takePhotoAction()}>
        {(url || backgroundImg) && (
          <>
            <img
              src={url || backgroundImg}
              alt=""
              onLoad={() => setFalse()}
              className={`${classPrefix}-image`}
            />
          </>
        )}

        {url && <img src={idSuccess} alt="" className={`${classPrefix}-success`}></img>}

        {!url && <img style={iconStyle} src={iconTake} alt="" className={`${classPrefix}-icon`} />}
        <div className={`${classPrefix}-desc`}>{desc}</div>
      </div>
      <div className={`${classPrefix}-title`}>{title}</div>
      {loading && (
        <Mask style={{ background: 'transparent' }}>
          <Spinner />
        </Mask>
      )}
      <TakePhotoTips
        visible={tipsVisible}
        onClose={() => setTipsVisible(false)}
        onOk={confirmTakePhotoTips}
      />
    </div>
  );
});
