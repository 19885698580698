import { IonList, IonItem, IonLabel } from '@ionic/react';
import classNames from 'classnames';
import React, { useRef, useState } from 'react';

import { LableItem } from '../../const/type';
import { Spinner } from '../spinner';

import './index.scss';

type Props = {
  list: [string, LableItem[]][];
  rightLink: string[];
  select?: string;
  onChange: (v: LableItem, e: React.MouseEvent<HTMLIonItemElement, MouseEvent>) => void;
  loading?: boolean;
  labelKey: string;
};

const classPrefix = 'qk-cascader';

function CascaderList({ list, rightLink, loading, labelKey, onChange }: Props) {
  const [selectLink, setSelectLink] = useState<string>();
  const onListRef = useRef<HTMLIonListElement | null>(null);

  //滚动到指定位置更新描点选中
  function onScollList() {
    const refList = onListRef.current?.children;
    let key;
    if (refList && onListRef.current) {
      for (let i = 0; i < refList.length; i++) {
        const ref = refList[i].getBoundingClientRect();
        if (ref.top <= onListRef.current?.offsetTop) {
          key = refList[i].children[0].id;
        }
      }

      setSelectLink(key);
    }
  }

  //选择列表项
  function handleChange(item: LableItem, e: React.MouseEvent<HTMLIonItemElement, MouseEvent>) {
    e.stopPropagation();
    onChange && onChange(item, e);
  }

  //滚动到指定位置
  function handleLink(anchorName: string) {
    if (anchorName) {
      // 找到锚点
      const anchorElement = document.getElementById(anchorName);
      // 如果对应id的锚点存在，就跳转到锚点
      if (anchorElement) {
        anchorElement.scrollIntoView({
          block: 'start',
          // behavior: 'smooth' 滚动动画
        });
      }
    }
  }

  //右侧描点
  function renderRightLink() {
    return (
      <div className={`${classPrefix}-right-link`}>
        {rightLink?.map((val, index) => {
          const current = (!selectLink && !index) || selectLink === val;

          return (
            <span
              key={`link_${val}`}
              onClick={() => handleLink(val)}
              className={classNames({ [`${classPrefix}-right-selectLink`]: current })}
            >
              {val}
            </span>
          );
        })}
      </div>
    );
  }

  //地址列表
  function renderList() {
    if (loading) return <Spinner />;
    return (
      //@ts-ignore
      <IonList ref={onListRef} className={`${classPrefix}-left-content`} onScroll={onScollList}>
        {list?.map((item, index) => {
          const [key, content] = item;

          return (
            <div className={`${classPrefix}-left-list`} key={key}>
              <div className={`${classPrefix}-left-key`} id={key}>
                <span>{key}</span>
              </div>
              {content.map((v) => (
//@ts-ignore
                <IonItem
                  className={classNames(
                    `${classPrefix}-left-item`,
                    index + 1 === length && `${classPrefix}-left-item-last`
                  )}
                  key={v[labelKey]}
                  onClick={(e) => handleChange(v, e)}
                >
                  {/* @ts-ignore */}
                  <IonLabel className={`${classPrefix}-left-label`}>{v[labelKey]}</IonLabel>
                </IonItem>
              ))}
            </div>
          );
        })}
      </IonList>
    );
  }

  return (
    <div className={`${classPrefix}-box`}>
      <div className={`${classPrefix}-left`}>{renderList()}</div>
      {renderRightLink()}
    </div>
  );
}

export default CascaderList;
