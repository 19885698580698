import { animated } from '@react-spring/web';
import classNames from 'classnames';
import React, { FC, ReactNode, ReactElement, ComponentProps, useRef } from 'react';

import { NativeProps, withNativeProps } from '../../utils/native-props';
import { ShouldRender } from '../../utils/should-render';
import { traverseReactNode } from '../../utils/traverse-react-node';
import { usePropsValue } from '../../utils/use-props-value';
import { useResizeEffect } from '../../utils/use-resize-effect';
import { useTabListScroll } from '../../utils/use-tab-list-scroll';
import ScrollMask from '../scroll-mask';

const classPrefix = `qk-capsule-tabs`;

export type CapsuleTabProps = {
  title: ReactNode;
  disabled?: boolean;
  forceRender?: boolean;
  destroyOnClose?: boolean;
  children?: ReactNode;
} & NativeProps;

export const CapsuleTab: FC<CapsuleTabProps> = () => {
  return null;
};

export type CapsuleTabsProps = {
  activeKey?: string | null;
  defaultActiveKey?: string | null;
  onChange?: (key: string) => void;
  children?: React.ReactNode;
} & NativeProps;

function isEqualKey(a: any, b: any): boolean {
  return (a || '').replace(/^.\$/, '') === (b || '')?.replace(/^.\$/, '');
}

export const CapsuleTabs: FC<CapsuleTabsProps> = (props) => {
  const tabListContainerRef = useRef<HTMLDivElement>(null);
  const rootRef = useRef<HTMLDivElement>(null);
  const keyToIndexRecord: Record<string, number> = {};
  let firstActiveKey: string | null = null;

  const panes: ReactElement<ComponentProps<typeof CapsuleTab>>[] = [];

  traverseReactNode(props.children, (child, index) => {
    if (!React.isValidElement(child)) return;
    const key = child.key;
    if (typeof key !== 'string') return;
    if (index === 0) {
      firstActiveKey = key;
    }
    //@ts-ignore
    const length = panes.push(child);
    keyToIndexRecord[key] = length - 1;
  });

  const [activeKey, setActiveKey] = usePropsValue({
    value: props.activeKey,
    defaultValue: props.defaultActiveKey ?? firstActiveKey,
    onChange: (v) => {
      if (v === null) return;
      props.onChange?.(v);
    },
  });

  const { scrollLeft, animate } = useTabListScroll(
    tabListContainerRef,
    keyToIndexRecord[activeKey as string]
  );

  useResizeEffect(() => {
    animate(true);
  }, rootRef);

  return withNativeProps(
    props,
    <div className={classPrefix} ref={rootRef}>
      <div className={`${classPrefix}-header`}>
        <ScrollMask scrollTrackRef={tabListContainerRef} />
        <animated.div
          className={`${classPrefix}-tab-list`}
          ref={tabListContainerRef}
          scrollLeft={scrollLeft}
        >
          {panes.map((pane) =>
            withNativeProps(
              pane.props,
              <div key={pane.key} className={`${classPrefix}-tab-wrapper`}>
                <div
                  onClick={() => {
                    const { key } = pane;
                    if (pane.props.disabled) return;
                    if (key === undefined || key === null) {
                      return;
                    }
                    setActiveKey(key.toString());
                  }}
                  className={classNames(`${classPrefix}-tab`, {
                    [`${classPrefix}-tab-active`]: isEqualKey(pane.key, activeKey),
                    [`${classPrefix}-tab-disabled`]: pane.props.disabled,
                  })}
                >
                  {pane.props.title}
                </div>
              </div>
            )
          )}
        </animated.div>
      </div>
      {panes.map((pane) => {
        if (pane.props.children === undefined) {
          return null;
        }
        const active = isEqualKey(pane.key, activeKey);
        return (
          <ShouldRender
            key={pane.key}
            active={active}
            forceRender={pane.props.forceRender}
            destroyOnClose={pane.props.destroyOnClose}
          >
            <div
              className={`${classPrefix}-content`}
              style={{ display: active ? 'block' : 'none' }}
            >
              {pane.props.children}
            </div>
          </ShouldRender>
        );
      })}
    </div>
  );
};
