import { NativeResult } from '../call';
import { BaseNativePlugin } from './base';

export type AppLocalStoragePluginType = {
  setItem(options: { key: string; value: string }): Promise<NativeResult<boolean>>;
  getItem(options: { key: string }): Promise<NativeResult<string>>;
  removeItem(options: { key: string }): Promise<NativeResult<string>>;
  clear(): Promise<NativeResult<string>>;
};

export class AppLocalStoragePlugin extends BaseNativePlugin implements AppLocalStoragePluginType {
  getItem(options: { key: string; }): Promise<NativeResult<string>> {
    return super.callPlugin<string>('AppLocalStoragePlugin', 'getItem', options);
  }
  removeItem(options: { key: string; }): Promise<NativeResult<string>> {
    return super.callPlugin('AppLocalStoragePlugin', 'removeItem', options);
  }
  clear(): Promise<NativeResult<string>> {
    return super.callPlugin('AppLocalStoragePlugin', 'clear');
  }
  setItem(options: { key: string; value: string; }): Promise<NativeResult<boolean>> {
    return super.callPlugin('AppLocalStoragePlugin', 'setItem', options,
      async () => {
        console.log("!!!!web impletions");
        return false
      });
  }
}
