import './capsule-tabs.scss';
import { attachPropertiesToComponent } from '../../utils/attach-properties-to-component';

import { CapsuleTab, CapsuleTabs } from './capsule-tabs';

export type { CapsuleTabsProps, CapsuleTabProps } from './capsule-tabs';

export default attachPropertiesToComponent(CapsuleTabs, {
  Tab: CapsuleTab,
});
