import { useEffect, useState } from 'react';

export type TimerProps = {
  initValue: number;
  onEnd?: () => void;
  onLoop?: (value: number) => void;
  delay?: number;
};

//定时器
export function useTimer({ initValue, onEnd, onLoop, delay = 1000 }: TimerProps) {
  const [timer, setTimer] = useState(0);
  const [intervalId, setIntervalId] = useState<any>();

  useEffect(() => {
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [intervalId]);

  //定时器开始
  function start() {
    if (intervalId) {
      clearInterval(intervalId);
    }
    let count = initValue;
    setTimer(count);
    const id = setInterval(() => {
      onLoop?.(count);
      if (count > 0) {
        setTimer(--count);
      } else {
        clearInterval(id);
        onEnd && onEnd();
      }
    }, delay);
    setIntervalId(id);
  }

  return {
    start,
    timer,
  };
}
