import { IonSpinner, IonToast } from '@ionic/react';
import clsx from 'clsx';
import React, { useMemo } from 'react';

import { ImperativeHandler, renderImperatively } from '../../utils/render-imperatively';
import { PropagationEvent } from '../../utils/with-stop-propagation';
import { Mask } from '../popup/mask';
import { Spinner, SpinnerInner } from '../spinner';

const defaultProps: ToastProps = {
  duration: 2000,
  position: 'bottom',
  maskClickable: true,
};

let currentHandler: ImperativeHandler | null = null;
let currentTimeout: number | null = null;

export type ToastHandler = {
  close: () => void;
};

const classPrefix = 'qk-toast';

export type ToastProps = {
  message?: string;
  duration?: number;
  position?: 'top' | 'bottom' | 'middle';
  icon?: 'loading' | React.ReactNode;
  customElement?: (props: ToastProps) => JSX.Element;
  afterClose?: () => void;
  maskClickable?: boolean;
  visible?: boolean;
  stopPropagation?: PropagationEvent[];
};

const ToastInner = (
  props: ToastProps & {
    onClose?: () => void;
  }
) => {
  const { message, position, maskClickable, icon } = props;
  const iconElement = useMemo(() => {
    if (icon === null || icon === undefined) return null;
    switch (icon) {
      case 'loading':
        return <SpinnerInner />;
      default:
        return icon;
    }
  }, [icon]);
  const top = useMemo(() => {
    switch (position) {
      case 'top':
        return '20%';
      case 'bottom':
        return '80%';
      default:
        return '50%';
    }
  }, [position]);
  return (
    <Mask
      visible={props.visible}
      destroyOnClose
      opacity={0}
      afterClose={props.afterClose}
      style={{
        pointerEvents: maskClickable ? 'none' : 'auto',
      }}
      stopPropagation={props.stopPropagation}
    >
      <div className={clsx(`${classPrefix}-mask`)}>
        <div className={clsx(`${classPrefix}-wrap`)}>
          <div
            style={{ top }}
            className={clsx(
              `${classPrefix}-main`,
              icon ? `${classPrefix}-main-icon` : `${classPrefix}-main-text`
            )}
          >
            {iconElement ? <div className={`${classPrefix}-icon`}>{iconElement}</div> : message}
          </div>
        </div>
      </div>
    </Mask>
  );
  // return (
  //   <IonToast
  //     isOpen={true}
  //     onDidDismiss={() => {
  //       props.afterClose?.();
  //       // if (props.onClose) props.onClose();
  //     }}
  //     // message={props.message}
  //     // icon={informationCircle}
  //     // position="middle"
  //     {...props}
  //   />
  // );
};

export function loading() {
  show({ icon: 'loading', maskClickable: false, duration: 60000 });
}

export function show(p: ToastProps | string) {
  const newP = typeof p === 'string' ? { message: p } : p;
  const props = {
    ...defaultProps,
    ...newP,
  } as any;

  let element: JSX.Element;
  if (props.customElement) {
    element = props.customElement({
      ...props,
      customElement: undefined,
    });
  } else {
    element = (
      <ToastInner
        {...props}
        onClose={() => {
          currentHandler = null;
        }}
      />
    );
  }
  if (currentHandler) {
    currentHandler.replace(element);
  } else {
    currentHandler = renderImperatively(element);
  }

  if (currentTimeout) {
    window.clearTimeout(currentTimeout);
  }
  if (props.duration !== 0) {
    currentTimeout = window.setTimeout(() => {
      clear();
    }, props.duration);
  }

  return currentHandler as ToastHandler;
}

export function clear() {
  currentHandler?.close();
  currentHandler = null;
}

export function config(val: ToastProps) {
  if (val.duration !== undefined) {
    defaultProps.duration = val.duration;
  }
  if (val.position !== undefined) {
    defaultProps.position = val.position;
  }
  if (val.customElement !== undefined) {
    defaultProps.customElement = val.customElement;
  }
}
