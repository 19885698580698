export default {
  accept: 'Aceptar',
  basicInfo: 'Información básica',
  iDPhoto: 'Foto del DNI',
  frontofID: 'Haga clic para cargar',
  backofID: 'Haga clic para cargar',
  name: 'Su nombre',
  iDNumber: 'Número de identificación',
  gender: 'Género',
  expiryDate: 'Fecha de vencimiento del certificado',
  homeAddress: 'Barrio residencial',
  detailedAddress: 'Dirección detallada',
  educationLevel: 'Nivel educativo',
  maritalStatus: 'Estado civil',
  age: 'Edad',
  salary: 'Salario',
  next: 'Próximo',
  nextStep: 'Próximo paso',
  save: 'Ahorrar',
  pleaseEnter: 'Por favor escribe',
  pleaseSelect: 'Por favor seleccione',
  cancel: 'Cancelar',
  confirm: 'Confirmar',
  selecting: 'Seleccionando',
  region: 'Región',
  emergencyContact: 'Contacto de emergencia',
  family: 'Familiar',
  relationship: 'Relación de contacto',
  loanAwareness: '¿Conoce su Historial Crediticio?',
  yes: 'Sí',
  no: 'No',
  bankInfo: 'Datos de la tarjeta bancaria',
  bankName: 'Nombre del banco',
  accountName: 'Nombre del titular de la cuenta',
  receivingAccount: 'Número de cuenta bancaria del destinatario',
  transitAccount: 'Cuenta de tránsito',
  faceRecognition: 'Reconocimiento facial',
  uploadFaceLoad: 'Haz clic para tomar una foto del rostro',
  submit: 'Entregar',
  transitAccountTips: 'Sólo se pueden introducir 20 dígitos',
  selectCompAddress: 'Por favor seleccione la dirección completa',
  systemMsgTitle: 'El mensaje del sistema',
  systemMsgDesc: 'Bienvenida a efectivo',
  actionMessageTitle: 'Mensaje de acción',
  actionMessageDesc: 'No hay mensajes disponibles',
  smsTips:
    'Necesitamos recolectar todos los mensajes de texto SMS, sin embargo, únicamente procesaremos aquellos mensajes de carácter financiero que sean pertinentes. Como ejemplo, consideraremos los mensajes financieros que provengan de bancos y entidades crediticias, verificando su origen para asegurar que sean de instituciones bancarias convencionales. Concretamente, requerimos adquirir datos relacionados con préstamos, pagos y los detalles de transacciones contenidos en los mensajes financieros. Esto se hace con la finalidad de evaluar su deuda actual y su historial de pagos. Esto nos permitirá generar una calificación crediticia precisa y tomar decisiones en cuanto a la aprobación de préstamos basadas en su calificación crediticia, todo con el objetivo de prevenir posibles fraudes. Solo en caso de que usted haga clic en "Aceptar" procederemos a recopilar el contenido de los SMS. En ningún momento compartiremos esta información con terceros.',
  contactTips:
    'Por favor, asegúrese de obtener el consentimiento previo de su contacto de emergencia antes de proporcionarnos su información. Solo nos comunicaremos con su contacto de emergencia en caso de que no podamos contactarlo directamente.',
  gradeTitle: 'Su puntaje de riesgo credities',
  gradeDesc:
    'Las puntuaciones se generan en función de los materiales que usted envía y las puntuaciones de crédito evaluadas por nuestro modelo actualmente solo son válidas dentro del alcance de nuestra aplicación.',
  score: 'Puntaje',
  noLoanRecords: 'No hay registros de préstamos en este momento',
  noRepaymentRecords: 'No hay registros de pagos realizados en este momento',
  noMyselyTips: 'No puede incluirse usted mismo en los contactos',
  noSameContact: 'Contacto de emergencia duplicado',
  checkTips: 'En proceso de préstamo, por favor espere pacientemente.',
  comparisonFailed:
    'La comparación entre el testigo y el testigo no coincide, vuelva a subir la foto de rostro completo.',
  informationEntry: 'Ingreso de información',
  guideMsg:
    'Antes de realizar un préstamo, necesitamos que proporcione la siguiente información para evaluar su riesgo crediticio y agilizar los servicios que le brindamos.',
  identification: 'Identificación',
  personalInformation: 'Información personal',
  contacts: 'Contactos',
  bankCardDetails: 'Datos de la tarjeta bancaria',
  guideEnsure:
    'Nosotros cumpliremos estrictamente con nuestra política de privacidad y términos de servicio para garantizar la seguridad de su información.Después de recibir su notificación, eliminaremos inmediatamente su información.',
  guideEnsureSuffix: 'La información realy efectiva mejorará su tasa de aprobación de préstamos.',
  startFillingIn: 'Empezar a rellenar',
  identityInfo: 'Autenticación',
  pleaseNote: 'Por favor, tenga en cuenta:',
  avoidReflection: 'Evite los reflejos',
  avoidMissing: 'Evite omisiones',
  avoidAmbiguity: 'Evite ambigüedades',
  avoidOcclusion: 'Evite la oclusión',
  identityMsg:
    'Verifique la exactitud de la siguiente información. Si hay algún problema, realice correcciones manuales.',
  dateOfBirth: 'Fecha de nacimiento',
  previousStep: 'Paso anterior',
  contactTip: '*Confirme el número de teléfono que seleccionó en su agenda.',
  contactName: 'Nombre del contacto',
  bankMsg:
    '*El préstamo sólo se puede pagar con su propia tarjeta bancaria, complétela cuidadosamente.',
  fileUpload: 'Archivos adjuntos',
  fileMsg:
    'Asegúrese de que los documentos sean auténticos y válidos. De lo contrario, podría afectar su evaluación crediticia y resultar en la incapacidad para acceder a los servicios de préstamos.',
  selectFileType: 'Por favor, seleccione el tipo de archivo para cargar',
  fileRequired: 'Se requieren al menos tres cargas',
  incomeProof: 'Prueba de ingresos',
  notUploaded: 'No subido',
  uploaded: 'Cargado',
  otherLoan: 'Otros documentos de préstamo',
  loanPaymentProof: 'comprobante de pago del préstamo',
  upload: 'Cargar',
  taxRecords: 'Registros de impuestos',
  salaryStatements: 'Detalles salariales de los últimos tres meses.',
  companyLabor: 'Contrato laboral de empresa',
  otherIncomeProof: 'Otra prueba de ingresos',
  repaymentAccountrecords: 'Registros de cuentas de reembolso',
  DriverLicense: 'Licencia de conducir',
  carPurchaseContract: 'Contrato de compra de auto',
  housePurchaseContract: 'Contrato de compra de casa',
  fileTip: 'Cuanta más información cargue, mayores serán sus posibilidades de obtener un préstamo.',
  riskScore: 'Su puntuación de riesgo crediticio',
  riskScoreDesc:
    'Recopilaremos registros de mensajes de texto en su dispositivo para evaluar el estado crediticio, el comportamiento de riesgo y el comportamiento preventivo. Proporcionándole los límites de crédito y descuentos adecuados.Ls registros de SMS se cifrarán y se cargarán en nuestro servidor para proteger completamente la seguridad de sus datos.El contenido de los mensajes de texto es información confidencial y no compartiremos ni transferiremos dicha información a terceros bajo ninguna circunstancia.',
  smsRiskTitle: 'Recordar',
  smsRiskDesc:
    'Cuando solicita un préstamo, necesitamos su permiso para obtener permisos de SMS. Con su permiso, recopilaremos el contenido de los mensajes SMS en su dispositivo para evaluar su estado crediticio, su comportamiento de riesgo y prevenir el fraude, y usaremos SMS para recordárselo. gestión de fondos. El contenido del mensaje de texto se cifrará y se cargará en nuestro servidor. Protegeremos sus datos y esta información no será compartida ni transferida a terceros de ninguna manera. Si desea que se elimine información confidencial, contáctenos por correo electrónico a atespaciorameng@outlook.com.',
  decline: 'Rechazar',
  agree: 'Aceptar',
  fileName: 'documentos',
  confirmShooting: 'Confirmar la captura',
  skip: 'Saltar',
  assessment: 'Evaluación',
  pleaseEnterCardholder:
    'Por favor ingresa el nombre del titular de la tarjeta, de entre 5 y 25 caracteres.',
  pleaseEnterCardAccount: 'Por favor ingresa la cuenta receptora, de entre 10 y 20 dígitos.',
  facebook: 'Facebook',
  whatsApp: 'WhatsApp',
  whatsApp2: 'WhatsApp2',
  pleaseEnterFamilyDetails: 'Por favor ingresa una dirección detallada de entre 5 y 50 caracteres.',
  pleaseEnterFacebook: 'Por favor ingresa tu cuenta de Facebook, de entre 5 y 25 caracteres.',
  requireFacebook: 'Copie el enlace de la página de inicio.',
  notRequireFacebook: 'Por favor completa tu información de Facebook',
  pleaseEnterWhatsApp: 'Por favor ingresa tu cuenta de WhatsApp, de entre 5 y 25 caracteres.',
  guideTip:
    'Complete la evaluación de crédito paso a paso según su situación real; esto determinará su límite de crédito disponible.',
  additionalDocuments: 'Documentos adicionales ',
  optional: 'Opcional',
  proofTip:
    'Comprobante de ingresos, comprobante de préstamo, comprobante de liquidación de préstamo.',
  pleaseUploadIDPhoto: 'Por favor sube foto de identificación',
  Male: 'Masculino',
  Female: 'Femenino',
  modify: 'Modificar',
  loanServices: 'Servicios de préstamo disponibles para usted',
  matchedBased: 'Emparejado según tu puntuación',
  maximumLoanTerm: 'Plazo máximo del préstamo',
  apply: 'Aplicar',
  confirmApplying: 'Confirmemos que es el propio solicitante el que solicita el préstamo.',
  clickButton: 'Haga clic en el botón',
  placeViewfinder: 'Coloca tu cara en el visor.',
  followInstructions: 'Siga las instrucciones para completar la operación.',
  verifyMyIdentity: 'Verificar mi identidad',
  // 新
  applyLoan: 'solicitar un préstamo',
  selectLoanAmount: 'Seleccione el monto del préstamo',
  applyLoanInfo: 'Solicitud online en 1 minuto/aprobación en 2 minutos/préstamo en 3 minutos',
  selectBorrowingPeriod: 'Seleccionar Ciclo',
  chooseLoanTerm: 'Elija el plazo del préstamo',
  weeksPeriod: '2 semanas/sesión',
  period: 'periodo',
  newPeriod: 'Periodo',
  serverfeeInfo:
    'El monto de la cuota es solo de referencia, la primera fecha de pago puede diferir según el tiempo de pago real',
  applyNow: 'Aplica ya',
  repaymentTime: 'tiempo de amortización',
  repaymentInfo:
    'Tomando como ejemplo el préstamo exitoso de hoy, el tiempo de pago estará sujeto al préstamo exitoso real.',
  repaymentAmount: 'Monto de reembolso',
  step: 'paso',
  check: 'Controlar',
  proofIncome: 'Comprobante de ingresos, ',
  proofLoan: ' comprobante de préstamo',
  proofLoanSettlement: 'comprobante de liquidación de préstamo',
  contactInfo:
    '*Por favor confirme el número de teléfono que seleccionó en su libreta de direcciones.',
  fileInfo: 'Se deben cargar al menos tres de los siguientes documentos',
  creditScore: 'Puntuación de crédito',
  faceInfo: 'Primero confirmamos que es el solicitante que solicita el préstamo.',
  selectLoanProduct: 'Por favor seleccione un producto de préstamo',
  week: 'Semana',
  nextWeek: 'La próxima semana',
  month: 'Mes',
  addressInformation: 'Datos del Domicilio',
  provinceCode: 'Código de provincia',
  postalCode: 'Código Postal',
  workInformation: 'Información de trabajo',
  employmentStatus: 'Estado de Empleo',
  income: 'Ingreso',
  salaryType: 'Tipo de salario',
  industry: 'Industria',
  occupation: 'Profesión',
  detailedCompanyAddress: 'Dirección detallada de la empresa',
  numberChildren: 'Numero de niños',
  loanContract: {
    title1: 'Contrato de prestamo',
    title2: 'Acuerdo de prestamo',
    info1:
      'Este Acuerdo describe los términos bajo los cuales Vitalidad Préstamos proporcionará un préstamo personal conocido como "Préstamo Personal" a la persona que proporciona los detalles y está incluido en este Acuerdo (el "Cliente").',
    info2: 'Prestatario:',
    info3: 'Número de identificación:',
    info4: 'Monto del préstamo:',
    info5: 'Artículo 1: Préstamo',
    info6:
      'Vitalidad Préstamos brinda préstamos personales en función del monto, plazo y propósito aplicado por el cliente, y los fondos se desembolsan a través de la información bancaria proporcionada por el cliente. Sin embargo, Vitalidad Préstamos se reserva el derecho de no pagar el préstamo si el mercado financiero, las condiciones políticas, económicas, legales o la situación financiera del cliente se revierten. En este caso, Vitalidad Préstamos notificará al cliente por escrito o por otro medio especificado en el contrato. Los clientes deben reconocer y aceptar los siguientes términos.',
    info7: 'Artículo 2: Cuenta de préstamo personal',
    info8:
      'Según el artículo 1, la cuenta a través de la cual se desembolsan los fondos de préstamos personales se denomina "cuenta de préstamos personales". En la medida que lo permitan las leyes y reglamentos, Vitalidad Préstamos podrá asignar fondos de préstamos, intereses, honorarios, comisiones y/o cualquier otro concepto que corresponda por concepto de montos impagos.',
    info9: 'Artículo 3: Intereses, Comisiones y Honorarios',
    info10:
      'Vitalidad Préstamos cobrará tasas de interés anuales efectivas, comisiones, tarifas, tasas de interés moratorias, tarifas de servicio y otras tarifas aplicables de acuerdo con las regulaciones aplicables. Una breve descripción de sus reglas de cobro.',
    info11: 'Artículo 4: Cambios al Contrato',
    info12:
      'El cliente autoriza a Vitalidad Préstamos a modificar unilateralmente las condiciones, comisiones, honorarios y cualesquiera otras cantidades impagas. Vitalidad Préstamos notificará al Cliente con 45 días naturales de antelación o cualquier otro plazo exigido por ley.',
    info13: 'Artículo 5: Cambios de contrato y rescisiones sin previo aviso',
    info14:
      'Debido a las normas prudenciales aplicables, Vitalidad Préstamos podrá optar por modificar o incluso rescindir el contrato con el cliente sin previo aviso, además de tasas de interés, comisiones o honorarios. Esto puede implicar, por ejemplo, disposiciones excesivas sobre gestión del riesgo de deuda para deudores minoristas, consideración de datos de clientes en relación con sistemas contra el blanqueo de dinero o financiación del terrorismo, detección de signos de fraude, transacciones inusuales, irregulares o sospechosas, incumplimiento de disposiciones legales, y falta de cumplimiento por parte de los clientes transparencia.',
    info15:
      'Si Vitalidad Préstamos decide resolver el contrato por los motivos mencionados en el párrafo anterior, el cliente estará obligado a pagar el importe total del crédito personal dentro de los 45 días naturales siguientes a la fecha de la notificación.',
    info16: 'Artículo 6: Estado de Cuenta',
    info17:
      'Los clientes pueden obtener información sobre el monto de la deuda de crédito personal, el monto de los próximos pagos u otra información relevante a través de la aplicación colectiva .',
    info18:
      'Si el cliente no recibe un extracto de cuenta antes de la fecha de vencimiento de pago especificada, se debe realizar una solicitud por escrito dentro del tiempo especificado o se considerará recibida a tiempo. La respuesta de Vitalidad Préstamos no limita el derecho del cliente a recurrir a instituciones administrativas, judiciales y/o arbitrales.',
    info19: 'Artículo 7: Modalidades y métodos de pago',
    info20:
      'El cliente está obligado a pagar el crédito personal según el calendario de pagos especificado.',
    info21:
      'Los pagos se realizarán mediante cuotas periódicas con una frecuencia y monto (incluidos intereses, comisiones y tarifas) en la moneda de pago, según se especifica en el Cuadro Resumen, y en las fechas especificadas.',
    info22: 'Artículo 8: Confirmación del cliente',
    info23:
      'Usted expresa expresamente su comprensión y acuerdo con las consecuencias financieras de la falta de pago a tiempo o del pago tardío de las tarifas.',
    info24:
      'Usted reconoce que la falta de pago oportuno o el pago tardío de las tarifas resultará en intereses adicionales, intereses de pago tardío y cualquier otro cargo relacionado que pueda aplicarse en caso de falta de pago. Estas tarifas servirán como compensación financiera por su incumplimiento de sus obligaciones de pago a tiempo.',
    info25:
      'Además, acepta que si no paga los intereses establecidos anteriormente y/o cualquier otro cargo relacionado que pueda aplicarse en caso de falta de pago, la parte impaga de la cuota especificada en el Cronograma resumido se agregará al monto de la última entrega. Esto significa que usted paga todos los montos pendientes en una sola suma, incluidos los cargos adicionales incurridos.',
    info26:
      'Además, usted entiende expresamente que cualquier retraso en el pago de sus créditos personales será reportado a la Central de Riesgos de la SBS de conformidad con la normativa vigente. Esta medida está diseñada para salvaguardar nuestra salud financiera y garantizar que se puedan seguir brindando nuestros servicios.',
    info27:
      'Lea y confirme atentamente los términos anteriores. Si tienes preguntas sobre algún contenido o necesitas más aclaraciones, estaremos encantados de ayudarte. Sin embargo, tenga en cuenta que solo podemos proceder con transacciones financieras relevantes con su consentimiento por escrito.',
    info28:
      'Gracias por su confianza y apoyo en nuestros servicios, y esperamos establecer una relación de cooperación estable y a largo plazo con usted.',
    info29: 'Artículo 9: Amortización anticipada',
    info30: '1. Los clientes tienen derecho a optar por pagar por adelantado.',
    info31:
      '2. Si el cliente opta por pagar por adelantado, Vitalidad Préstamos sólo cobrará intereses, comisiones y honorarios hasta la fecha del pago anticipado.',
    info32: '3. El pago por adelantado no generará cargos adicionales.',
    info33:
      '4. El pago anticipado dará lugar a una reducción de los intereses, comisiones y honorarios originalmente estipulados en los términos del contrato.',
    info34: 'Artículo 10: Violaciones y Terminación del Contrato',
    info35:
      'Vitalidad Préstamos puede rescindir el contrato por medios legales a partir de la fecha especificada sin previo aviso ni renuncia a todos los términos, y continuará cobrando la totalidad del saldo pendiente del crédito personal después de la liquidación, incluida la compensación por intereses, incumplimiento de contrato, comisiones y honorarios incurridos.',
    info36: 'Artículo 11: Información',
    info37:
      'El Cliente reconoce que en el marco de la relación que surge de este contrato, proporcionará a Vitalidad Préstamos información sobre su situación personal, financiera y crediticia. El Cliente está obligado a actualizar permanentemente esta información durante la vigencia del contrato, en particular en lo que respecta a la nacionalidad, el lugar de residencia o las condiciones fiscales.',
    info38:
      'Por otra parte, el Cliente reconoce la obligación de proporcionar toda la información financiera comercial y económica requerida por Vitalidad Préstamos para la ejecución de este contrato y de cumplir con la evaluación crediticia permanente de Vitalidad Préstamos . El Cliente deberá notificar a Vitalidad Préstamos dentro de los 15 días calendario cualquier hecho o circunstancia que pueda deteriorar materialmente sus ingresos, ganancias, capacidad de pago y/o condición financiera. El Cliente deberá actualizar permanentemente cualquier información previamente solicitada. El incumplimiento de las obligaciones aquí señaladas será motivo de resolución del presente contrato.',
    info39: 'Artículo 12: Operación',
    info40:
      'Vitalidad Préstamos puede ceder sus derechos o posición contractual bajo este Contrato a cualquier tercero, y el Cliente por la presente consiente dicha cesión.',
    info41: 'Artículo 13: Legislación y Jurisdicción',
    info42:
      'Este contrato se regirá además por las leyes vigentes en el Perú. En caso de cualquier desacuerdo respecto de la ejecución y/o interpretación del presente contrato, ambas partes se someterán a la jurisdicción de los jueces y tribunales de la ciudad donde se celebre el contrato y expresarán sus domicilios en la solicitud. Todas las citaciones y notificaciones judiciales o extrajudiciales que resulten aplicables se enviarán a la dirección especificada en este contrato y formarán parte de este contrato.',
    info43: 'Artículo 14: Declaración y Firma',
    info44:
      'El Cliente declara y reconoce que los datos y declaraciones contenidos en este documento son exactos, verdaderos y confiables. Mediante esta acción, usted declara expresamente que instruye y autoriza a Vitalidad Préstamos a modificar y/o reemplazar su última dirección registrada con la dirección que se muestra en este documento, autoriza a Vitalidad Préstamos a aplicar la nueva dirección a todos los demás productos que tenga o pueda tener en Vitalidad Préstamos o servicios.',
    info45:
      'El Cliente podrá acceder a una versión electrónica vigente de este Contrato y su hoja resumen en la sección Productos del sitio web ( https://www.uf-tek-top.com/home ).',
  },
};
