import { Http, HttpResponse } from '@quark-base-plugin/net';

export type SubmitApplicationOptions = {
  memberId: number;
  appInfoId: number;
  loanAmount: number;
  productNo: string;
  productStage: number;
  geoLatitude?: string;
  geoLongitude?: string;
  deviceId: string;
  appVersion: string;
  platform: string;
  ip?: string;
  langType: string;
  livingPhoto?: string;
  loanPurposeId?: number;
  orderType?: number;
  weekType?: number;
};

export type SubmitApplicationResult = {
  firstApplication: boolean;
  todayFirstApplication: boolean;
  reloan: boolean;
};

export const submitApplication = (
  options: SubmitApplicationOptions
): Promise<HttpResponse<SubmitApplicationResult>> => {
  return Http.post('/application/application-submit', options);
};

export const checkApplication = (productNo: string) => {
  return Http.post<{
    allowed: boolean;
    errorCode: string;
    message: string;
  }>('/application/application-check', { productNo });
};

export const checkWitnessesCompare = () => {
  return Http.post('/member/certificate-info/pe/face-verify');
};
