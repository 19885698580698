import { NativeResult } from '../call';
import { AppPermissionResult, AppPermissionState, BaseNativePlugin } from './base';

export type LocaltionPluginType = {
  checkPermission(): Promise<NativeResult<AppPermissionResult>>;

  requestPermission(): Promise<
    NativeResult<AppPermissionResult>
  >;

  getCoordinate(): Promise<
    NativeResult<{
      lat: number;
      lng: number;
    }>
  >;
};

export class LocationPlugin extends BaseNativePlugin implements LocaltionPluginType {
  checkPermission(): Promise<NativeResult<AppPermissionResult>> {
    return super.callPlugin<AppPermissionResult>('LocationPlugin', 'getCoordinate');
  }
  requestPermission(): Promise<NativeResult<AppPermissionResult>> {
    return super.callPlugin<AppPermissionResult>(
      'LocationPlugin',
      'requestPermission',
    );
  }
  getCoordinate(): Promise<NativeResult<{ lat: number; lng: number; }>> {
    return super.callPlugin<{ lat: number; lng: number; }>('LocationPlugin', 'getCoordinate');
  }
}
