import React from 'react';

import { RoutePage } from './Routes';

/**
 * 路径
 */
export enum Urls {
  APP = '/app',
  APP_HOME = '/app/home',
  APP_ME = '/app/me',
  APP_REPAY = '/app/repay',
  APP_MSG = '/app/msg',

  LOGIN = '/login',

  APPLY_STEP = '/apply-step/:step',
  APPLY_STEP_ID = '/apply-step/id',
  APPLY_STEP_BASIC = '/apply-step/basic',
  APPLY_STEP_PERSONAL = '/apply-step/personal',
  APPLY_STEP_WORK = '/apply-step/work',
  APPLY_STEP_CONTACT = '/apply-step/contact',
  APPLY_STEP_WITHDRAW = '/apply-step/withdraw',
  APPLY_STEP_ALL = '/apply-step/all',
  APPLY_STEP_GUIDE = '/apply-step/guide',
  APPLY_STEP_FILE = '/apply-step/file',
  APPLY_STEP_LIVINGBODY = '/apply-step/livingbody',

  HOME_REPAY = '/home/repay',

  RECORD_LOAN = '/record/loan',
  RECORD_REPAY = '/record/repay',
  RECORD_PLAN = '/record/plan',

  REPAY_DETAIL = '/repay/detail',
  REPAY_DETAIL_INSTALLMENT = '/repay/installmentDetail',

  APP_REPAY_INVOICE = '/repay/invoice',
  APP_REPAY_INVOICE_PAID = '/repay/invoice/paid',
  APP_REPAY_HISTORY = '/repay/history',

  PRODUCT_CREDIT = '/product/credit',
  PRODUCT_CONFIRM = '/product/confirm',
  PRODUCT_REPAYPLAN = '/product/repay-plan',
  PRODUCT_FACE = '/product/face',
  PRODUCT_APPLY = '/product/apply',

  RECORD_INSTALLMENT_DETAIL = '/record/installmentDetails',
  RECORD_DETAIL = '/record/detail',

  APP_ME_SETTING = '/me/setting',
  APP_ME_MATERIAL = '/me/material',
  APP_ME_PROTOCOL = '/me/protocol',
  APP_ME_DELETE_USER = '/me/setting/logout',
  APP_ME_FAQ = '/me/faq',
  APP_ME_PASSWORD_MANAGE = '/me/password',
  APP_ME_FEEDBACK = '/me/feedback',
  APP_ME_SETPWD = '/me/setpwd',
  APP_ME_FORGOTPWD = '/me/forgotpwd',
  APP_ME_RATIONAL = '/me/rational',
  APP_ME_STORY = '/me/story',
  APP_ME_STORY_DETAIL = '/me/story/detail',

  PROTOCOL = '/protocol/:id',
  LANDING = '/landing',

  REGISTER_SUCCESS = '/register/success',
  APP_ME_SERVICE = '/me/service',

  EXTENSION_DETAIL='/extension/detail',
  EXTENSION_REPAY='/extension/repay',
}

/**
 * 路由配置
 */
const defineRoutes: RoutePage[] = [
  {
    path: Urls.LOGIN,
    component: '/login',
    protected: false,
  },
  {
    path: Urls.APP_REPAY,
    component: '/repay',
  },
  {
    path: Urls.APPLY_STEP_BASIC,
    component: '/applyStep/basic',
    protected: false,
  },
  {
    path: Urls.APPLY_STEP_PERSONAL,
    component: '/applyStep/personal',
    protected: false,
  },
  {
    path: Urls.APPLY_STEP_ID,
    component: '/applyStep/id',
    protected: false,
  },
  {
    path: Urls.APPLY_STEP_WORK,
    component: '/applyStep/work',
    protected: false,
  },
  {
    path: Urls.APPLY_STEP_CONTACT,
    component: '/applyStep/contact',
    protected: false,
  },
  {
    path: Urls.APPLY_STEP_WITHDRAW,
    component: '/applyStep/withdraw',
    protected: false,
  },
  {
    path: Urls.APPLY_STEP_ALL,
    component: '/applyStep/all',
    protected: false,
  },
  {
    path: Urls.APPLY_STEP_LIVINGBODY,
    component: '/applyStep/livingbody',
    protected: false,
  },
  {
    path: Urls.APPLY_STEP_GUIDE,
    component: '/applyStep/guide',
    protected: false,
  },
  {
    path: Urls.APPLY_STEP_FILE,
    component: '/applyStep/file',
    protected: false,
  },
  {
    path: Urls.HOME_REPAY,
    component: '/home/repay',
  },
  {
    path: Urls.RECORD_LOAN,
    component: '/record/loan',
  },
  {
    path: Urls.RECORD_REPAY,
    component: '/record/repay',
  },
  {
    path: Urls.RECORD_PLAN,
    component: '/record/plan',
  },
  {
    path: Urls.REPAY_DETAIL,
    component: '/repay/detail',
  },
  {
    path: Urls.REPAY_DETAIL_INSTALLMENT,
    component: '/repay/installment',
  },
  {
    path: Urls.APP_REPAY_INVOICE,
    component: '/home/repay/invoice',
  },
  {
    path: Urls.APP_REPAY_INVOICE_PAID,
    component: '/home/repay/invoice/paid',
  },
  {
    path: Urls.APP_REPAY_HISTORY,
    component: '/home/repay/history',
  },
  {
    path: Urls.PRODUCT_CREDIT,
    component: '/product/credit',
  },
  {
    path: Urls.PRODUCT_CONFIRM,
    component: '/product/confirm',
  },
  {
    path: Urls.PRODUCT_REPAYPLAN,
    component: '/product/repay-plan',
  },
  {
    path: Urls.PRODUCT_FACE,
    component: '/product/face',
  },
  {
    path: Urls.PRODUCT_APPLY,
    component: '/product/apply',
  },
  {
    path: Urls.RECORD_INSTALLMENT_DETAIL,
    component: '/record/installment',
  },
  {
    path: Urls.RECORD_DETAIL,
    component: '/record/detail',
  },
  {
    path: Urls.APP_ME_SETTING,
    component: '/me/setting',
    protected: false,
  },
  {
    path: Urls.APP_ME_MATERIAL,
    component: '/me/material',
  },
  {
    path: Urls.APP_ME_PROTOCOL,
    component: '/me/protocol',
    protected: false,
  },
  {
    path: Urls.APP_ME_FAQ,
    component: '/me/faq',
    protected: false,
  },
  {
    path: Urls.APP_ME_PASSWORD_MANAGE,
    component: '/me/password',
  },
  {
    path: Urls.APP_ME_FEEDBACK,
    component: '/me/feedback',
  },
  {
    path: Urls.APP_ME_SETPWD,
    component: '/me/setpwd',
    protected: false,
  },
  {
    path: Urls.APP_ME_FORGOTPWD,
    component: '/me/forgotpwd',
    protected: false,
  },
  {
    path: Urls.APP_ME_RATIONAL,
    component: '/me/rational',
    protected: false,
  },
  {
    path: Urls.APP_ME_STORY,
    component: '/me/story',
    protected: false,
  },
  {
    path: Urls.APP_ME_STORY_DETAIL,
    component: '/me/story/detail',
    protected: false,
  },
  {
    path: Urls.APP_ME_DELETE_USER,
    component: '/me/setting/logoutUser',
  },
  {
    path: Urls.PROTOCOL,
    component: '/protocol',
    protected: false,
  },
  {
    path: Urls.REGISTER_SUCCESS,
    component: '/login/success',
    protected: false,
  },
  {
    path: Urls.APP_MSG,
    component: '/msg',
    protected: false,
  },
  {
    path: Urls.APP_ME_SERVICE,
    component: '/service',
  },
  {
    path: Urls.EXTENSION_DETAIL,
    component: '/extension',
    protected: false,
  },
  {
    path: Urls.EXTENSION_REPAY,
    component: '/repay/extensionRepay',
    protected: false,
  },
];

const routes = defineRoutes.map((route) => {
  let componentPath = route.component as string;
  if (!componentPath.startsWith('/')) {
    componentPath = '/' + componentPath;
  }
  return { ...route, component: React.lazy(() => import(`@/pages${componentPath}`)) };
});

export { routes };
