import { useForm } from '@quark-base-plugin/core/src/components/form';
import { notificationStore } from '@quark-base-plugin/storage';
import formatUtils from '@quark-base-plugin/utils/formatUtils';
// import '@quark-base-plugin/device';
import {
  useBoolean,
  useLocalStorageState,
  useMemoizedFn,
  useRequest,
  useSafeState,
  useUpdateEffect,
} from 'ahooks';
import { useEffect, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { useAppContext } from '../../app/app';
import { useGlobalContext } from '../../app/global';
import { useUserContext } from '../../app/user';
import { loadBasicDict } from '../../appStep';
import { ApplyStepItem, useGetApplyStep } from '../../appStep/useGetStep';
import { checkApplication } from '../api/application';
import { getCalculatedProduct, getProductQuota, getStageGroupList } from '../api/product';
import { ProductQuota } from '../types/quota';

import { useSubmitApplication } from './useSubmitApplication';

import { showCustomModal } from '@/components/customModal';
import { Urls } from '@/routes';

const AGREE_CACHE_KEY = '_PRODUCT_CONFIRM_AGREE_MENT';

export type ProductConfirmProps = {
  applyStepRouters: ApplyStepItem[];
  loanAmountKey?: string;
  repayPlanUrl?: string;
  /**
   * 自定义在提交前需要做的操作, 返回值 true: 通过 false: 不通过
   */
  preCommitPromise?: () => Promise<boolean>;
  onSuccess?: () => void;
};

export function useProductConfirm({
  applyStepRouters,
  loanAmountKey = 'loanAmount',
  repayPlanUrl = '/product/repay-plan',
}: ProductConfirmProps) {
  const checkAgree = useMemoizedFn(() => {
    if (!isAgree) {
      //TODO: 翻译
      notificationStore.addToastNotification(
        'Please tick to agree to <Loan Agreeement>、<Privacy Policy>'
      );
      return false;
    }
    return true;
  });

  const {
    Form,
    FormItem,
    formRef: { control, setValue, getValues, trigger, clearErrors },
  } = useForm<any>({
    onFinish,
    mode: 'onChange',
    // defaultValues: async () => {
    //   await new Promise<void>((resolve, reject) => {
    //     setTimeout(() => {
    //       resolve();
    //     }, 5000);
    //   });
    //   return { loanAmount: 4444 };
    // },
  });
  const {
    dataAction: {
      loanAmount,
      setLoanAmount,
      product,
      setProduct,
      stage,
      setStage,
      loanPurpose,
      setLoanPurpose,
    },
    submitAction: { isSubmitingApplication },
  } = useSubmitApplication();
  const { data: dictResult } = useRequest(loadBasicDict, {
    cacheKey: 'step-dict-info',
    staleTime: 50000,
  });
  // const { products } = useGlobalContext();
  const [isExpandStages, setIsExpandStages] = useLocalStorageState<boolean>(
    'product-confirm-stage-expand'
  );

  const { products, loadingProducts } = useGlobalContext();

  const {
    data: productTrial,
    loading: isCalculating,
    runAsync: calculateProduct,
  } = useRequest(getCalculatedProduct, {
    retryCount: 3,
    manual: true,
    // debounceWait: 300,
  });

  const { data: stageGroups } = useRequest(getStageGroupList, {
    cacheKey: 'product-getStageGroupList',
    staleTime: 5000,
  });

  // const [stageList, setStageList] = useSafeState<ProductStage[] | undefined>();
  const [isAgree, setIsAgree] = useLocalStorageState<boolean>(AGREE_CACHE_KEY);

  const [isOpenFeeModal, { toggle: toggleIsOpenFeeModal }] = useBoolean(false);
  const loanAmountWatch = useWatch({
    control,
    name: loanAmountKey,
  });

  const {
    data: productQuotaData,
    run: getProductQuotaRequest,
    loading,
  } = useRequest(getProductQuota, {
    retryCount: 3,
    manual: true,
    cacheKey: 'product-getProductQuota',
    staleTime: 50000,
  });

  const productQuota = useMemo(() => {
    return productQuotaData?.data;
    // return {
    //   totalQuotaAmount: 20000,
    //   remainingAvailableAmount: 20000,
    //   minQuota: 1000,
    // } as ProductQuota;
  }, [productQuotaData]);

  useEffect(() => {
    if (products.length > 0) {
      const defaultProduct = products[0];
      setProduct(defaultProduct);
      getProductQuotaRequest(defaultProduct.productNo);
    }
  }, [products, setProduct, getProductQuotaRequest]);

  const minLoanAmount = useMemo(() => {
    return productQuota?.minQuota || 0;
  }, [productQuota]);
  const maxLoanAmount = useMemo(() => {
    return productQuota?.remainingAvailableAmount || 0;
  }, [productQuota]);

  const stageList = useMemo(() => {
    const amount = Number(formatUtils.money2digits(loanAmount));
    if (!amount || amount === 0) {
      return [];
    }
    if (amount % 100 != 0) {
      return [];
    }
    const stageGroupsData = stageGroups?.data;
    const stageGroup = stageGroupsData?.filter((group, index) => {
      if (index == 0) {
        return amount >= group.minQuota && amount <= group.maxQuota;
      }
      return amount > group.minQuota && amount <= group.maxQuota;
    });
    if (stageGroup?.length) {
      return stageGroup[0].stages;
    }
    return [];
  }, [stageGroups?.data, loanAmount]);

  useMemo(() => {
    if (!stageList?.length) {
      setStage(undefined);
    }
  }, [stageList, setStage]);

  useEffect(() => {
    if (loanAmount) {
      setValue(loanAmountKey, loanAmount);
    }
  }, []);

  useUpdateEffect(() => {
    setLoanAmount(loanAmountWatch);
  }, [loanAmountWatch]);

  // useEffect(() => {
  //   if (loanAmountCache) {
  //     setValue(loanAmountKey, loanAmountCache);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (!loanAmount || !formatUtils.money2digits(loanAmount) || !stage || !product) {
  //     return;
  //   }
  //   let minAmount = minLoanAmount;
  //   const maxAmount = maxLoanAmount;
  //   if (minAmount > maxAmount) {
  //     minAmount = maxAmount;
  //   }
  //   const loanAmountD = Number(formatUtils.money2digits(loanAmount));
  //   if (loanAmountD < minAmount || loanAmountD > maxAmount) {
  //     return;
  //   }
  //   if (isCalculating) {
  //     return;
  //   }
  //   calculateProduct({
  //     productId: product.id,
  //     loanAmount: loanAmountD,
  //     productStage: stage.stage,
  //     productNo: product.productNo,
  //   });
  // }, [product, loanAmount, stage, minLoanAmount, maxLoanAmount, calculateProduct]);

  function selectStage(stage: number) {
    const selected = stageList?.filter((s) => s.stage === stage)?.[0];
    if (selected) {
      setStage(selected);
    }
  }

  const history = useHistory();

  const pushRepayPlanPage = useMemoizedFn(async () => {
    if (!loanAmount || !formatUtils.money2digits(loanAmount) || !stage || !product) {
      return;
    }
    const result = await calculateProduct({
      productId: product?.id,
      loanAmount: Number(formatUtils.money2digits(loanAmount)),
      productStage: stage.stage,
      productNo: product?.productNo,
    });
    history.push(repayPlanUrl, {
      data: result?.data,
    });
  });

  const checkCanApply = useMemoizedFn(async (productNo: string) => {
    const result = await checkApplication(productNo);
    if (result?.data?.allowed === false) {
      notificationStore.addToastNotification(result?.data?.message);
    }
    return result?.data && result?.data.allowed;
  });

  const { loading: isCheckingCanApply, runAsync: runCheckCanApply } = useRequest(checkCanApply, {
    manual: true,
  });

  const { t } = useTranslation();
  const { user } = useUserContext();
  const { appInfo, deviceInfo } = useAppContext();
  const [isSubmiting, setIsSubmiting] = useSafeState<boolean>(false);

  const { getApplyStepUrlUnFinished } = useGetApplyStep({ applyStepRouters, ready: !!user });

  const pushNextPage = useMemoizedFn(() => {
    let url;
    if (!url) {
      url = getApplyStepUrlUnFinished();
    }
    if (url) {
      history.push(url);
    } else {
      history.push(Urls.PRODUCT_FACE);
    }
  });

  async function onFinish() {
    if (!checkAgree()) {
      return false;
    }

    clearErrors();

    const result = await trigger();
    if (!result) {
      return false;
    }

    //TODO: 先注释
    setIsSubmiting(true);
    if ((await runCheckCanApply(product!.productNo)) === false) {
      setIsSubmiting(false);
      return false;
    }

    if (!user?.memberInfo?.newMember) {
      showCustomModal({
        confirmText: t('reloanUserWorkInfoChangeContinue'),
        cancelText: t('reloanUserWorkInfoChangeUpdate'),
        title: t('reloanUserWorkInfoChangeTitle'),
        desc: t('reloanUserWorkInfoChangeHint'),
        onConfirm: () => {
          // replace(Urls.APP_HOME);
          history.push(Urls.PRODUCT_FACE);
        },
        onCancel: () => {
          history.push(Urls.APPLY_STEP_ALL);
        },
      });
      return true;
    }
    pushNextPage();
    return true;
  }

  return {
    Form,
    FormItem,
    product,
    productQuota,
    minLoanAmount,
    maxLoanAmount,
    stage,
    stageList,
    selectStage,
    loanAmount,
    productTrial: productTrial?.data,
    // bankInfo: bankInfo?.data,
    isLoading: loading || loadingProducts,
    isSubmiting: isCheckingCanApply || isSubmitingApplication || isSubmiting,
    isCalculating,
    agreementProps: {
      isAgree,
      toggleIsAgree: useMemoizedFn(() => {
        setIsAgree(!isAgree);
      }),
    },
    feeModalProps: {
      isOpenFeeModal,
      toggleIsOpenFeeModal,
      productTrialBeforeFee: productTrial?.data?.preFees,
    },
    repayPlanProps: {
      productTrial: productTrial?.data,
      pushRepayPlanPage,
    },

    setValue,
    getValues,

    isExpandStages,
    setIsExpandStages,

    loanPurpose,
    setLoanPurpose,

    submit: onFinish,

    dictResult: dictResult?.data,
  };
}
