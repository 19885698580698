import { useUserContext } from '@quark-base-plugin/modules/app/user';
import { useMemoizedFn } from 'ahooks';
import React from 'react';
import { Redirect, Route } from 'react-router';

export interface RoutePage {
  /**
   * 路由url
   */
  readonly path: string;
  /**
   * 组件位置，默认从 pages 目录开始，例： '/login' (注意：string类型仅在 config.tsx 中使用)
   */
  readonly component: string | React.Component | React.ExoticComponent;
  /**
   * 是否精确匹配
   */
  readonly exact?: boolean;

  /**
   * 是否受保护的（需要授权登录才能访问），默认 true
   */
  readonly protected?: boolean;
}

export type RoutesProps = {
  routes?: RoutePage[];
};

const Routes: React.FC<RoutesProps> = (props) => {
  const { routes } = props;

  const { user } = useUserContext();

  const createRoute = useMemoizedFn((route: RoutePage) => {
    const needAuth = !user && route.protected !== false;

    return needAuth ? (
      <Route exact={route.exact || true} path={route.path} key={route.path}>
        <Redirect exact from={route.path} to={'/app/home'} />
      </Route>
    ) : (
      // <Redirect exact from={route.path} to={'/login'} />
      //@ts-ignore
      <Route
        exact={route.exact || true}
        path={route.path}
        key={route.path}
        //@ts-ignore
        component={route.component}
      />
    );
  });

  return <>{routes?.map((route) => createRoute(route))}</>;
};

export default Routes;
