export default {
  accept: 'Accept',
  basicInfo: 'Basic Information',
  iDPhoto: 'ID Photo',
  frontofID: 'Click to upload',
  backofID: 'Click to upload',
  name: 'Your Name',
  iDNumber: 'ID Number',
  gender: 'Gender',
  expiryDate: 'Certificate expiration date',
  homeAddress: 'Residential area',
  detailedAddress: 'Detailed Address',
  educationLevel: 'Education Level',
  maritalStatus: 'Marital Status',
  age: 'Age',
  salary: 'Salary',
  next: 'Next',
  nextStep: 'Next step',
  save: 'Save',
  pleaseEnter: 'Please Enter',
  pleaseSelect: 'Please Select',
  cancel: 'Cancel',
  confirm: 'Confirm',
  selecting: 'Selecting',
  region: 'Region',
  emergencyContact: 'Emergency Contact',
  family: 'Family',
  relationship: 'Contact relationship',
  loanAwareness: 'Loan Awareness',
  yes: 'Yes',
  no: 'No',
  bankInfo: 'Bank card details',
  bankName: 'Bank Name',
  accountName: 'Account Holder Name',
  receivingAccount: 'Receiving Account N0.',
  transitAccount: 'Intermediate Transfer Account N0.',
  faceRecognition: 'Face Recognition',
  uploadFaceLoad: 'Click to take a face photo',
  submit: 'Submit',
  transitAccountTips: 'Only 20 digits can be entered',
  selectCompAddress: 'Please select full address',
  systemMsgTitle: 'The system message',
  systemMsgDesc: 'Welcome to Cash',
  actionMessageTitle: 'Action message',
  actionMessageDesc: 'No messages available',
  smsTips:
    'We need to collect all SMS text messages, but we will only process those financial SMS messages that are relevant. For example, we will consider financial messages sent by banks and credit institutions, evaluating their source to verify that they come from typical banking institutions. Specifically, we require gathering information about loans, repayments, and transaction details contained in the financial messages to evaluate your current debt and payment history. This will enable us to generate an accurate credit score and make loan approval decisions based on your credit score, all to prevent possible fraud. Only if you click "Accept" will we proceed to collect the SMS content. At no point will we share this information with third parties',
  contactTips:
    'Please obtain prior consent from your emergency contact before providing us with their information. We will only reach out to your emergency contact if we are unable to reach you.',
  gradeTitle: 'Your Credit Risk Score',
  gradeDesc:
    'Scores are generated based on the materials you submit, and the credit scores assessed by our model are currently only valid within the scope of our application.',
  score: 'Score',
  noLoanRecords: 'No borrowing records available',
  noRepaymentRecords: 'No repayment records available',
  noMyselyTips: 'You cannot include yourself in contacts',
  noSameContact: 'Duplicate emergency conbankInfotact',
  checkTips: 'In loan process, please wait patiently.',
  comparisonFailed:
    'The comparison between the witness and the witness does not match, please re-upload the full face photo',
  informationEntry: 'Information entry',
  guideMsg:
    'Before making a loan, we need you to provide the following information to assess your credit risk and expedite the services we provide to you.',
  identification: 'Identification',
  personalInformation: 'Personal Information',
  contacts: 'Contacts',
  bankCardDetails: 'Bank card details',
  guideEnsure:
    'We will strictly comply with our privacy policy and service terms to ensure the security of your information.After receiving your notification, we will immediately delete your information.',
  guideEnsureSuffix: 'Real and effective information will improve your loan approval rate.',
  startFillingIn: 'Start filling in',
  identityInfo: 'Identity verification',
  pleaseNote: 'Please note',
  avoidReflection: 'avoid reflection',
  avoidMissing: 'avoid missing',
  avoidAmbiguity: 'avoid ambiguity',
  avoidOcclusion: 'avoid occlusion',
  identityMsg:
    'Please verify the accuracy of the following information. If there is an issue, make manual corrections.',
  dateOfBirth: 'Date of birth',
  previousStep: 'Previous Step',
  contactTip: '*Please confirm the phone number you selected in your address book',
  contactName: 'Contact name',
  bankMsg: '*The loan can only be paid to your own bank cardplease fill it out carefully.',
  fileUpload: 'Additional documents',
  fileMsg:
    'Please ensure that the documents are genuine and valid. Otherwise, it may impact your credit assessment and result in the inability to access loan services.',
  selectFileType: 'Please select the file type for upload',
  fileRequired: 'At least three uploads are required',
  incomeProof: 'Income proof',
  notUploaded: 'Not uploaded',
  uploaded: 'Uploaded',
  otherLoan: 'Other loan documentation',
  loanPaymentProof: 'Loan payment proof',
  upload: 'Upload',
  taxRecords: 'Tax records',
  salaryStatements: 'Salary statements for thelast three months',
  companyLabor: 'Company labor contract',
  otherIncomeProof: 'Other income proof',
  repaymentAccountrecords: 'Repayment accountrecords',
  DriverLicense: 'Driver license',
  carPurchaseContract: 'Car purchase contract',
  housePurchaseContract: 'House purchase contract',
  fileTip: 'The more information you upload, the better your chances of getting a loan.',
  riskScore: 'Your credit risk score',
  riskScoreDesc:
    'We will collect text message logs on your device to assess credit status, risk behavior and preventive behavior. Providing you with the right credit limits and discounts.SMS records will be encrypted and uploaded to our server to fully protect your data security.The content of text messages is confidential information, and we will not share or transfer such information to third parties under any circumstances.',
  smsRiskTitle: 'Recordar',
  smsRiskDesc:
    'Cuando solicita un préstamo, necesitamos su permiso para obtener permisos de SMS. Con su permiso, recopilaremos el contenido de los mensajes SMS en su dispositivo para evaluar su estado crediticio, su comportamiento de riesgo y prevenir el fraude, y usaremos SMS para recordárselo. gestión de fondos. El contenido del mensaje de texto se cifrará y se cargará en nuestro servidor. Protegeremos sus datos y esta información no será compartida ni transferida a terceros de ninguna manera. Si desea que se elimine información confidencial, contáctenos por correo electrónico a atespaciorameng@outlook.com.',
  decline: 'Decline',
  agree: 'Agree',
  fileName: 'file',
  confirmShooting: 'Confirm shooting',
  skip: 'Skip',
  assessment: 'Assessment',
  pleaseEnterCardholder: 'Please enter the cardholder is name, between 5-25 characters',
  pleaseEnterCardAccount: 'Please enter the receiving account, between 10-20 digits.',
  facebook: 'Facebook',
  whatsApp: 'WhatsApp',
  whatsApp2: 'WhatsApp2',
  pleaseEnterFamilyDetails: 'Please enter a detailed address between 5-50 characters.',
  pleaseEnterFacebook: 'Please enter your Facebook account, between 5-25 characters.',
  requireFacebook: 'Please copy the link from the home page.',
  notRequireFacebook: 'Please fill in your Facebook information',
  pleaseEnterWhatsApp: 'Please enter your WhatsApp account, between 5-25 characters.',
  guideTip:
    'Please complete the credit assessment step by step based on your actual situation; this will determine the available credit limit for you.',
  additionalDocuments: 'Additional documents ',
  optional: 'Optional',
  proofTip: 'Proof of income, proof of loan, proof of loan settlement',
  pleaseUploadIDPhoto: 'Please upload ID photo',
  Male: 'Male',
  Female: 'Female',
  modify: 'Modify',
  loanServices: 'Loan services available to you',
  matchedBased: 'Matched based on your score',
  maximumLoanTerm: 'Maximum loan term',
  apply: 'Apply',
  confirmApplying: 'Let is confirm that it is the applicant themselves applying for the loan.',
  clickButton: 'Click the button',
  placeViewfinder: 'Place your face in the viewfinder',
  followInstructions: 'Follow the instructions to complete the action',
  verifyMyIdentity: 'Verify my identity',
  // 新
  applyLoan: 'apply for a loan',
  selectLoanAmount: 'Select loan amount',
  applyLoanInfo: 'Online application in 1 minute/approval in 2 minutes/loan in 3 minutes',
  selectBorrowingPeriod: 'Select borrowing period',
  chooseLoanTerm: 'Choose the loan term',
  weeksPeriod: '2 Weeks / Period',
  period: 'Period',
  newPeriod: 'Periodo',
  serverfeeInfo:
    'The installment amount is for reference only, the first payment date may differ according to the actual payment time',
  applyNow: 'Apply Now',
  repaymentTime: 'Repayment time',
  repaymentInfo:
    'Taking today is successful loan as an example, the repayment time will be subject to the actual successful loan.',
  repaymentAmount: 'Repayment Amount',
  step: 'Step',
  check: 'Check',
  proofIncome: 'Proof of income',
  proofLoan: ' proof of loan',
  proofLoanSettlement: 'proof of loan settlement',
  contactInfo: '*Please confrm the phone number you selected in your addressbook',
  fileInfo: 'The following documents must be uploadedat leastthree of them',
  creditScore: 'credit score',
  faceInfo: 'Let is confirm that it is the applicant themselves applying for the loan.',
  selectLoanProduct: 'Please select a loan product',
  week: 'Week',
  nextWeek: 'NextWeek',
  month: 'Month',
  addressInformation: 'Address information',
  provinceCode: 'Province code',
  postalCode: 'Postal code',
  workInformation: 'Work information',
  employmentStatus: 'Employment status',
  income: 'Income',
  salaryType: 'Salary type',
  industry: 'Industry',
  occupation: 'Occupation',
  detailedCompanyAddress: 'Detailed Company Address',
  numberChildren: 'Number of children',
  loanContract: {
    title1: 'loan contract',
    title2: 'Loan agreement',
    info1:
      'This Agreement describes the terms under which Vitalidad Préstamos will provide a personal loan known as a "Personal Loan" to the person providing the details and who is included in this Agreement (the "Client").',
    info2: 'Borrower:',
    info3: 'Identification number:',
    info4: 'Loan amount:',
    info5: 'Article 1: Loan',
    info6:
      'Vitalidad Préstamos provides personal loans based on the amount, term and purpose applied by the client, and the funds are disbursed through the banking information provided by the client. However, Colectivo reserves the right not to pay the loan if the financial market, political, economic, legal conditions or the client is financial situation reverse. In this case, Vitalidad Préstamos will notify the client in writing or by other means specified in the contract. Customers must acknowledge and agree to the following terms.',
    info7: 'Article 2: Personal loan account',
    info8:
      'Under Article 1, the account through which personal loan funds are disbursed is called the “personal loan account.” To the extent permitted by laws and regulations, Colectivo may assign loan funds, interest, fees, commissions and/or any other corresponding concept for unpaid amounts.',
    info9: 'Article 3: Interest, Commissions and Fees',
    info10:
      'Vitalidad Préstamos will charge effective annual interest rates, commissions, fees, default interest rates, service fees and other applicable fees in accordance with applicable regulations. A brief description of your charging rules.',
    info11: 'Article 4: Changes to the Contract',
    info12:
      'The client authorizes colectivo to unilaterally modify the conditions, commissions, fees and any other unpaid amounts. Vitalidad Préstamos will notify the Client 45 calendar days in advance or any other period required by law.',
    info13: 'Article 5: Contract changes and terminations without prior notice',
    info14:
      'Due to the applicable prudential regulations, Vitalidad Préstamos may choose to modify or even terminate the contract with the client without prior notice, in addition to interest rates, commissions or fees. This may involve, for example, excessive provisions on debt risk management for retail debtors, consideration of customer data in relation to anti-money laundering or anti-terrorist financing systems, detection of signs of fraud, unusual, irregular or suspicious transactions , non-compliance with legal provisions, and lack of compliance by clients transparency.',
    info15:
      'If the Vitalidad Préstamos decides to terminate the contract for the reasons mentioned in the previous paragraph, the client will be obliged to pay the total amount of the personal credit within 45 calendar days following the date of notification.',
    info16: 'Article 6: Account Statement',
    info17:
      'Customers can obtain information about the amount of personal credit debt, the amount of upcoming payments or other relevant information through the Vitalidad Préstamos application.',
    info18:
      'If the customer does not receive an account statement by the specified payment due date, a written request must be made within the specified time or it will be deemed received on time. The Vitalidad Préstamos response does not limit the client is right to resort to administrative, judicial and/or arbitration institutions.',
    info19: 'Article 7: Customer confirmation',
    info20:
      'The client is obliged to pay the personal loan according to the specified payment schedule.',
    info21:
      'Payments will be made in periodic installments at a frequency and amount (including interest, commissions and fees) in the payment currency, as specified in the Summary Table, and on the dates specified.',
    info22: 'Article 8: Customer confirmation',
    info23:
      'You expressly express your understanding and agreement to the financial consequences of failure to pay on time or late payment of fees.',
    info24:
      'You acknowledge that failure to pay timely or late payment of fees will result in additional interest, late payment interest and any other related charges that may apply in the event of non-payment. These fees will serve as financial compensation for your failure to meet your timely payment obligations.',
    info25:
      'You further agree that if you fail to pay the interest set forth above and/or any other related charges that may apply in the event of non-payment, the unpaid portion of the installment specified in the Summary Schedule will be added to the final installment amount. This means that you pay all outstanding amounts in one lump sum, including any additional charges incurred.',
    info26:
      'Furthermore, you expressly understand that any delay in the payment of your personal credits will be reported to the SBS Risk Center in accordance with current regulations. This measure is designed to safeguard our financial health and ensure that our services can continue to be provided.',
    info27:
      'Please read and confirm the above terms carefully. If you have questions about any content or need further clarification, we will be happy to help you. However, please note that we can only proceed with relevant financial transactions with your written consent.',
    info28:
      'Thank you for your trust and support in our services, and we hope to establish a stable and long-term cooperative relationship with you.',
    info29: 'Article 9: Early amortization',
    info30: '1. Customers have the right to choose to pay in advance.',
    info31:
      '2. If the client chooses to pay in advance, Vitalidad Préstamos will only charge interest, commissions and fees until the date of advance payment.',
    info32: '3. Advance payment will not incur additional charges.',
    info33:
      '4. Early payment will result in a reduction of the interest, commissions and fees originally stipulated in the terms of the contract.',
    info34: 'Article 10: Violations and Termination of Contract',
    info35:
      'Vitalidad Préstamos may terminate the contract by legal means from the specified date without prior notice or waiver of all terms, and will continue to collect the entire outstanding balance of the personal credit after liquidation, including compensation for interest, breach of contract, commissions and fees incurred. .',
    info36: 'Article 11: Information',
    info37:
      'The Client acknowledges that within the framework of the relationship that arises from this contract, they will provide the group with information about their personal, financial and credit situation. The Client is obliged to permanently update this information during the term of the contract, in particular with regard to nationality, place of residence or tax conditions.',
    info38:
      'On the other hand, the Client recognizes the obligation to provide all the commercial and economic financial information required by the group for the execution of this contract and to comply with the permanent credit evaluation of the group. The Client must notify the Vitalidad Préstamos within 15 calendar days of any fact or circumstance that may materially deteriorate its income, profits, payment capacity and/or financial condition. The Client must permanently update any previously requested information. Failure to comply with the obligations indicated here will be grounds for termination of this contract.',
    info39: 'Article 12: Operation',
    info40:
      'Vitalidad Préstamos may assign its rights or contractual position under this Agreement to any third party, and Customer hereby consents to such assignment.',
    info41: 'Article 13: Legislation and Jurisdiction',
    info42:
      'This contract will also be governed by the laws in force in Peru. In the event of any disagreement regarding the execution and/or interpretation of this contract, both parties will submit to the jurisdiction of the judges and courts of the city where the contract is concluded and will express their addresses in the application. All applicable judicial or extrajudicial subpoenas and notifications will be sent to the address specified in this contract and will form part of this contract.',
    info43: 'Article 14: Declaration and Signature',
    info44:
      'The Client declares and acknowledges that the data and statements contained in this document are accurate, true and reliable. By this action, you expressly declare that you instruct and authorize Vitalidad Préstamos to modify and/or replace your last registered address with the address shown herein, authorize Vitalidad Préstamos to apply the new address to all other products that you have or may have in Vitalidad Préstamos or services.',
    info45:
      'The Client may access a current electronic version of this Contract and its summary sheet in the Products section of the website (xxx).',
  },
};
