export default {
  homeTab: 'Loan',
  repayTab: 'Loan Records',
  messageTab: 'Messages',
  myTab: 'Me',
  applyAgain: 'Apply again',
  chooseDiffTerms: 'Click to choose different terms',
  applyNow: 'Apply Now',
  withdraw: 'Withdraw',
  days: 'days',
  day: 'day',
  applyGuide: 'Loan Application Guide',
  clickToView: 'Click to view',
  learnAbout: 'Learn about loan application requirements and necessary documentation',
  choosePollo: 'Choose N Money',
  obtainCash: 'Get cash in five minutes',
  financialQualifications: 'Holds Financial Qualifications',
  safeAndReliable: 'Safe and Reliable',
  digitalTechnology: 'Digital Technology',
  aIApproval: 'AI Approval',
  onlineApproval: 'Online Approval',
  realtime: 'Real-time Disbursement',
  anytime: 'Borrow and Repay Anytime',
  warning:
    'Warning: Please provide genuine and valid loan application information. False information will not pass the review.',
  upTo: 'Maximum Credit Limit',
  chooseWarn: 'You will be able to apply for a loan after 14 days due to late payment behavior.',
  chooseWarn2: 'After payment, you can apply for more loans',
  whyDesc1:
    'N Money: Your Credit Partner! We take pride in holding legitimate financial qualifications and provide you with secure and reliable personal loan solutions. Borrow with peace of mind, trust us, as your financial security is our top priority.',
  whyDesc2:
    'N Money: Leading the future of tech loans! With our robust technological capabilities,we provide intelligent approvals with the help of artificial intelligence, making the loan process faster. Trust our AI and experience the new era of credit loans.',
  home_1: 'Holiday Notice',
  home_2:
    'Dear users, due to the start of the New Year holiday, there will be a certain delay in the arrival of the loan you applied for during the holiday period (January 1st to January 3rd). Please refer to the bank deposit time for the specific arrival time. Thank you for your support and understanding. I wish you a happy holiday.',
  home_3: 'Got it',
  welcomeHome: 'Hello and welcome to N Money',
  currentLimit: 'Your current limit',
  howMuch: 'How much money do you need？',
  loanAmountMsg: 'Please enter the loan amount.',
  howLong: 'How long do you want to use this loan?',
  agreedMsg: 'I’ve read and agreed to the',
  Agreement: 'Loan Agreement',
  fillForm: 'Fill the form',
  getCash: 'Get cash',
  yourLoanApplication: 'Your loan application：',
  loanAmount: 'Loan amount ',
  totalloanAmount: 'Total loan amount',
  loanTerm: 'Loan term',
  maximumLoanAmount: 'Maximum loan amount',
  minute: 'minute',
  whatloan: 'What is the desired duration for this loan?',
  creditAssessment: 'Credit assessment',
  verification: 'Verification',
  completed: 'Completed',
  notCompleted: 'Not completed',
  //新
  homeStartInfo1: 'Solicitud online en ',
  homeStartInfo2: ' minuto/aprobación en ',
  homeStartInfo3: 'minutos/préstamo en',
  homeStartInfo4: 'minutos',
  applyImmediately: 'Apply immediately',
  productDescription: 'Product description',
  sufficientAmount: 'Sufficient amount',
  loanAmountUpto: 'Loan amount Up to',
  flexibleMultipleTerms: 'Flexible multiple terms',
  homeDescriptionInfo1:
    'The number of repayment periods is flexible and optional, with a maximum of ',
  periods: 'periods',
  lowCost: 'Low cost',
  homeServiceFee: 'Service fee: capital*0.21',
  serviceFee: 'Service fee',
  homeInterest: 'Interest: 0.005*number of days',
  homePenaltyInterest: 'Penalty interest: 0.005*number of days',
  homeStartInfo5: 'The final payment amount will be subject to the actual total amount payable',
  userStories: 'User stories, they chose us',
  chooseLoanProduct: 'Choose the loan product that suits you',
  afterLogging: 'More exciting after logging in',
  homeModalServiceFee: 'The service fee includes：',
  laborAndPlatform: 'Labor and Platform Service Rates ',
  dataServiceFee: 'Data service fee',
  bankPaymentFees: 'Bank payment fees ',
  taxes: 'taxes  ',
  capital: ' capital',
  iSee: 'I see',
  creditEvaluation: 'Credit evaluation',
  creditEvaluationInfo: 'has not yet been completed Complete immediately, loan approved in seconds',
  second: 'second',
  loan: 'loan',
  totalLoan: 'Total loan amount requested',
  applicationTime: 'application time',
  homeRepayCard: {
    info1: 'Next repayment date',
    info2: 'Disbursement in progress',
    info3:
      'Your loan disbursement information has been submitted to the bank. Please refer to the local bank is service hours for the specific arrival time.',
    info4: 'Receiving account',
    info5: 'Bank Name',
    info6: 'Account Holder',
    info7: 'Receiving Account',
    info8: 'Intermediate Transfer Account',
    info9: 'Unpaid bill',
    info10: 'Total Due this Period',
    info11: 'Last payment date',
    info12: 'repayment',
    info13:
      'Repay the loan on time, and once the loan is settled, you can continue to borrow money.',
    info14: 'Late payment fee',
    info15: 'days overdue',
    info16: 'Caducado',
  },
  homeRepay: {
    loanDisbursed: 'Loan being disbursed',
    repaymentBtnTitle: 'Repayment',
    repayInfo1:
      'Your loan disbursement information has been submitted to the bank. Please refer to the local bank is service hours for the specific arrival time.',
    repayInfo2: 'Next repayment date',
    repayInfo3: 'Unpaid bill',
    repayInfo4: 'Days left',
    repayInfo5: 'Bill amount ',
    repayInfo6: 'Final repayment date',
    repayInfo7: 'Loan services available after repayment',
    repayInfo8:
      '*When using the loan service, cash can be expected in approximately 5 minutes.Not available after the bill is overdue.',
    repayInfo9: 'Amount',
    repayInfo10: 'Term',
    repayInfo11: 'days overdue',
    repayInfo12: 'Final repayment date',
    repayInfo13: 'Revision',
    repayInfo14: 'Remaining amount payable',
    repayInfo15: 'Amount repaid',
    repeyInfo16:
      'Repay your loan on time and you can continue to borrow money once the loan is paid off.',
    repayInfo17: 'Paga inmediatamente',
  },
};
