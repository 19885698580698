export default {
  hello: 'Have a nice day',
  loanRecords: 'Loan records',
  repaymentRecords: 'Repayment history',
  myCoupon: 'My coupon',
  ExcitingEvents: 'Exciting events, rewards, points marketplace under development.',
  activity: 'Activity',
  dataMgmt: 'Data Mgmt.',
  excitingEvents: 'Exciting Events',
  discountCoupons: 'Discount Coupons',
  limitIncrease: 'Limit Increase',
  LogInandRegister: 'Log in now',
  hasta: 'Up to',
  currentVersion: 'Current Version',
  feedback: 'Feedback',
  contactUs: 'Contact Us',
  logOut: 'Sign out',
  deleteAccount: 'Delete account',
  confirmLogOutDes:
    'Log out and we will clear your login status and help you jump to the login page.',
  settings: 'Settings',
  deleteAccountDesc:
    'Before deleting the account, please make sure that your loan has been fully repaid and there are no loan services currently in progress, otherwise the account deletion will fail. After deleting your account, you will be pushed out of the login window. If you log in again, you will be deemed to have re-registered.',
  changePassword: 'Change Password',
  confirmLogOutTitle: 'Are you sure to log out?',
  confirmLogOut: 'Confirm deletion',
  deleteAccountTitle: 'Are you sure you want to delete your account?',
  numberLoans: 'Number of loans: ',
  commonFunctions: 'Common Functions',
  otherFunctions: 'Other functions',
  aboutUs: 'About Us',
  version: 'Version',
  privacyAgreement: 'Privacy agreement',
  termsAndConditions: 'Terms and conditions',
  onlineService: 'Online service',
  pleaseSelectSubject: 'Please select the subject you want to respond to ',
  pleaseProblem: 'Please describe the problem you have encountered',
  emergencySituations: 'Emergency situations? Contact human service',
  feedbackSuccessful: 'Feedback successful',
  onlineSupport: 'Online support',
  questionType: 'Question type',
  attachmentOptional: 'Attachment（optional）',
  confirmDeletion: 'Confirm deletion',
  // 新
  chooseFinancialProducts:
    'We adhere to customer centricity and utilize technology to serve small and medium-sized enterprises. Based on underlying architectures such as artificial intelligence, financial cloud, blockchain, and the Internet of Things, we have constructed a financial brain that operates using technologies such as emotional computing, relational networks, and knowledge graphs, serving as an engine for perception and decision-making. The average daily processing capacity of big data reaches 100 elements. After billions of calculations, RPA robots have replaced over 60% of repetitive tasks in credit review, applying leading technological capabilities to intelligent marketing, intelligent risk control, intelligent management, intelligent operations, and full chain financing to accurately identify small amounts of credit. Risk characteristics, using technology to help users reduce financing costs.',
  userStories: 'User stories',
  seeMore: 'see more',
  userStoriesTitle1: 'De 0 a 5 millones, como di la vuelta',
  userStoriesInfo1: 'Cuando se trata de emprendimiento, el Sr. Hao es uno de los afortunados',
  userStoriesTitle2: 'Llevo diez años en el sector de la restauración.',
  userStoriesInfo2:
    'El Sr. Liao ha trabajado en la industria de la restauración durante más de diez años, ha experimentado la época dorada de la industria de la restauración y ahora se ha topado con un punto bajo en su vida.',
  userStoriesTitle3: 'La transformación de los "grandes fans" en la industria del entretenimiento',
  userStoriesInfo3:
    '"He estado en esta industria durante más de diez años y he conocido a mucha gente en la industria del entretenimiento". El Sr. Yang de Guangdong ha sido emprendedor en la industria de la comunicación cultural y fundó dos empresas. Actualmente, también se encuentra con dificultades financieras.',
  applyLoanAmount: 'Apply for the loan amount',
  millionPeopleSuccessfully: '8.92 million people successfully borrowed money',
  nowBorrow: 'Now borrow',
  moreFeatures: 'More features',
  account: 'Account',
  // 贷款各种状态文本 - 审核中或审批回退或审批拒绝或放款中
  totalRequested: 'Total loan amount requested',
  loanTerm: 'Loan term',
  rollbackInfo1: 'The loan information you submitted may be incorrect.',
  rollbackInfo2: 'Please review and modify the following information, then resubmit：',
  rollbackText1: '1. Please check the ID information.',
  rollbackText2: '2. Confirm if the bank card belongs to the applicant.',
  rollbackText3: '3. Verify if the bank card account number is correct.',
  modifyInformation: 'Modify information',
  refuseInfo1: 'Sorry, your loan application has been rejected. Apply again in 3 days.',
  refuseInfo2: 'Next application date',
  paymentInfo1:
    'Your loan disbursement information has been submitted to the bank. Please refer to the local bank is service hours for the specific arrival time.',
  rebates: 'Pagar',
};
