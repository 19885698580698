import { Analysis, AnalysisThirdEventName } from '@quark-base-plugin/analysis';
import formatUtils from '@quark-base-plugin/utils/formatUtils';
import { useMemoizedFn, clearCache, useLocalStorageState, useRequest } from 'ahooks';
import { Http } from '@quark-base-plugin/net';
import { isPlatform } from '@ionic/core';
import { Toast } from '@quark-base-plugin/core';
import { useTranslation } from 'react-i18next';
import PluginHelper from '@/inject.js';

import { useAppContext } from '../../app/app';
import { getCurrentPosition } from '../../app/auth';
import { useUserContext } from '../../app/user';
import {
  submitApplication,
  SubmitApplicationOptions,
  checkWitnessesCompare,
} from '../api/application';
import { Product, ProductStage } from '../types';
import { NativeBridge } from '@/native/index';
import { H5logEvent } from '@/providers/H5AnalyticsProvider';

const _CACHE_LOANAMOUNT_KEY = '_CACHE_SUBMIT_LOANAMOUNT_KEY';
const _CACHE_PRODUCT_KEY = '_CACHE_SUBMIT_PRODUCT_KEY';
const _CACHE_STAGE_KEY = '_CACHE_SUBMIT_STAGE_KEY';
const _CACHE_LOANPURPOSE_KEY = '_CACHE_LOANPURPOSE_KEY';
const _CACHE_HOME_REPAYDAY_WEEK_KEY = '_CACHE_HOME_REPAYDAY_WEEK_KEY';
import { _CACHE_HOME_REPAYDAY_KEY } from '@quark-base-plugin/storage';
import { useHistory } from 'react-router';
import { Urls } from '@/routes';

declare type SubmitProps = {
  options?: Partial<Record<string, any>>;
  onSuccess?: (data: any) => void;
  onFailed?: (data: any) => void;
};

async function PluginGetDeviceInfo() {
  return await NativeBridge.Plugins.appPlugin.getEquipmentInfo();
}

export function useSubmitApplication() {
  const { t } = useTranslation();
  const history = useHistory();
  const [loanAmount, setLoanAmount] = useLocalStorageState<string | undefined>(
    _CACHE_LOANAMOUNT_KEY
  );
  const [product, setProduct] = useLocalStorageState<Product | undefined>(_CACHE_PRODUCT_KEY);
  const [stage, setStage] = useLocalStorageState<ProductStage | undefined>(_CACHE_STAGE_KEY);
  const [loanPurpose, setLoanPurpose] = useLocalStorageState<number | undefined>(
    _CACHE_LOANPURPOSE_KEY
  );
  const [productStage, setProductStage] = useLocalStorageState(_CACHE_HOME_REPAYDAY_KEY); // 借款总期数
  const [productWeekNum, setProductWeekNum] = useLocalStorageState(_CACHE_HOME_REPAYDAY_WEEK_KEY, {
    // defaultValue: 1,
  }); // 借款周期间隔周数,默认1周

  const { user } = useUserContext();
  const { appInfo, deviceInfo } = useAppContext();

  const { loading: isSubmitingApplication, runAsync: runSubmitApplication } = useRequest(
    submitApplication,
    {
      debounceWait: 300,
      manual: true,
    }
  );

  const submit = useMemoizedFn(async (props?: SubmitProps) => {
    if (!productStage) {
      Toast.show({
        message: t('selectLoanProduct'),
      });
      setTimeout(() => {
        history.push(Urls.PRODUCT_APPLY);
      }, 1000);
      return;
    }
    const { options: otherOptions, onSuccess, onFailed } = props || {};
    const position = await getCurrentPosition();
    const info = (await PluginGetDeviceInfo()) as any;
    // if (!product || !stage || !loanAmount) {
    if (!product) {
      const content = JSON.stringify({
        product: product ? product : 'none',
        stage: stage ? stage : 'none',
        loanAmount: loanAmount ? product : 'none',
      });
      Analysis.logEvent({
        event: AnalysisThirdEventName.CustomSubmitApplicationFailed,
        params: {
          content,
        },
      });
      return false;
    }

    const options: SubmitApplicationOptions = {
      memberId: user!.memberId,
      appInfoId: appInfo!.appInfoId,
      loanAmount: Number(formatUtils.money2digits(loanAmount)), // 金额
      productNo: product!.productNo,
      // productStage: stage!.stage,
      // productStage: 0,
      productStage: Number(productStage) || 1,
      // geoLatitude: position.latitude,
      // geoLongitude: position.longitude,
      geoLatitude: position.latitude == 'undefined' ? '' : position.latitude,
      geoLongitude: position.longitude == 'undefined' ? '' : position.longitude,
      deviceId: deviceInfo!.deviceId,
      appVersion: appInfo!.appVersion!,
      platform: deviceInfo!.platform!,
      // ip: '', //ip不用传
      langType: deviceInfo!.langType!,
      // livingPhoto: '', //不用传
      loanPurposeId: loanPurpose,
      // orderType: 7,
      // orderType: 3,
      orderType: 8,
      weekType: Number(productWeekNum) || 1,
      ...otherOptions,
    };

    const result = await Http.post('/member/certificate-info/pe/face-verify');
    if (!result?.data) {
      Toast.show({ message: t('comparisonFailed'), duration: 5000 });
      return false;
    }

    Analysis.logEvent({ event: 'CustomSubmitApplicationStart' });
    try {
      H5logEvent(AnalysisThirdEventName.CustomSubmitApplication);
      Analysis.logEvent({ event: AnalysisThirdEventName.CustomSubmitApplication });
      const result = await runSubmitApplication(options);
      if (result.success) {
        H5logEvent(AnalysisThirdEventName.CustomSubmitApplicationSuccess);
        Analysis.logEvent({ event: AnalysisThirdEventName.CustomSubmitApplicationSuccess }); // 埋点：借款申请提交成功（授信项填写完成）
        if (result.data?.firstApplication) {
          H5logEvent(AnalysisThirdEventName.CustomFirstApply);
          Analysis.logEvent({ event: AnalysisThirdEventName.CustomFirstApply });
        }
        if (result.data?.todayFirstApplication) {
          H5logEvent(AnalysisThirdEventName.CustomFirstTodayApply);
          Analysis.logEvent({ event: AnalysisThirdEventName.CustomFirstTodayApply });
        }
        if (result.data?.reloan) {
          H5logEvent(AnalysisThirdEventName.CustomReloanSubmitApplication);
          Analysis.logEvent({ event: AnalysisThirdEventName.CustomReloanSubmitApplication });
        } else {
          H5logEvent(AnalysisThirdEventName.CustomNewSubmitApplication);
          Analysis.logEvent({ event: AnalysisThirdEventName.CustomNewSubmitApplication });
        }
        clearCache();
        setStage(undefined);
        setLoanAmount(undefined);
        // setProduct(undefined);
        setLoanPurpose(undefined);
        onSuccess?.(result);
      } else {
        onFailed?.(result);
        H5logEvent(AnalysisThirdEventName.CustomSubmitApplicationFailed);
        Analysis.logEvent({ event: AnalysisThirdEventName.CustomSubmitApplicationFailed });
      }
      return result;
    } catch (error: any) {
      H5logEvent(AnalysisThirdEventName.CustomSubmitApplicationFailed);
      Analysis.logEvent({
        event: AnalysisThirdEventName.CustomSubmitApplicationFailed,
        params: {
          content: error?.message || 'none',
        },
      });
      return false;
    }
  });

  return {
    dataAction: {
      loanAmount,
      setLoanAmount,
      product,
      setProduct,
      stage,
      setStage,
      loanPurpose,
      setLoanPurpose,
    },
    submitAction: {
      submit,
      isSubmitingApplication,
    },
  };
}
