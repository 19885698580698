import { useMemoizedFn, useSafeState } from 'ahooks';
import { useState } from 'react';

import { useAppContext } from '../../app/app';
import { useAuth } from '../../app/auth';
import { RequestVerifyCodeParam, VerifyCodeType } from '../api/login';

import { useTimer } from './useTimer';

export type Props = {
  sceneCode: 'LOGIN' | 'REGISTER' | 'CHANGE_PWD';
  checkAgree: () => boolean;
};

export function useSendCode({ sceneCode, checkAgree }: Props) {
  const [code, setCode] = useSafeState<string>();
  const [canSendVoiceCode, setCanSendVoiceCode] = useState(false);

  const { appInfo } = useAppContext();
  const { requestVerifyCode, isRequestingCode } = useAuth();
  const { start, timer } = useTimer({
    initValue: 60,
    onLoop: (v) => {
      if (v === 30) {
        setCanSendVoiceCode(true);
      }
    },
  });

  const requestSMSCodeFn = useMemoizedFn(
    async (
      telephoneNo: string,
      type: VerifyCodeType,
      shouldAgree: boolean | undefined = false
    ): Promise<boolean> => {
      if (shouldAgree && !checkAgree()) {
        return false;
      }
      const options: RequestVerifyCodeParam = {
        appInfoId: appInfo?.appInfoId || 0,
        telephoneNo,
        sceneCode,
        type,
      };
      const response = await requestVerifyCode(options);
      if (response.success) {
        start();
        if (response.data) {
          setCode(response.data);
        }
      }
      return response?.data;
    }
  );

  const resetCode = useMemoizedFn(() => {
    setCode('');
  });

  return {
    requestSMSCodeFn,
    isRequestingCode,
    canSendVoiceCode,
    code,
    resetCode,
    count: timer,
    isCountDown: timer > 0,
  };
}
