import { JSX } from '@ionic/core';
import { IonSpinner } from '@ionic/react';
import React from 'react';

import './Spinner.scss';

export type SpinnerProps = JSX.IonSpinner;

export const Spinner: React.FC<SpinnerProps> = React.memo(function Spinner({
  ...others
}: SpinnerProps) {
  return (
    <div className={'qk-spinner'}>
      <SpinnerInner {...others} />
    </div>
  );
});

export const SpinnerInner = ({ ...others }: any) => {
  return (
    <div className={'qk-spinner-inner'}>
      <IonSpinner name="circular" className="qk-spinner-inner-bubbles" {...others} />
    </div>
  );
};
