import { Http } from '@quark-base-plugin/net';

import { useApplyStepModule, UseApplyStepModuleProps } from '../step';

import { SaveParams, BasicInfoProps } from './type';

type UseBasicInfo = UseApplyStepModuleProps<BasicInfoProps>;

//保存基本信息
export async function onSaveInfo(params: SaveParams) {
  return await Http.post('/member/basis-info/save', params);
}

//加载基本信息
export async function onLoadInfo(): Promise<BasicInfoProps> {
  const result = await Http.post('/member/basis-info/load');
  return result?.data;
}


export function useApplyStepPersonalInfo({ defaultValues, ...others }: UseBasicInfo) {
  const step = useApplyStepModule<BasicInfoProps>({
    code: 'useApplyStepBasic',
    defaultValues,
    onLoad: onLoadInfo,
    onSave: onSaveInfo,
    ...others,
  });

  return {
    ...step,
  };
}
