import clsx from 'clsx';
import { CSSProperties } from 'react';
import './Card.scss';

export interface ICardProps {
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties | undefined;
  verticalPadding?: boolean;
}

export function Card(props: ICardProps) {
  const { children, className, style, verticalPadding } = props;
  return (
    <div
      className={clsx('qk-card', { 'qk-card-vertical-padding': verticalPadding }, className)}
      style={style}
    >
      {children}
    </div>
  );
}
