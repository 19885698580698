import { Plugins} from '@capacitor/core';
import { Analysis, AnalysisThirdEventName } from '@quark-base-plugin/analysis';
import { Spinner } from '@quark-base-plugin/core';
import { userStorage } from '@quark-base-plugin/storage';
import { Toast } from '@quark-base-plugin/core';
import { NativeBridge } from '../../../../apps/vitalidad_prestamos/src/native';

import {
  LoginParam,
  loginBySms,
  RequestVerifyCodeParam,
  reuqestVerifyCode,
  register,
  loginByPwd,
  LoginByPwdParam,
} from '../../login/api/login';
import { AuthUser } from '../../login/types';

import { initReactQueryAuth } from './react-query-auth';

async function getPositionResult() {
  return await NativeBridge.Plugins.locationPlugin.getCoordinate();
}

export async function getCurrentPosition(): Promise<{ latitude: string; longitude: string }> {
  try {
    // const coordinates = await Plugins.Geolocation.getCurrentPosition();
    const coordinates = (await getPositionResult()) as any;
    console.log('Current position:', coordinates);
    return {
      latitude: coordinates ? coordinates?.data?.latitude + '' : '',
      longitude: coordinates ? coordinates?.data?.longitude + '' : '',
    };
  } catch (error) {
    return {
      latitude: '',
      longitude: '',
    };
  }
}

async function requestVerifyCodeFunc(data: RequestVerifyCodeParam) {
  Toast.loading()
  const response = await reuqestVerifyCode(data);
  Toast.clear()
  return response;
}

async function loginFunc(data: LoginParam) {
  //TODO: 获取设备的fcm key
  const fcmKey = '';
  //TODO: 获取位置

  const gps = await getCurrentPosition();

  const response = await loginBySms({
    ...data,
    fcmKey,
    // ...gps,
    latitude: gps?.latitude == 'undefined' ? '' : gps?.latitude,
    longitude: gps?.longitude == 'undefined' ? '' : gps?.longitude
  });
  if (response.data?.accessToken) {
    await userStorage.setUser(response.data);
    await userStorage.setToken(response.data.accessToken);
  }
  return response.data!;
}

async function loginByPwdFunc(data: LoginByPwdParam) {
  //TODO: 获取设备的fcm key
  const fcmKey = '';

  const gps = await getCurrentPosition();

  const response = await loginByPwd({
    ...data,
    fcmKey,
    // ...gps,
    latitude: gps?.latitude == 'undefined' ? '' : gps?.latitude,
    longitude: gps?.longitude == 'undefined' ? '' : gps?.longitude
  });
  if (response.data?.accessToken) {
    await userStorage.setUser(response.data);
    await userStorage.setToken(response.data.accessToken);
  }
  return response.data!;
}

async function registerFunc(data: any) {
  //TODO: 获取设备的fcm key
  const fcmKey = '';
  const gps = await getCurrentPosition();

  const response = await register({
    ...data,
    fcmKey,
    // ...gps,
    latitude: gps?.latitude == 'undefined' ? '' : gps?.latitude,
    longitude: gps?.longitude == 'undefined' ? '' : gps?.longitude
  });
  if (response.data?.accessToken) {
    Analysis.logEvent({ event: AnalysisThirdEventName.CustomRegisterSuccess }); // 埋点：用户注册成功（接口调用成功）
    await userStorage.setUser(response.data);
    await userStorage.setToken(response.data.accessToken);
  }
  return response.data!;
}

const config = {
  // loadUser,
  requestVerifyCodeFunc,
  loginFunc,
  loginByPwdFunc,
  registerFunc,
  // logoutFunc,
  LoadingComponent: Spinner,
} as any;

export const { AuthProvider, useAuth } = initReactQueryAuth<
  AuthUser | null,
  // unknown,
  LoginParam,
  unknown,
  RequestVerifyCodeParam
>(config);
