import { Http, HttpResponse } from '@quark-base-plugin/net';

import { MemberInfoResponse, UserResponse } from '../types';

export enum VerifyCodeType {
  SMS = 1,
  VOICE = 2,
}

export type RequestVerifyCodeParam = {
  /**
   * 马甲包
   */
  appInfoId: number;
  /**
   * 验证码使用场景LOGIN,REGISTER,CHANGE_PWD
   */
  sceneCode: 'LOGIN' | 'REGISTER' | 'CHANGE_PWD';
  /**
   * 电话号码
   */
  telephoneNo: string;
  /**
   * 验证码类型，1短信，2语音
   */
  type: VerifyCodeType;
  // /**
  //  * 电话区号
  //  */
  // zoneCode: string;
};

export const reuqestVerifyCode = (
  data: RequestVerifyCodeParam
): Promise<HttpResponse<string | undefined>> => {
  return Http.post<string | undefined>('/member/send-sms-code', data);
};

export type LoginParam = {
  // zoneCode: string;
  telephoneNo: string;
  smsCode: string;

  fcmKey?: string;
  longitude?: string;
  latitude?: string;
};

export const loginBySms = (data: LoginParam): Promise<UserResponse> => {
  return Http.post('/member/login-by-sms', data);
};

export type LoginByPwdParam = {
  // zoneCode: string;
  telephoneNo: string;
  password: string;

  fcmKey?: string;
  longitude?: string;
  latitude?: string;
};

export const loginByPwd = (data: LoginByPwdParam): Promise<UserResponse> => {
  return Http.post('/member/login-by-password', data);
};

export type RegisterParam = {
  // zoneCode: string;
  telephoneNo: string;
  smsCode: string;
  password: string;

  fcmKey?: string;
  longitude?: string;
  latitude?: string;
};

export const register = (data: RegisterParam): Promise<UserResponse> => {
  return Http.post('/member/register-with-password', data);
};

export const checkRegisterStatus = (data: {
  // zoneCode: string;
  telephoneNo: string;
}): Promise<HttpResponse<boolean>> => {
  return Http.post('/member/check-register-status', data);
};

export const getMemberInfo = (): Promise<MemberInfoResponse> => {
  return Http.post('/member/get-member-info');
};

export const logout = (): Promise<HttpResponse> => {
  return Http.post('/member/logout');
};

export const refreshToken = (rToken: string): Promise<HttpResponse> => {
  return Http.post('/member/refresh-token', { refreshToken: rToken });
};

export type ModifyPasswordParam = {
  password: string;
  smsCode: string;
};

export const modifyPassword = ({ password, smsCode }: ModifyPasswordParam) => {
  return Http.post('/member/change-password', { password, smsCode });
};
