import { CacheLocation, FpjsProvider } from '@fingerprintjs/fingerprintjs-pro-react';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './flexible';

ReactDOM.render(
  <React.StrictMode>
    {/* <FpjsProvider
      // 正式环境再启用cache
      cacheLocation={CacheLocation.LocalStorage}
      cacheTimeInSeconds={60 * 60 * 24}
      loadOptions={{
        // public api key
        apiKey: 'vFbosODBu6hyBeDUwcmQ',
        // 只有欧洲和孟买需要配置这个
        // region: "eu", // 欧洲
        // region: "ap", // 印度孟买
        // 子域配置后需要设置 endpoint
        endpoint: 'https://testh5.cyclecashin.com',
      }}
      cachePrefix={'QKSDGASHDJUCCAB13137TCJYAGCJSHG11'}
    >
      <App />
    </FpjsProvider> */}
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

defineCustomElements(window);
