import login from './en-US/login';
import home from './en-US/home';
import apply from './en-US/apply';
import me from './en-US/me';
import repay from './en-US/repay';
import extension from './en-US/extension';
export default {
  ...login,
  ...home,
  ...apply,
  ...me,
  ...repay,
  ...extension
};
