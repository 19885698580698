import { Plugins } from '@capacitor/core';

// const group = 'quark_cross_platform_app_storage';

// Plugins.Storage.configure({
//   group,
// });

const storage = {
  set: async (key: string, value: string) => {
    await Plugins.Storage.set({
      key,
      value,
    });
  },
  get: async (key: string) => {
    const { value } = await Plugins.Storage.get({ key });
    return value;
  },
  remove: async (key: string) => {
    await Plugins.Storage.remove({ key });
  },
  clearAll: async () => {
    await Plugins.Storage.clear();
  },
};

export { storage };
