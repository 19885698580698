import React, { CSSProperties, useMemo, useRef, useState } from 'react';

import './FileUploader.scss';
import { Http } from '@quark-base-plugin/net';
import fileUtils from '@quark-base-plugin/utils/fileUtils';
import { useMemoizedFn, useRequest } from 'ahooks';
import { ellipsisHorizontal } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

import { mergeProps } from '../../utils/with-default-props';
import ActionSheet, { Action, ActionSheetShowHandler } from '../action-sheet';
import { Label } from '../label';
import { Mask } from '../popup/mask';
import { Space } from '../space';
import { Spinner } from '../spinner';

import _ from 'lodash';

import del_icon from './del_icon.png';
import takePhoto from './take-photo.png';

import { IonIcon } from '@ionic/react';
import { isPlatform } from '../../judgmentSystem';
import { CameraUtils } from '@quark-base-plugin/utils/cameraUtils';
import { NativeBridge } from '../../../../../apps/vitalidad_prestamos/src/native';

type FileUploaderValue = {
  fileName: string;
  previewUrl: string;
  attachmentId: string;
  type: number;
};

/**
 * 表单数据类型, 对象数组
 *
 * [{
 *   fileName: string,
 *   previewUrl: string,
 *   attachmentId: string,
 * }]
 *
 */

type FileUploaderProps = {
  uploadUrl?: string;
  value?: FileUploaderValue[];
  /**
   * 在原生下拍照的逻辑实现, base64 string
   */
  takePhotoFn?: () => Promise<string>;
  onChange?: (v?: any) => void;
  title?: string;
  desc?: string;
  uploadText?: string;
  params?: {
    [key: string]: any;
  };
  /**
   * 开始的类型（用来适配多个文件上传，会根据这个递增）
   */
  startType: number;
  /**
   * 限制文件的个数
   */
  limitNumber?: number;
  style?: CSSProperties & Partial<Record<'--width' | '--height' | '--padding', string>>;
};

const defaultParams = {
  ocrCheck: false,
};

const defaultProps = {
  startType: 1,
  limitNumber: 3,
} as FileUploaderProps;

const classPrefix = 'qk-fileUpload';

const defaultUploadUrl = '/member/certificate-info/save-attachment';

export const FileUploader: React.FC<FileUploaderProps> = React.forwardRef(function FileUploader(
  props: FileUploaderProps,
  ref: React.LegacyRef<HTMLDivElement>
) {
  const newProps = mergeProps(defaultProps, props);
  const {
    value,
    uploadUrl = defaultUploadUrl,
    takePhotoFn,
    startType,
    limitNumber,
    params: p,
    title,
    desc,
    uploadText,
    style,
    onChange,
  } = newProps;

  const params = mergeProps(defaultParams, p);
  const { t } = useTranslation();
  /**
   * 剩余可用的类型，每次上传文件传递的类型取这个数组的第一个值（原因是后端如果所有文件都传一个type的话会覆盖）
   * 实现逻辑：1. 根据最大限制文件个数来生成可用类型数组，如 limit=3 并且startType=1，会生成 [1,2,3]
   *         2. 筛选去掉当前已上传的类型
   */
  const remainTypes = useMemo(() => {
    let types: number[] = [];
    const currentValue = value ? value : [];
    for (let i = 0; i < limitNumber!; i++) {
      types.push(i + startType!);
    }
    // types = types.filter((type) => !currentValue.find((v) => v.type == type));
    return types;
  }, [limitNumber, value]);

  const { runAsync: upload, loading } = useRequest(
    (url: string, blob?: Blob, params?: any, name?: string) => Http.upload(url, blob, params, name),
    {
      manual: true,
    }
  );

  async function getCamera() {
    return await NativeBridge.Plugins.photoPlugin.pickPhoto();
  }
  async function PluginGetDeviceInfo() {
    return await NativeBridge.Plugins.appPlugin.getEquipmentInfo();
  }

  const onClickUpload = useMemoizedFn(async (OldFile?: any) => {
    if (takePhotoFn) {
      // const photoBase64String = await takePhotoFn();
      // if (photoBase64String) handleTakePhoto(photoBase64String);
    } else {
      // const { file, name } = (await selectFile()) || {};
      // const type = remainTypes.length ? remainTypes[0] : 100;
      // const p = { ...params, type: type, fileName: name };
      // const { data } = await upload(uploadUrl, file, p);
      // addFile(data);
      const result = await PluginGetDeviceInfo();
      if (isPlatform() !== 'h5' && result?.data) {
        try {
          const photo = (await getCamera()) as any;
          const file = fileUtils.convertBase64ToFile(photo?.data?.img);
          // const { file, name } = (await selectFile()) || {};
          const type = remainTypes.length ? remainTypes[0] : 100;
          const p = { ...params, type: type, fileName: t('fileName') };
          const { data } = await upload(uploadUrl, file, p);
          if (data) {
            OldFile && removeFile(OldFile, false);
            addFile(data);
          }
        } catch (error) {
          console.log('take photo failed, error: ', error);
        }
      } else {
        const photoBase64String: any = await CameraUtils.takeOriginPhoto('rear');
        const file = fileUtils.convertBase64ToFile(photoBase64String);
        // const { file, name } = (await selectFile()) || {};
        const type = remainTypes.length ? remainTypes[0] : 100;
        const p = { ...params, type: type, fileName: t('fileName') };
        const { data } = await upload(uploadUrl, file, p);
        if (data) {
          OldFile && removeFile(OldFile, false);
          addFile(data);
        }
      }
    }
  });

  const addFile = useMemoizedFn((file?: any) => {
    const newValue = value ? [...value] : [];
    if (file) newValue.push(file);
    onChange?.(newValue);
  });

  const handler = useRef<ActionSheetShowHandler>();

  const removeFile = useMemoizedFn((file: any, isSheet: boolean = false) => {
    if (isSheet) {
      const actions: Action[] = [
        {
          text: t('delete'),
          key: 'delete',
          danger: true,
          onClick: () => {
            handler.current?.close();
            const newValue = value ? [...value] : [];
            _.remove(newValue, (item) => item.type == file.type);
            onChange?.(newValue);
          },
        },
      ];
      handler.current = ActionSheet.show({
        actions,
        cancelText: t('cancel'),
        onClose: () => {},
      });
    } else {
      const newValue = value ? [...value] : [];
      _.remove(newValue, (item) => item.type == file.type);
      onChange?.(newValue);
    }
  });

  return (
    <div className={`${classPrefix}-container`} ref={ref} style={style}>
      {
        <>
          {(value?.length || 0) < limitNumber! && (
            <>
              <div className={`${classPrefix}-container-default`} onClick={() => onClickUpload()}>
                <img src={takePhoto} className="default-img" alt="" />
              </div>
              <div className={`${classPrefix}-title`}>{title}</div>
            </>
          )}
        </>
      }

      {value && (
        <div className={`${classPrefix}-filesWrapper`}>
          {value.map((file, index) => {
            return (
              <div key={index}>
                <div
                  className={`${classPrefix}-container-default`}
                  onClick={() => onClickUpload(file)}
                >
                  <img className="file" src={file.previewUrl} alt="" />
                </div>
                <div className={`${classPrefix}-title`}>{title}</div>
                <span onClick={() => removeFile(file, true)}>
                  <img className={`${classPrefix}-del`} src={del_icon} width={20} alt="" />
                </span>
              </div>
            );
          })}
        </div>
      )}
      {loading && (
        <Mask style={{ background: 'transparent' }}>
          <Spinner />
        </Mask>
      )}
    </div>
  );
});

function selectFile(): Promise<{ file: File | undefined; name: string | undefined } | undefined> {
  return new Promise((resolve, reject) => {
    let isResolved = false;
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*,.xls,.doc,.txt,.pdf,.zip,.gz,.rar';
    fileInput.style.display = 'none';
    document.body.append(fileInput);
    fileInput.addEventListener('change', (event) => {
      if (!event || !event.target) {
        isResolved = true;
        resolve(undefined);
        return;
      }
      const inputFile = fileInput.files?.[0];
      const fileReader = new FileReader();
      fileReader.onload = function () {
        //@ts-ignore
        const file = fileUtils.convertBase64ToFile(fileReader.result);
        resolve({
          name: inputFile?.name,
          file,
        });
        // document.body.removeChild(fileInput);
      };
      // @ts-ignore
      fileReader.readAsDataURL(event.target.files[0]);
    });
    setTimeout(() => {
      if (!isResolved) {
        document.body.removeChild(fileInput);
        reject();
      }
    }, 60000);
    setTimeout(() => {
      fileInput?.click();
    }, 200);
  });
}
