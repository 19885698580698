import classNames from 'classnames';
import React, { FC, ReactElement, ReactNode } from 'react';

import { NativeProps, withNativeProps } from '../../utils/native-props';
import { mergeProps } from '../../utils/with-default-props';
import Button from '../button';


import type { ErrorBlockStatus, ImageRecord } from '.';

const classPrefix = `qk-error-block`;

export type ErrorBlockProps = {
  status?: ErrorBlockStatus;
  title?: ReactNode;
  subtitle?: ReactNode;
  button?: ReactNode;
  image?: string | ReactElement;
  description?: ReactNode;
  fullPage?: boolean;
  children?: React.ReactNode;
} & NativeProps<
  | '--image-height'
  | '--image-height-full-page'
  | '--image-width'
  | '--image-width-full-page'
  | '--pr-margin-top'
>;

const defaultProps = {
  status: 'default',
};

export function createErrorBlock(imageRecord: ImageRecord) {
  const ErrorBlock: FC<ErrorBlockProps> = (p) => {
    const props = mergeProps(defaultProps, p);
    // const { locale } = useConfig();
    // const contentPack = locale.ErrorBlock[props.status];
    const des = 'description' in props ? props.description : undefined;
    const title = 'title' in props ? props.title : undefined;
    const subtitle = 'subtitle' in props ? props.subtitle : undefined;
    const image: ReactNode = props.image ?? imageRecord[props.status];
    const imageNode =
      // eslint-disable-next-line jsx-a11y/img-redundant-alt
      typeof image === 'string' ? <img src={image} alt="error block image" /> : image;

    return withNativeProps(
      props,
      <div
        className={classNames(classPrefix, {
          [`${classPrefix}-full-page`]: props.fullPage,
        })}
      >
        {title && <div className={`${classPrefix}-description-title`}>{title}</div>}
        <div className={`${classPrefix}-image`}>{imageNode}</div>
        {subtitle && <div className={`${classPrefix}-description-subtitle`}>{subtitle}</div>}
        {props.status === 'default' && props.button && props.button}
        <div className={`${classPrefix}-description`}>
          {des && <div className={`${classPrefix}-description-desc`}>{des}</div>}
        </div>
        {props.status !== 'default' && props.button && props.button}
        {(props.status === 'error' || props.status === 'disconnected') &&
          (props.button ? (
            props.button
          ) : (
            <Button
              color="ghost"
              onClick={() => window.location.reload()}
              className={`${classPrefix}-retry`}
            >
              intentar otra vez
            </Button>
          ))}
        {props.children && <div className={`${classPrefix}-content`}>{props.children}</div>}
      </div>
    );
  };
  return ErrorBlock;
}
