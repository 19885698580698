import { ReactElement } from 'react';

import { createErrorBlock } from './create';
import { defaultImage, disconnectedImage, emptyImage } from './images';

const imageRecord: Record<'default' | 'disconnected' | 'empty', ReactElement> = {
  default: defaultImage,
  disconnected: disconnectedImage,
  empty: emptyImage,
};

export const ErrorBlock = createErrorBlock(imageRecord);
