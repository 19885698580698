import { Http, HttpResponse } from '@quark-base-plugin/net';

import { Product, ProductCostTrail, ProductStageGroup } from '../types';
import { ProductQuota } from '../types/quota';

export type CalculateProductOptions = {
  productId?: number;
  loanAmount: number;
  productStage: number;
  productNo?: string;
};

export const getCalculatedProduct = (
  options: CalculateProductOptions
): Promise<HttpResponse<ProductCostTrail>> => {
  return Http.post('product/trial-calculation', options);
};

export const getProductList = (): Promise<HttpResponse<Product[]>> => {
  return Http.post('/product/loanable-product');
};

export const getProductQuota = (productNo: string): Promise<HttpResponse<ProductQuota>> => {
  return Http.post('/member/quota/product-quota', {
    productNo,
  });
};

export const getStageGroupList = (): Promise<HttpResponse<ProductStageGroup[]>> => {
  return Http.post('product/stage');
};

export const getProductListNew = (): Promise<HttpResponse<ProductStageGroup[]>> => {
  return Http.post('/memberproduct/loanableProducts');
};

export const getProductDetail = (params: any): Promise<HttpResponse<any>> => {
  return Http.post('/product/get-product-detail', params);
};
