import { IonSearchbar } from '@ionic/react';
import React from 'react';
import classNames from 'classnames';
import css from './index.module.scss';

import type { JSX } from '@ionic/core/components';

type Props = JSX.IonSearchbar & {
  className?: string;
};
export default React.memo(function Search({ className, ...props }: Props) {
  return <IonSearchbar className={classNames(css.search, className)} {...props} />;
});
