import React from 'react';

export const emptyImage = (
  <svg
    width="300px"
    height="272px"
    viewBox="0 0 300 272"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>编组</title>
    <defs>
      <linearGradient
        x1="49.9994907%"
        y1="0.000935578722%"
        x2="49.9994907%"
        y2="100.000067%"
        id="linearGradient-1"
      >
        <stop stopColor="#D4DBE5" offset="0%"></stop>
        <stop stopColor="#FFFFFF" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="49.9997703%"
        y1="100.000954%"
        x2="49.9997703%"
        y2="0.000699856847%"
        id="linearGradient-2"
      >
        <stop stopColor="#FFFFFF" offset="11.19%"></stop>
        <stop stopColor="#E2E6ED" offset="92.05%"></stop>
      </linearGradient>
      <linearGradient
        x1="49.9973667%"
        y1="100.000329%"
        x2="49.9973667%"
        y2="-0.0167873601%"
        id="linearGradient-3"
      >
        <stop stopColor="#F1F3F6" offset="11.19%"></stop>
        <stop stopColor="#D2D9E3" offset="92.05%"></stop>
      </linearGradient>
      <linearGradient
        x1="50.0023191%"
        y1="100.000928%"
        x2="50.0023191%"
        y2="-0.0160018553%"
        id="linearGradient-4"
      >
        <stop stopColor="#F1F3F6" offset="11.19%"></stop>
        <stop stopColor="#D2D9E3" offset="92.05%"></stop>
      </linearGradient>
      <linearGradient
        x1="99.9971429%"
        y1="49.9955102%"
        x2="-0.0195918367%"
        y2="49.9955102%"
        id="linearGradient-5"
      >
        <stop stopColor="#F1F3F6" offset="11.19%"></stop>
        <stop stopColor="#D2D9E3" offset="92.05%"></stop>
      </linearGradient>
      <linearGradient
        x1="17.1996939%"
        y1="64.1952514%"
        x2="82.8002625%"
        y2="35.8040394%"
        id="linearGradient-6"
      >
        <stop stopColor="#CED7E3" offset="11.19%"></stop>
        <stop stopColor="#E0E4EB" offset="92.05%"></stop>
      </linearGradient>
      <linearGradient
        x1="49.9979576%"
        y1="100.001079%"
        x2="49.9979576%"
        y2="0.00110053998%"
        id="linearGradient-7"
      >
        <stop stopColor="#CAD5E2" offset="11.19%"></stop>
        <stop stopColor="#E6EAEF" offset="92.05%"></stop>
      </linearGradient>
      <linearGradient
        x1="49.9980601%"
        y1="100.000944%"
        x2="49.9980601%"
        y2="-0.00169960972%"
        id="linearGradient-8"
      >
        <stop stopColor="#C2CFDD" offset="11.19%"></stop>
        <stop stopColor="#DAE0E8" offset="92.05%"></stop>
      </linearGradient>
      <linearGradient
        x1="27.3073993%"
        y1="-1.24658231%"
        x2="72.6923081%"
        y2="101.246264%"
        id="linearGradient-9"
      >
        <stop stopColor="#EBEEF2" offset="8.84%"></stop>
        <stop stopColor="#D2D9E3" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="0.00226079321%"
        y1="49.9995323%"
        x2="100.003635%"
        y2="49.9995323%"
        id="linearGradient-10"
      >
        <stop stopColor="#CAD5E2" offset="11.19%"></stop>
        <stop stopColor="#D2D9E3" offset="92.05%"></stop>
      </linearGradient>
      <linearGradient
        x1="0.00226079321%"
        y1="50.0065482%"
        x2="100.003635%"
        y2="50.0065482%"
        id="linearGradient-11"
      >
        <stop stopColor="#CAD5E2" offset="11.19%"></stop>
        <stop stopColor="#D2D9E3" offset="92.05%"></stop>
      </linearGradient>
      <linearGradient
        x1="-0.00644859813%"
        y1="50.0130964%"
        x2="99.9972897%"
        y2="50.0130964%"
        id="linearGradient-12"
      >
        <stop stopColor="#CAD5E2" offset="11.19%"></stop>
        <stop stopColor="#D2D9E3" offset="92.05%"></stop>
      </linearGradient>
      <linearGradient
        x1="-0.00966928251%"
        y1="49.9728718%"
        x2="99.9984585%"
        y2="49.9728718%"
        id="linearGradient-13"
      >
        <stop stopColor="#CAD5E2" offset="11.19%"></stop>
        <stop stopColor="#D2D9E3" offset="92.05%"></stop>
      </linearGradient>
      <linearGradient
        x1="49.9966505%"
        y1="99.9982054%"
        x2="49.9966505%"
        y2="0.00179458863%"
        id="linearGradient-14"
      >
        <stop stopColor="#C2CFDD" offset="11.19%"></stop>
        <stop stopColor="#DAE0E8" offset="92.05%"></stop>
      </linearGradient>
      <linearGradient
        x1="24.9953385%"
        y1="6.77642617%"
        x2="75.0043746%"
        y2="93.2248622%"
        id="linearGradient-15"
      >
        <stop stopColor="#EBEEF2" offset="8.84%"></stop>
        <stop stopColor="#D2D9E3" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="-0.0121983559%"
        y1="49.9977988%"
        x2="99.9866083%"
        y2="49.9977988%"
        id="linearGradient-16"
      >
        <stop stopColor="#CAD5E2" offset="11.19%"></stop>
        <stop stopColor="#D2D9E3" offset="92.05%"></stop>
      </linearGradient>
    </defs>
    <g id="英文" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="06-记录-无还款记录" transform="translate(-210.000000, -264.000000)" fillRule="nonzero">
        <g id="编组" transform="translate(210.000000, 264.000000)">
          <g>
            <ellipse
              id="椭圆形"
              fill="url(#linearGradient-1)"
              cx="150"
              cy="233.95854"
              rx="150"
              ry="38.0414603"
            ></ellipse>
            <polygon
              id="路径"
              fill="url(#linearGradient-2)"
              points="236.219189 69.3969381 236.219189 8.75533207 224.027297 0 170.77307 21.1409238 170.77307 83.063798 158.581177 83.063798 158.581177 65.9802232 135.271949 65.9802232 135.271949 81.9960745 124.149521 81.9960745 124.149521 52.0998187 104.043593 52.0998187 104.043593 105.053816 98.4823793 105.053816 98.4823793 11.7449577 51.0032593 11.7449577 51.0032593 97.3662075 26.83846 97.3662075 26.83846 129.082678 12.9354247 129.082678 12.9354247 159.828028 278.992667 159.828028 278.992667 157.367179 278.992667 129.082678 278.992667 69.3969381"
            ></polygon>
            <ellipse
              id="椭圆形"
              fill="url(#linearGradient-3)"
              cx="21.8374414"
              cy="113.946427"
              rx="7.7357914"
              ry="7.72319943"
            ></ellipse>
            <ellipse
              id="椭圆形"
              fill="url(#linearGradient-4)"
              cx="251.828275"
              cy="66.7276296"
              rx="10.979833"
              ry="10.9619605"
            ></ellipse>
            <ellipse
              id="椭圆形"
              fill="url(#linearGradient-5)"
              cx="240.848442"
              cy="222.711853"
              rx="6.23854145"
              ry="6.22838664"
            ></ellipse>
          </g>
          <g transform="translate(56.187291, 57.000000)">
            <path
              d="M133.633536,159.563759 L44.5859486,176.242541 C34.8072156,178.074256 25.390092,171.637814 23.5565796,161.868667 L0.31171644,37.9989455 C-1.52179599,28.2297992 4.92096297,18.8219078 14.6996959,16.9901929 L103.747283,0.311410846 C113.526016,-1.52030408 122.94314,4.91613865 124.776652,14.6852849 L148.021515,138.555007 C149.855028,148.329241 143.412269,157.732044 133.633536,159.563759 Z"
              id="路径"
              fill="url(#linearGradient-6)"
            ></path>
            <path
              d="M138.777557,161.161422 L46.3379716,178.476216 C38.5811954,179.926324 31.1198184,174.82805 29.6631946,167.078879 L5.40989962,37.8361263 C3.95836894,30.0869546 9.06164521,22.6328925 16.8184214,21.1776967 L109.258006,3.86290256 C117.014783,2.41279491 124.47616,7.51106812 125.932783,15.2602399 L150.186078,144.502992 C151.637609,152.252164 146.52924,159.711314 138.777557,161.161422 Z"
              id="路径"
              fill="url(#linearGradient-7)"
            ></path>
            <path
              d="M141.507453,182.302465 L50.0813928,182.302465 C40.5267558,182.302465 32.7852588,174.568557 32.7852588,165.023287 L32.7852588,37.922624 C32.7852588,28.377354 40.5267558,20.6434465 50.0813928,20.6434465 L141.512546,20.6434465 C151.067183,20.6434465 158.80868,28.377354 158.80868,37.922624 L158.80868,165.028376 C158.803587,174.568557 151.06209,182.302465 141.507453,182.302465 Z"
              id="路径"
              fill="url(#linearGradient-8)"
            ></path>
            <path
              d="M146.137072,184.515787 L52.0829772,184.515787 C44.1937806,184.515787 37.7968595,178.125137 37.7968595,170.243675 L37.7968595,38.7468957 C37.7968595,30.8654334 44.1937806,24.4747836 52.0829772,24.4747836 L146.137072,24.4747836 C154.026268,24.4747836 160.423189,30.8654334 160.423189,38.7468957 L160.423189,170.243675 C160.423189,178.125137 154.026268,184.515787 146.137072,184.515787 Z"
              id="路径"
              fill="url(#linearGradient-9)"
            ></path>
            <path
              d="M127.465804,67.8508107 L68.5183789,67.8508107 C65.5134557,67.8508107 63.0738656,65.4136122 63.0738656,62.4116349 L63.0738656,62.4116349 C63.0738656,59.4096577 65.5134557,56.9724592 68.5183789,56.9724592 L127.465804,56.9724592 C130.470727,56.9724592 132.910325,59.4096577 132.910325,62.4116349 L132.910325,62.4116349 C132.91541,65.4136122 130.47582,67.8508107 127.465804,67.8508107 Z"
              id="路径"
              fill="url(#linearGradient-10)"
            ></path>
            <path
              d="M127.465804,95.6572609 L68.5183789,95.6572609 C65.5134557,95.6572609 63.0738656,93.2200624 63.0738656,90.2180851 L63.0738656,90.2180851 C63.0738656,87.2161079 65.5134557,84.7789094 68.5183789,84.7789094 L127.465804,84.7789094 C130.470727,84.7789094 132.910325,87.2161079 132.910325,90.2180851 L132.910325,90.2180851 C132.91541,93.2251505 130.47582,95.6572609 127.465804,95.6572609 Z"
              id="路径"
              fill="url(#linearGradient-11)"
            ></path>
            <path
              d="M112.130509,123.463711 L68.523472,123.463711 C65.5185488,123.463711 63.0789587,121.026513 63.0789587,118.024535 L63.0789587,118.024535 C63.0789587,115.022558 65.5185488,112.58536 68.523472,112.58536 L112.130509,112.58536 C115.135432,112.58536 117.575023,115.022558 117.575023,118.024535 L117.575023,118.024535 C117.575023,121.031601 115.135432,123.463711 112.130509,123.463711 Z"
              id="路径"
              fill="url(#linearGradient-12)"
            ></path>
            <path
              d="M93.9787362,151.275249 L68.523472,151.275249 C65.5185488,151.275249 63.0789587,148.838051 63.0789587,145.836074 L63.0789587,145.836074 C63.0789587,142.834096 65.5185488,140.396898 68.523472,140.396898 L93.9787362,140.396898 C96.9836594,140.396898 99.4232495,142.834096 99.4232495,145.836074 L99.4232495,145.836074 C99.4232495,148.838051 96.9836594,151.275249 93.9787362,151.275249 Z"
              id="路径"
              fill="url(#linearGradient-13)"
            ></path>
            <ellipse
              id="椭圆形"
              fill="url(#linearGradient-14)"
              cx="143.111776"
              cy="157.034975"
              rx="35.73312"
              ry="36.8581748"
            ></ellipse>
            <ellipse
              id="椭圆形"
              fill="url(#linearGradient-15)"
              cx="146.096327"
              cy="158.525788"
              rx="35.5090241"
              ry="35.4742124"
            ></ellipse>
            <path
              d="M161.003802,166.75324 L149.131809,166.75324 L149.131809,161.960253 L161.003802,161.960253 C163.030852,161.960253 164.670827,160.474529 164.670827,158.637726 C164.670827,156.800923 163.030852,155.315198 161.003802,155.315198 L149.763352,155.315198 L162.277074,142.742511 C163.631836,141.378901 163.514695,139.277517 162.007141,138.051285 C160.504679,136.825054 158.18223,136.931904 156.827468,138.295514 L145.388388,149.789525 L134.361848,138.687297 C133.007086,137.323687 130.68973,137.211749 129.182175,138.437981 C127.67462,139.664212 127.552386,141.765596 128.907148,143.129206 L141.00833,155.315198 L129.930859,155.315198 C127.903809,155.315198 126.263834,156.800923 126.263834,158.637726 C126.263834,160.474529 127.903809,161.960253 129.930859,161.960253 L141.802852,161.960253 L141.802852,166.75324 L129.930859,166.75324 C127.903809,166.75324 126.263834,168.238965 126.263834,170.075768 C126.263834,171.912571 127.903809,173.398295 129.930859,173.398295 L141.802852,173.398295 L141.802852,178.796766 C141.802852,180.633569 143.442827,182.119293 145.469877,182.119293 C147.496927,182.119293 149.136902,180.633569 149.136902,178.796766 L149.136902,173.398295 L161.008895,173.398295 C163.035945,173.398295 164.67592,171.912571 164.67592,170.075768 C164.67592,168.238965 163.030852,166.75324 161.003802,166.75324 Z"
              id="路径"
              fill="url(#linearGradient-16)"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
