import fileUtils from "./fileUtils";
import { isPlatform } from '@ionic/react';

class CameraUtilsCls {
  public async takeOriginPhoto(position: 'front' | 'rear' | 'all'): Promise<string | undefined> {
    return new Promise((resolve, reject) => {
      let isResolved = false;
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      if (position !== 'all') {
        //@ts-ignore
        fileInput.capture = position === 'front' ? 'user' : 'camera';
      }
      fileInput.accept = 'image/*';
      fileInput.style.display = 'none';
      document.body.append(fileInput);
      fileInput.addEventListener('change', (event) => {
        if (!event || !event.target) {
          isResolved = true;
          resolve(undefined);
          return;
        }
        const fileReader = new FileReader();
        fileReader.onload = function () {
          const isHybrid = isPlatform('hybrid');
          if (isHybrid) {
            fileUtils.compressImage(fileReader.result + '').then((base64Data) => {
              // const base64 = fileUtils.convertBase64ToFile(base64Data);
              isResolved = true;
              resolve(base64Data);
              // document?.body?.removeChild(fileInput);
            });
          } else {
            isResolved = true;
            resolve(fileReader.result + '');
            // document?.body?.removeChild(fileInput);
          }
        };
        
        // @ts-ignore
        fileReader.readAsDataURL(event.target.files[0]);
      });
      setTimeout(() => {
        if (!isResolved) {
          reject();
        }
      }, 60000);
      setTimeout(() => {
        fileInput?.click();
      }, 200);
    });
  }
}

const CameraUtils = new CameraUtilsCls();
export { CameraUtils };