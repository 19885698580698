export default {
    idCardPhoto: 'Foto de DNI：',
    uploadTips:'(Por favor cargue una foto del frente y reverso de su DNI)',
    idCardUp:'Clic para tomar foto del DNI (frente)',
    idCardDown:'Clic para tomar foto del DNI (reverso)',
    name:'Nombre',
    idCardNo:'CUI',
    gender:'Sexo',
    birthday:'Fecha de Nacimiento',
    issueDate:'Fecha de Emisión :',
    expiryDate:'Fecha de Caducidad :',
    basicTitle:'Información básica',
    basicEmail:'Correo electrónico',
    basicMobile:'Número de celular',
    basicEducation:'Nivel educativo',
    basicMaritalStatus:'Estado civil',
    basicFacebookHomePage: 'Enlace personal de Facebook',
    haveInfoNotConfirmed: 'Haga clic en "conf." para confirmar la exactitud de la información completada',
    next:'Siguiente',
    start:'Inicio',
    inProgress:'En proceso',
    end:'Hecho',
    step2:'Paso2',
    step3:'Paso3',
    workTitle:'Información laboral',
    jobType: 'Tipo de trabajo',
    companyName:'Nombre de la empresa(opcional)',
    companyPhone:'Teléfono de la empresa(opcional)',
    companyAddress:'Dirección de la empresa(opcional)',
    companyDetails:'Dirección detallada(opcional)',
    monthlyIncome:'Ingreso mensual',
    familyAdress:'Dirección de residencia(opcional)',
    familyDetails:'Dirección detallada(opcional)',
    contactTitle:'Contacto de emergencia',
    contact1:'contact1',
    contact2:'contact2',
    relation:'Por favor seleccione ',
    contactPhone:'Número de celular',
    contactName:'Nombre',
    uploadBankCardFront: 'Sube la foto frontal de la tarjeta bancaria',
    bankTitle:'Información bancaria',
    bankName:'Nombre del banco',
    withdrawalName:'Titular de la cuenta',
    bankNumber:'Número de cuenta receptora',
    transferAccount:'Número de cuenta de tránsito',
    faceVerifyTitle:'Reconocimiento facial',
    faceVerifyPrepare:'Por favor alinee su rostro frente al teléfono',
    faceVerifyTips1:'No gafas',
    faceVerifyTips2:'No sombreros',
    faceVerifyTips3:'No en sombra',
    takePhotoTips1:'Foto clara',
    takePhotoTips2:'No borrosa',
    takePhotoTips3:'Sin bloqueos',
    monthlyIncomeCheck:'Introduzca un número del 1 al 99999999',
    facebookEnter:'Por favor introduzca'
  };
  