export default {
  extension1: 'Details',
  extension2: 'Payment amount',
  extension3: 'Extension Fee',
  extension4: 'Interest for late payment',
  extension5: 'Interest',
  extension6: 'Current billing date',
  extension7: 'After the extended billing day',
  extension8: 'Number of days of extension',
  extension9: 'Pay the fee',
  extension10: 'Cancel exhibition',
  extension11: 'Deferred payment advantage',
  extension12: "If you're under financial pressure, try deferred payment.",
  extension13: 'Low payout rate',
  extension14: 'No additional interest is charged',
  extension15: 'Excludes penalty interest',
  extension16: 'La extensión se realiza correctamente y seposponen todos los ciclos de facturación.',
  extension17: 'Request deferred payment',
  extension18: 'Request an extension',
  extension19: 'Deferred amortization fee',
  extension20: 'Number of successful deferred payments',
  extension21: 'times',
  extension22: 'After the extension is successful, the payment plan:',
  extension23: 'Payment time',
  extension24: 'Total amount owed',
  extension25: 'Confirm Extension and Pay',
  extension26: 'Do you have any questions?',
  extension27: 'Seeking Help',
  extension28: 'This extension request will expire soon. Please pay the extension fee as soon as possible.',
  extension29: 'Late Billing Charges:',
  extension30: 'Extension fee',
  extension31: 'Pay the extension fee',
  extension32: 'Cash payment error',
  extension33: 'Sorry, we are unable to issue your cash payment because the payment information you entered is incorrect. Please resubmit your loan application.',
  extension34: 'Special reminder: Please confirm that the bank card information is correct',
  extension35: 'Current Repaid',
  extension36: 'Remaining Due this Term',
  extension37: 'Term/Total Terms',
  extension38: 'Remaining Total Due',
  extension39: "If you're under financial pressure, try deferred payment.",
  extension40: 'Loan details >',
};
