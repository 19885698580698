import clsx from 'clsx';

import { NativeProps } from '../../utils/native-props';
import { Popup } from '../popup/popup';
import './Modal.scss';
export type IModalProps = {
  visible?: boolean;
  ref?: any;
  children?: any;
  className?: string;
  maskClassName?: string;
  /**
   * 显示关闭按钮, 默认false
   */
  showCloseButton?: boolean;
  onClose?: () => void;
  align?: 'center' | 'bottom';
  /**
   * 是否支持点击遮罩关闭弹窗, 默认 false
   */
  closeOnMaskClick?: boolean;
  id?: string;
  afterClose?: () => void;
} & NativeProps<'--padding-top' | '--padding-bottom' | '--padding-left' | '--padding-right'>;

export const IModal = (props: IModalProps) => {
  const {
    visible,
    // ref,
    align = 'center',
    // fullPage = false,
    children,
    className,
    maskClassName,
    showCloseButton = true,
    onClose,
    afterClose,
    closeOnMaskClick = false,
    style,
  } = props;

  return (
    <Popup
      position={align}
      visible={visible}
      showCloseButton={showCloseButton}
      bodyClassName={clsx(`qk-modal-${align}`, 'qk-modal', className)}
      maskClassName={maskClassName}
      closeOnMaskClick={closeOnMaskClick}
      onClose={onClose}
      onMaskClick={() => {
        // setVisible(false);
      }}
      afterClose={afterClose}
      style={style}
    >
      {children}
    </Popup>
  );
};
