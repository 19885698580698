import { useSpring, animated } from '@react-spring/web';
import { useIsomorphicLayoutEffect, useUnmountedRef } from 'ahooks';
import classNames from 'clsx';
import { useState, useRef, FC, PropsWithChildren } from 'react';

import { NativeProps, withNativeProps } from '../../utils/native-props';
import { renderToContainer } from '../../utils/render-to-container';
import { ShouldRender } from '../../utils/should-render';
import { useInnerVisible } from '../../utils/use-inner-visible';
import { useLockScroll } from '../../utils/use-lock-scroll';
import { mergeProps } from '../../utils/with-default-props';
import { withStopPropagation } from '../../utils/with-stop-propagation';

import { Mask } from './mask';
import { defaultPopupBaseProps, PopupBaseProps } from './popup-base-props';

import './popup.scss';
const classPrefix = `qk-popup`;

export type PopupProps = PopupBaseProps &
  PropsWithChildren<{
    position?: 'bottom' | 'top' | 'left' | 'right' | 'center';
  }> &
  NativeProps<'--z-index'>;

const defaultProps = {
  ...defaultPopupBaseProps,
  position: 'bottom',
};

export const Popup: FC<PopupProps> = (p) => {
  const props = mergeProps(defaultProps, p);

  const bodyCls = classNames(
    `${classPrefix}-body`,
    props.bodyClassName,
    `${classPrefix}-body-position-${props.position}`
  );

  const [active, setActive] = useState(props.visible);
  useIsomorphicLayoutEffect(() => {
    if (props.visible) {
      setActive(true);
    }
  }, [props.visible]);

  const ref = useRef<HTMLDivElement>(null);
  useLockScroll(ref, props.disableBodyScroll && active);

  const unmountedRef = useUnmountedRef();
  const { percent } = useSpring({
    percent: props.visible ? 0 : 100,
    config: {
      precision: 0.1,
      mass: 0.4,
      tension: 300,
      friction: 30,
    },
    onRest: () => {
      if (unmountedRef.current) return;
      setActive(props.visible);
      if (props.visible) {
        props.afterShow?.();
      } else {
        props.afterClose?.();
      }
    },
  });
  let style: any = {
    ...props.bodyStyle,
    transform: percent.to((v) => {
      if (props.position === 'bottom') {
        return `translate(0, ${v}%)`;
      }
      if (props.position === 'top') {
        return `translate(0, -${v}%)`;
      }
      if (props.position === 'left') {
        return `translate(-${v}%, 0)`;
      }
      if (props.position === 'right') {
        return `translate(${v}%, 0)`;
      }
      return 'none';
    }),
  };
  if (props.position === 'center') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    style = useSpring({
      scale: props.visible ? 1 : 0.8,
      opacity: props.visible ? 1 : 0,
      config: {
        mass: 1.2,
        tension: 200,
        friction: 25,
        clamp: true,
      },
      onRest: () => {
        if (unmountedRef.current) return;
        setActive(props.visible);
        if (props.visible) {
          props.afterShow?.();
        } else {
          props.afterClose?.();
        }
      },
    });
  }
  const maskVisible = useInnerVisible(active && props.visible);

  const node = withStopPropagation(
    props.stopPropagation,
    withNativeProps(
      props,
      <div
        className={classNames(classPrefix, {
          [`${classPrefix}-center`]: props.position === 'center',
        })}
        onClick={props.onClick}
        style={{ display: active ? undefined : 'none' }}
      >
        {props.mask && (
          <Mask
            visible={maskVisible}
            forceRender={props.forceRender}
            destroyOnClose={props.destroyOnClose}
            onMaskClick={(e) => {
              props.onMaskClick?.(e);
              if (props.closeOnMaskClick) {
                props.onClose?.();
              }
            }}
            className={props.maskClassName}
            style={props.maskStyle}
            disableBodyScroll={false}
            stopPropagation={props.stopPropagation}
          />
        )}
        <animated.div className={bodyCls} style={style} ref={ref}>
          {props.showCloseButton && (
            <a
              className={classNames(`${classPrefix}-close-icon`, 'adm-plain-anchor')}
              onClick={() => {
                props.onClose?.();
              }}
            >
              <CloseFill />
            </a>
          )}
          {props.children}
        </animated.div>
      </div>
    )
  );

  return (
    <ShouldRender
      active={active}
      forceRender={props.forceRender}
      destroyOnClose={props.destroyOnClose}
    >
      {renderToContainer(props.getContainer, node)}
    </ShouldRender>
  );
};

const CloseFill = ({ color }: { color?: string }) => {
  return (
    <svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>close</title>
      <g id="英文" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="06-分期详情-应还金额弹窗"
          transform="translate(-640.000000, -563.000000)"
          fillRule="nonzero"
        >
          <g id="编组-52" transform="translate(0.000000, 523.000000)">
            <g id="关闭/黑色" transform="translate(640.000000, 40.000000)">
              <rect
                id="矩形"
                fill={'#000000'}
                opacity="0"
                x="0"
                y="0"
                width="40"
                height="40"
              ></rect>
              <g
                id="close-outline"
                transform="translate(6.000000, 6.000000)"
                fill={color || '#121934'}
              >
                <path
                  d="M17.2868237,13.9980105 L27.3104703,3.99625357 C28.2245387,3.08205528 28.2245387,1.599847 27.3104703,0.685648715 C26.3964019,-0.228549572 24.9144042,-0.228549572 24.0003358,0.685648715 L14,10.7107198 L3.9996642,0.685648715 C3.0855958,-0.228549549 1.60359814,-0.228549538 0.689529762,0.68564874 C-0.22453862,1.59984702 -0.224538631,3.08205528 0.689529737,3.99625357 L10.7131763,13.9980105 L0.689529737,23.9997674 C0.248226688,24.4375287 0,25.0334279 0,25.6550698 C0,26.2767118 0.248226688,26.872611 0.689529737,27.3103723 C1.12722882,27.751738 1.72304338,28 2.34459697,28 C2.96615056,28 3.56196512,27.751738 3.9996642,27.3103723 L14,17.2853012 L24.0003358,27.3103723 C24.4380349,27.751738 25.0338494,28 25.655403,28 C26.2769566,28 26.8727712,27.751738 27.3104703,27.3103723 C27.7517733,26.872611 28,26.2767118 28,25.6550698 C28,25.0334279 27.7517733,24.4375287 27.3104703,23.9997674 L17.2868237,13.9980105 Z"
                  id="路径"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
