import { AppPlugin } from './plugins/app';
import { CameraPlugin } from './plugins/camera';
import { ContactPlugin } from './plugins/contact';
import { EventPlugin } from './plugins/event';
import { FacePlugin } from './plugins/face';
import { LocationPlugin } from './plugins/location';
import { PhotoPlugin } from './plugins/photo';
import { SmsPlugin } from './plugins/sms';
import { CallRecordsPlugin } from './plugins/callRecords';
import { AppLocalStoragePlugin } from './plugins/storage';
import NativeCall from './call';

export type NativeBridgePluginsType = {
  storagePlugin: AppLocalStoragePlugin;
  appPlugin: AppPlugin;
  contactPlugin: ContactPlugin;
  locationPlugin: LocationPlugin;
  cameraPlugin: CameraPlugin;
  photoPlugin: PhotoPlugin;
  eventPlugin: EventPlugin;
  facePlugin: FacePlugin;
  smsPlugin: SmsPlugin;
  callRecordsPlugin: CallRecordsPlugin;
};

const plugins: NativeBridgePluginsType = {
  storagePlugin: new AppLocalStoragePlugin(),
  appPlugin: new AppPlugin(),
  contactPlugin: new ContactPlugin(),
  locationPlugin: new LocationPlugin(),
  cameraPlugin: new CameraPlugin(),
  photoPlugin: new PhotoPlugin(),
  eventPlugin: new EventPlugin(),
  facePlugin: new FacePlugin(),
  smsPlugin: new SmsPlugin(),
  callRecordsPlugin: new CallRecordsPlugin()
}

const NativeBridge = {
  Plugins: plugins,
  isNative: NativeCall.isNative,
  getPlatform: NativeCall.getPlatform
};

export { NativeBridge };
