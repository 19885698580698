import clsx from 'clsx';
import React, { CSSProperties, FC } from 'react';

import { StepProps } from './step';

const classPrefix = `qk-steps`;
const stepClassPrefix = `qk-step`;

const defaultIcon = <span className={`${stepClassPrefix}-icon-dot`} />;

type Direction = 'horizontal' | 'vertical';

export type StepsStyle = CSSProperties &
  Partial<
    Record<
      | '--title-font-size'
      | '--description-font-size'
      | '--indicator-margin-right'
      | '--icon-size'
      | '--pre-title-width', // 左边的元素宽度, 默认 30%
      string
    >
  >;

export type StepsProps = {
  current?: number;
  direction?: Direction;
  children?: React.ReactNode;
  className?: string;
  style?: StepsStyle;
  status?: string;
};

export const Steps: FC<StepsProps> = (props) => {
  const { direction = 'horizontal', current = 0, className, style } = props;
  const classString = clsx(classPrefix, `${classPrefix}-${direction}`, className);

  return (
    <div className={classString} style={style}>
      {React.Children.map(props.children, (child, index) => {
        if (!React.isValidElement(child)) {
          return child;
        }
        const props = child.props as StepProps;
        let status = props.status || 'wait';

        if (index < current) {
          status = props.status || 'finish';
        } else if (index === current) {
          status = props.status || 'process';
        }

        const icon = props.icon ?? defaultIcon;

        return React.cloneElement(child, {
          //@ts-ignore
          status,
          icon,
        });
      })}
    </div>
  );
};
