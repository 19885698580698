import { NativeBridge } from '../../../apps/vitalidad_prestamos/src/native';

/**
 * 判断当前系统
 * @param system
 */
export function isPlatform() {
  if (NativeBridge.getPlatform() !== 'h5') {
    return NativeBridge.getPlatform();
  }
  const u = navigator.userAgent;
  const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
  const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  let result = '';
  if (isAndroid) {
    result = 'android';
  } else if (isIOS) {
    result = 'ios';
  } else {
    result = 'h5';
  }
  return result;
}
