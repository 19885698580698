import { Http, HttpResponse } from '@quark-base-plugin/net';
import { useRequest, useMemoizedFn } from 'ahooks';

//0，未填写；1，已填写可修改；2，已填写不可修改

export type Status = 0 | 1 | 2;
export type ApplyStepStatusInfo = {
  ktpFlag: Status;
  basisFlag: Status;
  companyFlag: Status;
  contactFlag: Status;
  bankFlag: Status;
};

export type ApplyStepItem = {
  key: 'basisFlag' | 'companyFlag' | 'contactFlag' | 'bankFlag' | 'ktpFlag';
  url: string; //申请步骤路由
  title: string;
};

export type UseGetApplyStepProps = {
  applyStepRouters: ApplyStepItem[];
  ready: boolean;
};

//查询提交资料步骤信息
export const getApplyStep = (): Promise<HttpResponse<ApplyStepStatusInfo>> => {
  return Http.post('/member/basis-info/v8/load-submit-info-steps');
};

export function useGetApplyStep({ applyStepRouters, ready }: UseGetApplyStepProps) {
  const { data, ...others } = useRequest(getApplyStep, {
    ready,
    retryCount: 3,
    cacheKey: 'product-load-submit-info-steps',
    staleTime: 10000,
    manual: true,
  });

  //获取未填写的Url
  const getApplyStepUrlUnFinished = useMemoizedFn(() => {
    if (!data?.data) {
      return applyStepRouters[0].url;
    }
    let url: string | undefined = undefined;
    for (let i = 0; i < applyStepRouters.length; i++) {
      const item = applyStepRouters[i];
      // eslint-disable-next-line no-prototype-builtins
      if (data.data.hasOwnProperty(item.key) && data.data[item.key] === 0) {
        url = item.url;
        break;
      }
    }
    return url;
  });

  return {
    ...others,
    data,
    getApplyStepUrlUnFinished,
  };
}
