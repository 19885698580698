// import { DeviceInfo } from '@quark-base-plugin/device';

export enum AppDevicePlatform {
  ANDROID = '1',
  IOS = '2',
  H5 = '3',
  H5Android = '4',
  H5IOS = '5',
}

export type AppBasicInfo = {
  appInfoId: number;
  merchantId: string;
  appVersion?: string;
};

// export type AppDeviceInfo = {
//   platform: AppDevicePlatform; //
//   google_referrer: string;
//   af_referrer: string;
//   IOS_Referrer: string;
//   langType: string;
//   [key: string]: any;
// } & DeviceInfo;

export type AppDeviceInfo = {
  platform: AppDevicePlatform; //
  google_referrer: string;
  af_referrer: string;
  IOS_Referrer: string;
  langType: string;
  [key: string]: any;
};
