import clsx from 'clsx';
import React, { FC } from 'react';

const classPrefix = `qk-step`;

export type StepProps = {
  title?: React.ReactNode;
  description?: React.ReactNode;
  icon?: React.ReactNode;
  status?: 'wait' | 'process' | 'finish' | 'error';
  className?: string;

  preTitle?: React.ReactNode;
  preDescription?: React.ReactNode;
};

export const Step: FC<StepProps> = (props) => {
  const { title, description, preTitle, preDescription, icon, status = 'wait' } = props;

  return (
    <div className={clsx(`${classPrefix}`, `${classPrefix}-status-${status}`)}>
      {(preTitle || preDescription) && (
        <div
          className={`${classPrefix}-content`}
          style={{
            textAlign: 'end',
            minWidth: 'var(--pre-title-width, 30%)',
            maxWidth: 'var(--pre-title-width, 30%)',
          }}
        >
          <div className={`${classPrefix}-title`}>{preTitle}</div>
          {!!preDescription && <div className={`${classPrefix}-description`}>{preDescription}</div>}
        </div>
      )}
      {/* <div className={`${classPrefix}-indicator`}>
        <div className={`${classPrefix}-icon-container`}>{icon}</div>
      </div> */}
      <div
        className={`${classPrefix}-content`}
        // style={{ textAlign: 'end', width: '50%', maxWidth: '50%' }}
      >
        <div className={`${classPrefix}-title`}>{title}</div>
        {!!description && <div className={`${classPrefix}-description`}>{description}</div>}
      </div>
    </div>
  );
};
