import React from 'react';

import { renderImperatively } from '../../utils/render-imperatively';

import { IModal, IModalProps } from './Modal';

export type ModalShowProps = Omit<IModalProps, 'visible' | 'destroyOnClose' | 'forceRender'>;

export type ModalShowHandler = {
  close: () => void;
};

export const closeFnSet = new Set<() => void>();

export function show(props: ModalShowProps) {
  const handler: ModalShowHandler = renderImperatively(
    <IModal
      {...props}
      afterClose={() => {
        closeFnSet.delete(handler.close);
        props.afterClose?.();
      }}
    />
  );
  closeFnSet.add(handler.close);
  return handler;
}
