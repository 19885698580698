import React from 'react';

export const disconnectedImage = (
  <svg
    width="300px"
    height="272px"
    viewBox="0 0 300 272"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>编组</title>
    <defs>
      <linearGradient
        x1="49.9994568%"
        y1="0.00280673617%"
        x2="49.9994568%"
        y2="100.002005%"
        id="linearGradient-1"
      >
        <stop stopColor="#D4DBE5" offset="0%"></stop>
        <stop stopColor="#FFFFFF" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="49.999732%"
        y1="99.9986639%"
        x2="49.999732%"
        y2="-0.00155877207%"
        id="linearGradient-2"
      >
        <stop stopColor="#FFFFFF" offset="11.19%"></stop>
        <stop stopColor="#E2E6ED" offset="92.05%"></stop>
      </linearGradient>
      <linearGradient
        x1="49.9967084%"
        y1="100.009546%"
        x2="49.9967084%"
        y2="-0.00724160632%"
        id="linearGradient-3"
      >
        <stop stopColor="#F1F3F6" offset="11.19%"></stop>
        <stop stopColor="#D2D9E3" offset="92.05%"></stop>
      </linearGradient>
      <linearGradient
        x1="50.0018553%"
        y1="100.007421%"
        x2="50.0018553%"
        y2="-0.00950834879%"
        id="linearGradient-4"
      >
        <stop stopColor="#F1F3F6" offset="11.19%"></stop>
        <stop stopColor="#D2D9E3" offset="92.05%"></stop>
      </linearGradient>
      <linearGradient
        x1="99.9955102%"
        y1="50.0061224%"
        x2="-0.0204081633%"
        y2="50.0061224%"
        id="linearGradient-5"
      >
        <stop stopColor="#F1F3F6" offset="11.19%"></stop>
        <stop stopColor="#D2D9E3" offset="92.05%"></stop>
      </linearGradient>
      <linearGradient
        x1="50.0017863%"
        y1="99.9986628%"
        x2="50.0017863%"
        y2="-0.00139660654%"
        id="linearGradient-6"
      >
        <stop stopColor="#CED7E3" offset="11.19%"></stop>
        <stop stopColor="#E0E4EB" offset="92.05%"></stop>
      </linearGradient>
      <linearGradient
        x1="28.1053741%"
        y1="-0.858793008%"
        x2="71.8954089%"
        y2="100.856291%"
        id="linearGradient-7"
      >
        <stop stopColor="#EBEEF2" offset="8.84%"></stop>
        <stop stopColor="#D2D9E3" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="-0.00045861041%"
        y1="50.0026781%"
        x2="99.9951846%"
        y2="50.0026781%"
        id="linearGradient-8"
      >
        <stop stopColor="#CAD5E2" offset="11.19%"></stop>
        <stop stopColor="#D2D9E3" offset="92.05%"></stop>
      </linearGradient>
    </defs>
    <g id="英文" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="06-缺省-网络异常" transform="translate(-210.000000, -264.000000)" fillRule="nonzero">
        <g id="编组" transform="translate(210.000000, 264.000000)">
          <g>
            <ellipse
              id="椭圆形"
              fill="url(#linearGradient-1)"
              cx="150"
              cy="233.957829"
              rx="150"
              ry="38.0421714"
            ></ellipse>
            <polygon
              id="路径"
              fill="url(#linearGradient-2)"
              points="236.219189 69.3931509 236.219189 8.75041125 224.027297 0 170.77307 21.1362345 170.77307 83.0602662 158.581177 83.0602662 158.581177 65.9763721 135.271949 65.9763721 135.271949 81.9925228 124.149521 81.9925228 124.149521 52.0957081 104.043593 52.0957081 104.043593 105.050695 98.4823793 105.050695 98.4823793 11.7400927 51.0032593 11.7400927 51.0032593 97.3680275 26.83846 97.3680275 26.83846 129.080006 12.9354247 129.080006 12.9354247 159.831015 278.992667 159.831015 278.992667 157.365037 278.992667 129.080006 278.992667 69.3931509"
            ></polygon>
            <ellipse
              id="椭圆形"
              fill="url(#linearGradient-3)"
              cx="21.8374414"
              cy="113.943472"
              rx="7.7357914"
              ry="7.7233438"
            ></ellipse>
            <ellipse
              id="椭圆形"
              fill="url(#linearGradient-4)"
              cx="251.828275"
              cy="66.7237924"
              rx="10.979833"
              ry="10.9621654"
            ></ellipse>
            <ellipse
              id="椭圆形"
              fill="url(#linearGradient-5)"
              cx="240.848442"
              cy="222.710932"
              rx="6.23854145"
              ry="6.22850307"
            ></ellipse>
          </g>
          <g transform="translate(83.000000, 63.000000)">
            <path
              d="M113.349431,171 L17.6505692,171 C7.89898597,171 0,163.108787 0,153.378154 L0,17.6218465 C0,7.88613199 7.90407553,0 17.6505692,0 L113.344341,0 C123.095924,0 130.994913,7.89121326 130.994913,17.6218465 L130.994913,153.373072 C131,163.108787 123.095924,171 113.349431,171 Z"
              id="路径"
              fill="url(#linearGradient-6)"
            ></path>
            <path
              d="M116.779789,171 L22.740122,171 C14.886942,171 8.51991142,164.64333 8.51991142,156.80293 L8.51991142,20.6756901 C8.51991142,12.8352896 14.886942,6.47862003 22.740122,6.47862003 L116.779789,6.47862003 C124.632969,6.47862003 131,12.8352896 131,20.6756901 L131,156.80293 C131,164.64333 124.632969,171 116.779789,171 Z"
              id="路径"
              fill="url(#linearGradient-7)"
            ></path>
            <path
              d="M112.056684,138.6882 L27.463227,138.6882 C22.3736742,138.6882 18.2460469,134.56729 18.2460469,129.486019 L18.2460469,26.7884587 C18.2460469,21.7071881 22.3736742,17.5862776 27.463227,17.5862776 L112.056684,17.5862776 C117.146237,17.5862776 121.273865,21.7071881 121.273865,26.7884587 L121.273865,129.486019 C121.273865,134.56729 117.151327,138.6882 112.056684,138.6882 Z"
              id="路径"
              fill="#FFFFFF"
            ></path>
            <path
              d="M73.3455457,164.328292 L66.103112,164.328292 C61.9754847,164.328292 58.6265589,160.984816 58.6265589,156.863905 L58.6265589,152.819214 C58.6265589,148.698303 61.9754847,145.354827 66.103112,145.354827 L73.3455457,145.354827 C77.473173,145.354827 80.8220988,148.698303 80.8220988,152.819214 L80.8220988,156.863905 C80.8220988,160.984816 77.473173,164.328292 73.3455457,164.328292 Z"
              id="路径"
              fill="url(#linearGradient-8)"
            ></path>
            <ellipse
              id="椭圆形"
              fill="#DEE3EA"
              cx="69.8541124"
              cy="105.33474"
              rx="5.88352306"
              ry="6.22963777"
            ></ellipse>
            <path
              d="M76.2516803,57.2252697 C76.2516803,53.2161471 73.18268,49.9692152 69.3960527,49.9692152 C65.6094254,49.9692152 62.540425,53.2161471 62.540425,57.2252697 C62.540425,61.2343922 65.6094254,94.1305381 69.3960527,94.1305381 C73.18268,94.1305381 76.2516803,61.2293109 76.2516803,57.2252697 Z"
              id="路径"
              fill="#DEE3EA"
            ></path>
            <g transform="translate(54.241074, 85.939530)" fill="#DEE3EA" id="路径">
              <path d="M7.60208277,0.41158292 C6.42130651,1.02641666 5.29142579,1.77844472 4.24297791,2.67782961 C2.84335088,3.87192821 1.66766418,5.23370873 0.705738698,6.70219594 C-0.510664426,8.56194099 -0.113679306,11.1076576 1.58114178,12.4796006 C1.64730597,12.5354946 1.71347016,12.5863073 1.77963434,12.6422013 C3.61187336,14.1259323 6.22281395,13.6584554 7.54609769,11.6513535 C7.86673951,11.1635515 8.23318732,10.6960746 8.6454411,10.2641666 C8.84393366,10.0558345 9.05260532,9.86274626 9.26636654,9.66965798 C8.67597841,6.80382135 8.12121715,3.66867738 7.60208277,0.41158292 Z"></path>
              <path d="M30.4185481,6.54975782 C29.4566226,5.11175824 28.2860254,3.78046534 26.9016671,2.6117731 C25.6190997,1.52946246 24.2296518,0.66056518 22.7740397,0 C22.2752635,3.16563159 21.7408605,6.2245565 21.1759201,9.03958042 C21.6696068,9.40035064 22.1429352,9.80177102 22.5806367,10.2692479 C22.9572636,10.665587 23.2931741,11.0873325 23.5985473,11.529403 C24.9727265,13.5415862 27.6091149,14.0293882 29.4617121,12.505007 L29.5685927,12.4186254 C31.2430556,11.0365198 31.6451303,8.38409651 30.4185481,6.54975782 Z"></path>
            </g>
            <g transform="translate(44.406886, 71.457909)" fill="#DEE3EA" id="路径">
              <path d="M15.4208078,0.299794966 C11.196479,1.84450123 7.23171733,4.40038035 3.86243336,7.96235105 C2.73764219,9.15136838 1.71464207,10.4166048 0.793433012,11.7478977 C-0.509492509,13.6279678 -0.178671576,16.260066 1.57213459,17.6777405 C1.63829878,17.7285532 1.70446297,17.7844472 1.77062715,17.8352599 C3.56214975,19.283422 6.12728437,18.8921641 7.4505681,16.9663626 C8.13256818,15.9805961 8.89091155,15.040561 9.72050866,14.1615012 C11.7563298,12.0070425 14.0924345,10.3657921 16.5812259,9.22250617 C16.1638825,6.24996286 15.771987,3.24185065 15.4208078,0.299794966 Z"></path>
              <path d="M49.9483341,11.5497281 C49.0729311,10.2997355 48.1008265,9.10055567 47.0269308,7.96235105 L47.0269308,7.96235105 C43.428617,4.15139809 39.1432135,1.5040561 34.5931533,0 C34.2419741,2.91156806 33.8602577,5.89427391 33.4429143,8.85157341 C36.2574371,9.9948593 38.898915,11.7631415 41.1688555,14.1615012 C41.9475571,14.984667 42.6550049,15.8586456 43.2962886,16.768193 C44.6653783,18.7194009 47.2661398,19.1208213 49.0831102,17.632009 L49.1849012,17.5456274 C50.9407969,16.1076278 51.2767074,13.445042 49.9483341,11.5497281 Z"></path>
            </g>
            <g transform="translate(33.825398, 55.147030)" fill="#DEE3EA" id="路径">
              <path d="M24.3074747,0.254063531 C17.2482649,2.3170594 10.5809507,6.27536921 5.04351723,12.1340742 C3.49629318,13.7753246 2.07630794,15.5131192 0.793740629,17.3423766 C-0.519363998,19.2122842 -0.168184853,21.8596262 1.58771087,23.2671381 C1.65387506,23.3179509 1.72003924,23.3738448 1.78620343,23.4246575 C3.58790513,24.8728197 6.13268154,24.4459929 7.48141303,22.5252726 C8.51459226,21.0517042 9.65465209,19.6492735 10.9015925,18.3332244 C15.0343094,13.9582504 19.9406383,10.8993255 25.15743,9.13612457 C24.8164299,5.88919264 24.531415,2.8709179 24.3074747,0.254063531 Z"></path>
              <path d="M71.0448382,17.0324191 C69.818256,15.3149496 68.474614,13.6787805 67.0139124,12.128993 C61.2423595,6.02130568 54.2493139,1.98169554 46.8592832,0 C46.6404324,2.59652928 46.3554175,5.59956022 46.0144174,8.84141087 C51.56203,10.5487178 56.7890007,13.7092681 61.1558371,18.3332244 C62.351882,19.5984608 63.4512254,20.9449975 64.4538673,22.3474282 C65.8076883,24.2529046 68.3982707,24.5831872 70.1796142,23.1197813 L70.2864948,23.0333997 C72.0423905,21.5903188 72.3833906,18.9124892 71.0448382,17.0324191 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
