export default {
  extension1: 'Detalles',
  extension2: 'Monto del pago',
  extension3: 'Tarifa de Extensión',
  extension4: 'Interés por mora',
  extension5: 'Interés',
  extension6: 'Fecha actual de facturación',
  extension7: 'Después de la ampliación',
  extension8: 'Número de días de prórroga',
  extension9: 'Pagar la cuota',
  extension10: 'Cancelar extensión',
  extension11: 'Ventaja de pago diferido',
  extension12: 'Si est¡s bajo presión financiera, prueba el pagodiferido.',
  extension13: 'Tasa de pago baja',
  extension14: 'No se cobran intereses adicionales',
  extension15: 'Excluye intereses de penalización',
  extension16: 'La extensión se realiza correctamente y seposponen todos los ciclos de facturación.',
  extension17: 'Solicitar pago diferido',
  extension18: 'Solicitar una extensión',
  extension19: 'Comisión de amortización diferida',
  extension20: 'Número de pagos diferidos exitosos',
  extension21: 'veces',
  extension22: 'Después de que la extensión sea exitosa, el plan de pago:',
  extension23: 'Tiempo de pago',
  extension24: 'Monto total adeudado',
  extension25: 'Confirmar extensión y pagar',
  extension26: '¿Tiene alguna pregunta?',
  extension27: 'Buscar ayuda',
  extension28: 'Esta solicitud de extensión caducará pronto. Pague la tarifa de extensión lo antes posible.',
  extension29: 'Cargos por facturación retrasada:',
  extension30: 'Tarifa de extensión',
  extension31: 'Pagar la tarifa de extensión',
  extension32: 'Error de pago en efectivo',
  extension33: 'Lo siento. No podemos emitir su pago en efectivo porque la información de pago que ingresó es incorrecta. Vuelva a enviar su solicitud de préstamo.',
  extension34: 'Recordatorio especial: confirme que la información de la tarjeta bancaria sea correcta',
  extension35: 'Corriente reembolsada',
  extension36: 'Pago restante para este período',
  extension37: 'Este período/Período total',
  extension38: 'Pago restante total',
  extension39: "Si est¡s bajo presión financiera, prueba el pagodiferido.",
  extension40: 'Detalles del préstamo >',
};
