import './toast.scss';
import { clear, config, show, loading } from './toast';

export type { ToastProps } from './toast';

const Toast = {
  show,
  clear,
  config,
  loading,
};

export default Toast;
