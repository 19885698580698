import { Http } from '@quark-base-plugin/net';

import { useApplyStepModule, UseApplyStepModuleProps } from '../step';

import { WorkInfoProps } from './type';

type UseApplyStepWork = UseApplyStepModuleProps<WorkInfoProps>;

//保存工作信息
export async function onSaveWork(params: WorkInfoProps) {
  return await Http.post('member/company-info/save', params);
}

//加载工作信息
export async function onLoadWork(): Promise<WorkInfoProps> {
  const result = await Http.post('member/company-info/load');
  return result?.data;
}

export function useApplyStepWork({ defaultValues, ...others }: UseApplyStepWork) {
  const step = useApplyStepModule({
    code: 'useApplyStepWork',
    defaultValues,
    onLoad: onLoadWork,
    onSave: onSaveWork,
    ...others,
  });

  return {
    ...step,
  };
}
