import { userStorage } from '@quark-base-plugin/storage';
import {
  useQuery,
  useQueryClient,
  QueryObserverResult,
  RefetchOptions,
} from '@tanstack/react-query';
import { clearCache, useMemoizedFn, useUpdateEffect } from 'ahooks';
import { key } from 'ionicons/icons';
import React, { useMemo } from 'react';
import { storage } from '@quark-base-plugin/storage';

import { AuthUser, getMemberInfo, logout } from '../login';

export type UserContextValue<UserType = unknown> = {
  user: UserType | undefined;
  refetchUser: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<UserType>>;
  setUser: (data: UserType) => UserType | undefined;
  clearUser: () => void;
  logout: () => Promise<boolean>;
};

export type UserContextProviderType<UserType = any> = {
  children: React.ReactNode;
  onUserChange?: (user: UserType | undefined) => void;
};

function initUserContext<UserType = unknown>() {
  const UserContext = React.createContext<UserContextValue<UserType> | null>(null);
  UserContext.displayName = 'UserContext';

  function UserContextProvider({ children, onUserChange }: UserContextProviderType<UserType>) {
    const loadUser = useMemoizedFn(async () => {
      const token = await userStorage.getToken();
      if (token) {
        const user = await userStorage.loadUser();

        if (!user.memberInfo) {
          const memberInfo = await getMemberInfo();

          user.memberInfo = memberInfo.data;
          userStorage.setUser(user).then(() => {});
        }
        return user;
      }
      return null;
    });

    const {
      data: user,
      // error,
      // status,
      isLoading,
      // isIdle,
      // isSuccess,
      refetch,
    } = useQuery<UserType, Error>({
      queryKey: ['auth-user'],
      queryFn: loadUser,
    });

    const logoutFn = React.useCallback(async () => {
      const response = await logout();
      // if (response.success) {
      // }
      await userStorage.clearUser();
      await userStorage.clearToken();
      await storage.remove('fileGrade');
      clearCache();
      refetch();
      return response.success;
    }, [refetch]);

    const queryClient = useQueryClient();

    const setUser = React.useCallback(
      (data: UserType) => queryClient.setQueryData([key], data),
      [queryClient]
    );

    const value = useMemo(
      () => ({
        // global,
        user,
        refetchUser: refetch,
        setUser,
        clearUser: () => queryClient.clear(),
        logout: logoutFn,
      }),
      [logoutFn, queryClient, refetch, setUser, user]
    );

    useUpdateEffect(() => {
      onUserChange?.(user);
    }, [user]);

    //TODO: Loading 控件
    if (isLoading) {
      return <div />;
    }

    // if (error) {
    //   return <div />;
    // }

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;

    // if (isSuccess) {

    // }
    // return <div>unknown error</div>;
  }

  function useUserContext() {
    const context = React.useContext(UserContext);
    if (!context) {
      throw new Error(`useUserContext must be used within an UserContextProvider`);
    }
    return context;
  }

  return { UserContextProvider, useUserContext };
}

export const { UserContextProvider, useUserContext } = initUserContext<AuthUser>();
