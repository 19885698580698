import { NativeResult } from '../call';
import { BaseNativePlugin } from './base';

export type EventPluginType = {
  fireBaseEvent(options: { eventName: string; params: any }): Promise<void>;
  faceBookEvent(options: { eventName: string; params: any }): Promise<void>;
  branchEvent(options: { eventName: string; params: any }): Promise<void>;
  adjustEvent(options: { eventName: string; params: any }): Promise<void>;
  allEvent(options: { eventName: string; params: any }): Promise<void>;
};

export class EventPlugin extends BaseNativePlugin implements EventPluginType {
  // @ts-ignore
  fireBaseEvent(options: { eventName: string; params: any; }): Promise<void> {
    super.callPlugin('EventPlugin', 'fireBaseEvent', options, () => {

    });
  }
  // @ts-ignore
  faceBookEvent(options: { eventName: string; params: any; }): Promise<void> {
    super.callPlugin('EventPlugin', 'faceBookEvent', options, () => {

    });
  }
  // @ts-ignore
  branchEvent(options: { eventName: string; params: any; }): Promise<void> {
    super.callPlugin('EventPlugin', 'branchEvent', options, () => {

    });
    return Promise.resolve();
  }
  adjustEvent(options: { eventName: string; params: any; }): Promise<void> {
    super.callPlugin('EventPlugin', 'adjustEvent', options, () => {

    });
    return Promise.resolve();
  }
  // @ts-ignore
  allEvent(options: { eventName: string; params: any; }): Promise<void> {
    super.callPlugin('EventPlugin', 'allEvnet', options, () => {

    });
  }

}