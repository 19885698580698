import './ErrorBlock.scss';
import { ReactNode } from 'react';

import { ErrorBlock } from './ErrorBlock';

export { createErrorBlock } from './create';

export type { ErrorBlockProps } from './create';

export type ErrorBlockStatus = 'default' | 'disconnected' | 'empty' | 'error';
export type ImageRecord = Partial<Record<ErrorBlockStatus, string | ReactNode>>;

export default ErrorBlock;
