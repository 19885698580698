// import { Network } from '@awesome-cordova-plugins/network';
import { NetworkStatus, Plugins } from '@capacitor/core';
import { useMount, useSafeState, useUnmount } from 'ahooks';
import { useRef } from 'react';
const { Network } = Plugins;

export function useNetwork() {
  const handler = useRef<any>();

  const [networkStatus, setNetworkStatus] = useSafeState<NetworkStatus>();

  useMount(() => {
    Network.getStatus().then((status) => {
      setNetworkStatus(status);
    });
    handler.current = Network.addListener('networkStatusChange', (status) => {
      setNetworkStatus(status);
    });
  });

  useUnmount(() => {
    if (handler.current) {
      handler.current.remove();
    }
  });

  return {
    networkStatus,
  };
}
