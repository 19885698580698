import { Plugins } from '@capacitor/core';
import { IonApp, isPlatform, useIonRouter } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { ModalPresent } from '@quark-base-plugin/core';
// import { useLiveUpdate } from '@quark-base-plugin/live-update';
import { useMemoizedFn } from 'ahooks';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { showCustomModal } from '@/components/customModal';
import { config } from '@/config';
import useWatcher from '@/hooks/useReactRouterWatch';
import { routes, Urls } from './config';
import { routeAnalysis } from './route-analysis';
import { RoutePage } from './Routes';
import Tabs from './Tabs';

export type AppRoutesProps = {
  otherRutes?: RoutePage[];
};


const Content = () => {
  const watcher = useWatcher();
  const onRouteChange = useCallback((context) => {
    if (context.location.pathname) {
      routeAnalysis.onRouteChange(context.location.pathname);
    }
  }, []);

  useEffect(() => {
    watcher.addListener(onRouteChange);
    return () => {
      watcher.removeListener(onRouteChange);
    };
  }, [watcher, onRouteChange]);

  const ionRouter = useIonRouter();
  const location = useLocation<any>();
  const { t } = useTranslation();

  // 处理android物理返回按键事件,
  const handleAndroidHardwareBackButton = useMemoizedFn((ev) => {
    // 如果有弹窗，先关闭
    if (ModalPresent.isShowed()) {
      ModalPresent.clear();
      return;
    }

    // 处理登录页
    if (window.location.pathname == '/login') {
      if (location?.state?.data?.route) {
        ionRouter.push(location?.state?.data?.route, 'back', 'pop');
      } else {
        ionRouter.goBack();
      }
      return;
    }

    // 处理首页TAB
    if (
      location.pathname == Urls.APP_HOME ||
      location.pathname == Urls.APP_ME ||
      location.pathname == Urls.APP_REPAY
    ) {
      showCustomModal({
        title: t('exitTitle'),
        confirmText: t('commonConfirm'),
        cancelText: t('commonCancel'),
        onConfirm: () => {
          Plugins.App.exitApp();
        },
      });
      return;
    }

    if (!ionRouter.canGoBack()) {
      Plugins.App.exitApp();
    } else {
      ionRouter.goBack();
    }
  });

  useEffect(() => {
    Plugins.App.removeAllListeners();
    Plugins.App.addListener('backButton', handleAndroidHardwareBackButton);
  }, [handleAndroidHardwareBackButton]);

  return <Tabs routes={routes} />;
};

export const AppRoutes: React.FC<AppRoutesProps> = ({ otherRutes = [] }: AppRoutesProps) => {
  // const { checkForUpdates } = useLiveUpdate();
  // useVConsole(config.env != 'pro');
  React.useEffect(() => {
    console.log('NODE_ENV: ', config.env);

    // if (isPlatform('hybrid')) {
    //   let liveUpdateJsonRemoteUrl;
    //   let liveUpdateFileName;
    //   let archiveFileUrl; // 指定下载热更新包地址
    //   if (config.env == 'pro') {
    //     //TODO: 热更新地址
    //     liveUpdateJsonRemoteUrl =
    //       'https://uang-kaya-h5.oss-ap-southeast-5.aliyuncs.com/ayokaya1_client/';
    //     liveUpdateFileName = 'live-update.json';
    //     archiveFileUrl =
    //       'https://uang-kaya-h5.oss-ap-southeast-5.aliyuncs.com/ayokaya1_client/oss.tar.gz';
    //   } else {
    //     liveUpdateJsonRemoteUrl =
    //       'https://cashloan-pub.oss-cn-chengdu.aliyuncs.com/liveupdate/ID/ayokaya/';
    //     liveUpdateFileName = 'live-update.json';
    //     archiveFileUrl =
    //       'https://cashloan-pub.oss-cn-chengdu.aliyuncs.com/liveupdate/ID/ayokaya/oss.tar.gz';
    //   }
    //   console.log('start check live update');
    //   console.log('liveUpdateJsonRemoteUrl: ', liveUpdateJsonRemoteUrl);
    //   console.log('liveUpdateFileName: ', liveUpdateFileName);
    //   console.log('archiveFileUrl: ', archiveFileUrl);

    //   checkForUpdates({
    //     liveUpdateJsonRemoteUrl,
    //     liveUpdateFileName,
    //     archiveFileUrl,
    //   });
    // }

    if (isPlatform('hybrid')) {
      // checkForUpdates({
      //   liveUpdateJsonRemoteUrl:
      //     'https://cashloan-pub.oss-cn-chengdu.aliyuncs.com/liveupdate/IN/cyclecash/',
      //   liveUpdateFileName: 'live-update.json',
      // });
    } else {
      // console.log('running on web');
    }

    // firebase 推送
    // FirebaseMessaging.requestPermission().then((pushPermissionResult) => {
    //   console.log('pushPermissionResult: ', pushPermissionResult);
    // });

    // FirebaseMessaging.getToken().then((token) => {
    //   console.log('token: ' + token);
    // });
    // FirebaseMessaging.onMessage().subscribe((message) => {
    //   console.log('FirebaseMessaging received message: ', message);
    // });
  }, []);

  return (
    <IonApp>
      <IonReactRouter>
        <Content />
        {/* <Tabs routes={routes} /> */}
      </IonReactRouter>
    </IonApp>
  );
};

export { Urls } from './config';
