import React from 'react';

import { LableItem } from '../../../const/type';
import { PickerView } from '../antd-picker-view/picker-view';

import './index.scss';
const classPrefix = 'qk-picker-view';

export type Props = {
  data?: LableItem[];
  value?: string | number;
  onChange?: (v: any) => void;
  valueKey?: string; //标识 默认id
  labelKey?: string; //显示 默认name
};

export default React.memo(function Modal({
  data,
  value,
  labelKey = 'name',
  valueKey = 'id',
  onChange,
}: Props) {
  // function handleChange(value: LableItem, e: React.MouseEvent<HTMLIonItemElement, MouseEvent>) {
  //   e.stopPropagation(); //阻止冒泡防止事件穿透
  //   onChange && onChange(value);
  // }

  return (
    <div className={`${classPrefix}-content`}>
      <PickerView
        className="qk-custom-picker"
        style={{ '--item-height': '40px' }}
        /** @ts-ignore */
        value={value ? [value] : undefined}
        onChange={(item) => {
          onChange?.(item[0]);
        }}
        columns={[
          /** @ts-ignore */
          data
            ? data?.map((item) => {
                return {
                  ...item,
                  label: item.name,
                  value: item.id,
                };
              })
            : [],
        ]}
      />
    </div>
  );

  // return (
  //   <IonList className={`${classPrefix}-content`}>
  //     {data?.map((item, index) => {
  //       return (
  //         <Item hPadding="normal" key={index} onClick={(e) => handleChange(item, e)}>
  //           <Label
  //             medium={value === item[valueKey]}
  //             color={value === item[valueKey] ? 'var(--qk-color-primary)' : 'var(--qk-color-weak)'}
  //             // className={classNames(`${classPrefix}-content-item`, {
  //             //   ['select']: value === item[valueKey],
  //             // })}
  //             style={{ textAlign: 'center' }}
  //           >
  //             {item[labelKey]}
  //           </Label>
  //         </Item>
  //       );
  //     })}
  //   </IonList>
  // );
});
