import { storage } from '@quark-base-plugin/storage';
// import throttle from 'lodash/throttle';

export enum AnalysisThirdEventName {
  CustomAppStart = "CustomAppStart",// app启动
  CustomFirstOpen = "CustomFirstOpen",// 第一次打开app
  CustomRegistered = "CustomRegistered",// 注册成功
  CustomRegisterSuccess = "CustomRegisterSuccess", // 用户注册成功（接口调用成功）
  CustomLogin = "CustomLogin",// 登录成功
  CustomLogout = "CustomLogout",// 退出登录
  CustomApplyNowToBasicInfo = "CustomApplyNowToBasicInfo", // 通过“立即申请”进入基本信息页面
  CustomApplyNotFillToBasicInfo = "CustomApplyNotFillToBasicInfo", // 申请未完成时通过“立即申请”进入基本信息页面
  CustomApplyAgainToBasicInfo = "CustomApplyAgainToBasicInfo", // 通过“再次申请”进入基本信息页面
  CustomNoLoanBorrowNowToBasicInfo = "CustomNoLoanBorrowNowToBasicInfo", // 通过暂无贷款记录时“借款”进入基本信息页面
  CustomNoRepayBorrowNowToBasicInfo = "CustomNoRepayBorrowNowToBasicInfo", // 通过无需还款时“借款”进入基本信息页面
  CustomCommitBaseInfo = "CustomCommitBaseInfo", // 提交基本信息
  CustomCommitBaseInfoSuccess = "CustomCommitBaseInfoSuccess", // 提交基本信息成功
  CustomGoToWorkInfo = "CustomGoToWorkInfo", // 进入工作信息页面 WorkInfo:Enter
  CustomCommitWorkInfo = "CustomCommitWorkInfo", // 提交工作信息
  CustomCommitWorkInfoSuccess = "CustomCommitWorkInfoSuccess", // 提交工作信息成功
  CustomGoToContactInfo = "CustomGoToContactInfo", // 进入紧急联系人页面 EmergencyContact:Enter
  CustomCommitContactInfo = "CustomCommitContactInfo", // 提交紧急联系人
  CustomCommitContactInfoSuccess = "CustomCommitContactInfoSuccess", // 提交紧急联系人成功
  CustomGoToBankInfo = "CustomGoToBankInfo", // 进入银行信息页面 SetBankCard:Enter
  CustomCommitBankInfo = "CustomCommitBankInfo", // 提交银行信息
  CustomCommitBankInfoSuccess = "CustomCommitBankInfoSuccess", // 提交银行信息成功
  CustomFaceShowPage = "CustomFaceShowPage", // 进入活体识别页面
  CustomFaceCancel = "CustomFaceCancel", // 人脸识别取消
  CustomFaceFail = "CustomFaceFail", // 人脸识别失败
  CustomFaceStart = "CustomFaceStart", // 人脸识别开始
  CustomFaceCommited = "CustomFaceCommited", // 人脸识别已提交
  CustomFaceSuccess = "CustomFaceSuccess", // 人脸识别成功
  CustomFirstApply = "CustomFirstApply", // 第一次借款申请
  CustomFirstTodayApply = "CustomFirstTodayApply", // 当天第一次借款申请
  CustomReloanSubmitApplication = "CustomReloanSubmitApplication", // 老客借款申请提交
  CustomNewSubmitApplication = "CustomNewSubmitApplication", // 新客借款申请提交
  CustomSubmitApplication = "CustomSubmitApplication", // 借款申请提交
  CustomSubmitApplicationSuccess = "CustomSubmitApplicationSuccess", // 借款申请提交成功（授信项填写完成）
  CustomSubmitApplicationFailed = "CustomSubmitApplicationFailed", // 借款申请失败
  CustomClaimLoanSuccess = "CustomClaimLoanSuccess", // 领取额度成功
  CustomLoanSuccess = "CustomLoanSuccess", // 放款成功
}

/**
 * event: 事件名称 (QKAnalysisProvider 会自动转换成 actionCode)
 * params : 参数
 */
export type AnalysisEvent = {
  event: string,
  params?: Record<string, unknown>
}
export type AnalysisProvider = {
  /**
   * 初始化
   */
  init: (options: Record<string, unknown>) => void;
  /**
   * 直接调用埋点的实现
   */
  log?: (event: AnalysisEvent) => Promise<boolean>;
  /**
   * 上传保存的事件
   */
  logEvents?: (events: AnalysisEvent[]) => Promise<boolean>;
}

const _KEY_STORE_EVENTS = 'YWFqaWFvemljYXNobWVoNQ';

/**
 * 使用方法
 * 
 * 1. 先调用注册方法 register
 * 2. 调用初始化方法 init
 * 3. 方法说明： add: 保存埋点, logAllEvents: 上传保存的埋点, logEvent: 直接发送埋点
 * 
 */
export class Analysis {
  private _providers: AnalysisProvider[] = [];
  private _events: AnalysisEvent[] = [];

  constructor() {
    storage.get(_KEY_STORE_EVENTS).then((json) => {
      if (json && json.length) {
        this._events.push(...JSON.parse(json));
        // console.log("event storage");
        // console.log(this._events);
      }
    });
  }

  register(providers: AnalysisProvider[]) {
    this._providers.push(...providers);
  }

  init(options: Record<string, unknown>) {
    this._providers.forEach(provider => provider.init && provider.init(options));
  }

  /**
   * 保存事件
   * @param event 
   */
  async add(event: AnalysisEvent) {
    await this._addEvent(event);
    await this.syncEvents();
    // throttle(
    //   this.syncEvents,
    //   1000,
    // );
    // console.log(`LOG: ${event.event}`, event.params);
    // console.log('current events');
    // console.log(this._events);
  }

  /**
   * 上传已保存的事件
   * @returns 
   */
  logAllEvents() {
    if (!this._events || !this._events.length) return;
    const events: AnalysisEvent[] = [];
    events.push(...this._events);
    this._events = [];
    this.syncEvents();
    // console.log('upload events');
    // console.log(events);
    const _this = this;

    this._providers.forEach(async provider => {
      if (provider.logEvents) {
        const result = await provider.logEvents?.(events);
        if (!result) {
          // console.log('event logEvents failed restore');
          this._events = [...this._events, ...events];
          // console.log(this._events);
          this.syncEvents();
        }
      }
    })
    // events.forEach(event => this.logEvent(event));
  }

  /**
   * 直接调用埋点
   * @param event 
   */
  logEvent(event: AnalysisEvent) {
    const _this = this;
    // console.log(`LOGED: ${event.event}`, event.params);
    this._providers.forEach(provider => {
      provider.log?.(event).then((result) => {
        if (!result) {
          // _this.add(event);
          // console.log('upload event failed');
          // console.log(event.event);
        } else {
          // console.log('upload event success');
          // console.log(event.event);
        }
      });
    });
  }

  private async syncEvents(): Promise<void> {
    // console.log('event syncEvents');
    if (this._events.length) {
      await storage.set(_KEY_STORE_EVENTS, JSON.stringify(this._events));
    } else {
      await storage.remove(_KEY_STORE_EVENTS);
    }
  }

  private async _addEvent(event: AnalysisEvent) {
    this._events.push(event);
    return true;
  }
}
