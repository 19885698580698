import { Http } from '@quark-base-plugin/net';

import { useApplyStepModule, UseApplyStepModuleProps } from '../step';

import { SaveParams, BasicInfoProps } from './type';

type UseBasicInfo = UseApplyStepModuleProps<BasicInfoProps>;

//保存证件信息
export async function onSaveBasic(params: SaveParams) {
  // return await Http.post('/member/basis-info/save', params);
  return await Http.post('/member/certificate-info/save-cert', params);
}

//加载证件信息
export async function onLoadBasic(): Promise<BasicInfoProps> {
  // const result = await Http.post('/member/basis-info/load');
  const result = await Http.post('/member/certificate-info/load-cert')
  return result?.data;
}


export function useApplyStepBasic({ defaultValues, ...others }: UseBasicInfo) {
  const step = useApplyStepModule<BasicInfoProps>({
    code: 'useApplyStepBasic',
    defaultValues,
    onLoad: onLoadBasic,
    onSave: onSaveBasic,
    ...others,
  });

  return {
    ...step,
  };
}
