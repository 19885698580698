export default {
  verificationCode: 'Verification Code',
  contactNumber: 'Contact number',
  pleaseEnterPhoneNumber: 'Please enter the phone number',
  pleaseEnterValidPhoneNumber: 'Please enter a valid number',
  verificationCodeError: 'Please enter the correct verification code',
  logIn: 'Log In',
  send: 'Send',
  haveRead: 'I have read and agreed to ',
  termsConditions: 'Terms',
  andThe: 'and the',
  privacyPolicy: 'Privacy Policy',
  invalidPhoneNumber: 'Invalid Phone Number',
  incorrectVerificationCode: 'Incorrect Verification Code',
  codeHeader: 'Please Enter SMS code',
  codeHeader2: 'The SMS verification code has been sent to: {{phone}}',
  countDown: 'Retry after {{count}}s',
  reSendCode: 'Resend SMS code',
  usePasswordLogin: 'Use password login',
  welcomeMeg: 'Welcome to N MONEY. Log in or register with your phone number and SMS code.',
  phoneNumber: 'Phone number',
  password: 'Password',
  yourPassword: 'Your Password',
  yourPasswordPlaceholder: 'Please set your password',
  confirmPassword: 'Confirm your password',
  ConfirmPasswordPlaceholder: 'Enter the set password again',
  passwordsNotMatch: 'The passwords entered do not match, please check.',
  phoneNumPlaceholder: 'Please enter your phone number',
  passwordPlaceholder: 'Please enter your password',
  loginNext: 'Next',
  setPassword: 'Set password',
  setPasswordTip:
    'Please Enter a password you can remember. Please enter it twice to ensure you truly remember it.',
  getCode: 'Obtain verification code',
  getNewCode: 'Get new verification code',
  passwordRequires: 'Please enter a password between 6-20 characters.',
  sMSCode: 'SMS code',
  pleaseSMSCode: 'Please enter SMS code',
  notGettingCode: 'Not getting the code?',
  canNotCode: 'Can not get the code?',
  chooseTryAgain: 'You can choose either of the two options below to try again.',
  smsVerificationCode: 'SMS verification code',
  voiceVerificationCode: 'Voice verification code',
  // 新
  useSMSCodeLogin: 'Use SMS code to log in',
  accessPassword: 'Access password',
  forgotPassword: 'Forgot password',
};
