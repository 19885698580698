import { Http, HttpResponse } from '@quark-base-plugin/net';
import { useCreation, useRequest } from 'ahooks';

import { useApplyStepModule, UseApplyStepModuleProps } from '../step';

import { ContactInfoProps } from './type';

//保存基本信息
export async function onSaveContact(params: ContactInfoProps) {
  return await Http.post('/member/contact-info/save', params);
}

//加载基本信息
export async function onLoadContact(): Promise<ContactInfoProps> {
  const result = await Http.post('/member/contact-info/load');
  return result?.data;
}

type UseApplyStepContactProps = UseApplyStepModuleProps<ContactInfoProps>;

export function useApplyStepContact({ defaultValues, ...others }: UseApplyStepContactProps) {
  const loadResult = useRequest(onLoadContact);

  const step = useApplyStepModule<ContactInfoProps>({
    code: 'useApplyStepContact',
    defaultValues,
    onLoad: onLoadContact,
    onSave: onSaveContact,
    ...others,
  });

  // const memberContactInfos = useCreation(() => {
  //   return loadResult.data?.memberContactInfos?.map((item) => {
  //     return {
  //       relationId: item.relationId,
  //       contactWayId: item.contactWayId,
  //       contactNumber: item.contactNumber,
  //       name: item.name,
  //     };
  //   });
  // }, [loadResult.loading]);

  return {
    ...step,
    loadResult,
  };
}
