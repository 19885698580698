import { FirebaseMessaging } from '@awesome-cordova-plugins/firebase-messaging';
// import { Plugins, PushNotificationToken } from '@capacitor/core';
import { isPlatform } from '@ionic/react';
import { useEffect, useState } from 'react';

export function useFCMToken() {
  const [fcmToken, setFcmToken] = useState<string>();
  useEffect(() => {
    if (isPlatform('hybrid')) {
      // Plugins.PushNotifications.requestPermission().then((result) => {
      //   if (result.granted) {
      //     console.log('FCM token granted');
      //     Plugins.PushNotifications.register();
      //   } else {
      //     console.log('FCM token not granted');
      //   }
      // });

      // Plugins.PushNotifications.addListener('registration', (token: PushNotificationToken) => {
      //   console.log('Push registration success, token: ' + token.value);
      //   setFcmToken(token.value);
      // });

      // Plugins.PushNotifications.addListener('registrationError', (error: any) => {
      //   // console.log('Error on registration: ', error);
      // });

      FirebaseMessaging.requestPermission({
        forceShow: true,
      }).then(() => {
        FirebaseMessaging.getToken().then((token) => {
          console.log('Push Token: ' + token);
          if (token && typeof token === 'string') {
            setFcmToken(token);
          }
        });
      });

      FirebaseMessaging.onTokenRefresh().subscribe(() => {
        FirebaseMessaging.getToken().then((token) => {
          console.log('Push Token refreshed: ' + token);
          if (token && typeof token === 'string') {
            setFcmToken(token);
          }
        });
      });
    }
  }, []);

  return { fcmToken };
}
