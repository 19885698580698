import { isPlatform } from '../../judgmentSystem';
import PluginHelper from '../../inject';
import { Analysis, AnalysisWrapper } from '@quark-base-plugin/analysis';
import { AnalysisProps } from '@quark-base-plugin/analysis/react_augmented';
import _ from 'lodash';
import React from 'react';
import { RegisterOptions, UseFormReturn, FieldValues, useController } from 'react-hook-form';
import { useMemoizedFn } from 'ahooks';
import { Http } from '@quark-base-plugin/net';
import { FormItemProps } from '../form';
import { InputItem, InputItemProps } from '../input';
import  { PickerProps } from '../picker/picker';
import { ModalPresent } from '@quark-base-plugin/core';
import { useTranslation } from 'react-i18next';
import { mergeProps } from '@quark-base-plugin/core/src/utils/with-default-props';
import { useState } from 'react';
import classNames from 'classnames';
import { NativeBridge } from '../../../../../apps/vitalidad_prestamos/src/native';

import './styles/index.scss';

const classPrefix = 'qk-contact-item';

export type Item = {
  name: string;
  value: string;
};

export type ValueType = {
  relation: string;
  phone: string;
  name: string;
  awareLoan: boolean;
};

export type ItemProps = RegisterOptions & {
  name: string;
  label: string;
  controlProps?: PickerProps & InputItemProps;
  analysis?: AnalysisProps;
};

export type Props = {
  defaultValue?: any;
  relations: any;
  imgIcon: string;
  name: string;
  FormItem: React.FC<FormItemProps>;
  relationFormItem: FormItemProps;
  phoneFormItem: FormItemProps;
  nameFormItem: FormItemProps;
  isKownFormItem?: FormItemProps;
  isIputMode: boolean; //是否是手动输入模式
  handleCustomerSelect?: () => void; //自定义选择联系人
  cusOnFocus?: () => void;
  formRef: UseFormReturn<FieldValues, any>;
  formItemStyle: string;
  /**
   * contact 在group中的位置，从1开始，默认1
   */
  index?: number;
  /**
   * 埋点参数，设置此值的话会自动进行埋点
   */
  analysisProps?: {
    // 页面code
    pageCode: string;
  };
};

export default function Contact(props: Props) {
  type RadioProps = {
    checked?: boolean;
    name?: string;
    onChange?: () => void;
  };
  const {
    relations,
    FormItem,
    name,
    defaultValue = {},
    imgIcon,
    relationFormItem,
    phoneFormItem,
    nameFormItem,
    isKownFormItem,
    isIputMode,
    formRef,
    handleCustomerSelect,
    cusOnFocus,
    analysisProps,
    index = 1,
    formItemStyle,
    ...others
  } = props;
  const { name: relationName, label: relationLabel, ...relationOthers } = relationFormItem;
  const { name: phoneName, label: phoneLabel, ...phoneOthers } = phoneFormItem;
  const { name: nameName, label: nameLabel, ...nameOthers } = nameFormItem;
  const { name: isKownName, label: isKownLabel, ...isKownOthers } = isKownFormItem || {};
  const { t } = useTranslation();
  const [isInput, setIsInput] = useState(true);

  const isKownList = [
    { id: 1, name: t('yes') },
    { id: 2, name: t('no') },
  ];

  const mergeContextValue = useMemoizedFn((data: any[]) => {
    // const user = await userStorage.loadUser() as any
    return data.map((item) =>
      mergeProps(item, {
        // memberId: user?.memberId,
        //TODO: 哪里获取?（不传）
        businessOrderNo: '',
        merchantId: '7',
        // appInfoId: 7013,
        appInfoId: isPlatform() === 'android' ? 14516 : 14016,
      })
    );
  });

  const phoneClickProps = isIputMode
    ? {}
    : {
        onClick: (e?: any) => {
          e?.stopPropagation();
          handleSelectContact();
        },
        analysis: analysisProps
          ? {
              analysisType: ['onClick'],
              actionCode: `${analysisProps.pageCode}:ContactSelect-${index}`,
            }
          : undefined,
      };
  const {
    field: { value, onChange },
  } = useController({ name, control: formRef.control });

  const uploadContacts = useMemoizedFn(async () => {
    Analysis.logEvent({ event: 'UploadPhotoContactsStart' });
    try {
      const contactsResult = (await PluginuploadContacts()) as any;
      if (!contactsResult?.success) {
        setIsInput(false);
        return false;
      }
      const res = (await PluginSelectContact()) as any;
      const contact = res?.data;
      const fieldValue = _.get(formRef.getValues(), name);

      onChange({
        [nameName]: contact.name,
        [phoneName]: contact.phoneNo,
        [relationName]: fieldValue?.[relationName],
      });
      if (analysisProps) {
        const event = `${analysisProps.pageCode}:ContactSelectSuccess-${index}`;
        Analysis.add({
          event: event,
          params: {
            pageCode: analysisProps.pageCode,
            actionCode: event,
            actionTime: Date.now(),
            content: JSON.stringify(contact || {}),
          },
        });
      }
      if (!contactsResult?.data?.length) {
        console.log('contacts is empty!');
        Analysis.logEvent({
          event: 'UploadPhotoContactsFailed',
        });
        return false;
      }
      const result = await Http.post(
        '/personal-contact/save',
        mergeContextValue(contactsResult?.data || [])
      );
      Analysis.logEvent({
        event: result?.success ? 'UploadPhotoContactsSuccess' : 'UploadPhotoContactsFailed',
      });
      return result?.success;
    } catch (error) {
      // const permission = await QKContacts.checkPermissions();
      // console.log('check permissions: ', permission);
      Analysis.logEvent({
        event: 'UploadPhotoContactsFailed',
      });
      return false;
    }
  });

  async function PluginuploadContacts() {
    return PluginHelper.callPlugin(
      'ccContactPlugin',
      'getAllContacts',
      {},
      'ccContactPlugin_getAllContacts_result'
    )
      .then((r: any) => {
        return r;
      })
      .catch((e: any) => {
        console.log(e);
      });
  }
  async function PluginSelectContact() {
    return await NativeBridge.Plugins.contactPlugin.selectAPerson();
  }

  //选择联系人
  async function handleSelectContact() {
    if (handleCustomerSelect) {
      handleCustomerSelect();
      return;
    }
    if (!isIputMode) {
      if (isPlatform() === 'h5') {
        return;
      }
      try {
        const res = (await PluginSelectContact()) as any;
        const contact = res?.data;
        const fieldValue = _.get(formRef.getValues(), name);
        contact &&
          onChange({
            [nameName]: contact?.name,
            [phoneName]: contact?.phoneNo,
            [relationName]: fieldValue?.[relationName],
          });
        if (analysisProps) {
          const event = `${analysisProps.pageCode}:ContactSelectSuccess-${index}`;
          Analysis.add({
            event: event,
            params: {
              pageCode: analysisProps.pageCode,
              actionCode: event,
              actionTime: Date.now(),
              content: JSON.stringify(contact || {}),
            },
          });
        }
      } catch (error: any) {
        if (analysisProps) {
          const event = `${analysisProps.pageCode}:ContactSelectError-${index}`;
          Analysis.add({
            event: event,
            params: {
              pageCode: analysisProps.pageCode,
              actionCode: event,
              actionTime: Date.now(),
              content: '0',
            },
          });
        }
        console.log('select contact error: ', error);
      }
    }
  }
  const Radio = React.memo(({ checked, name }: RadioProps) => {
    return (
      <div
        className={classNames(`${classPrefix}-radio`, checked && `${classPrefix}-radio-checked`)}
      >
        {name}
      </div>
    );
  });
  Radio.displayName = 'replenishRadio';
  return (
    <div className={`${classPrefix}-container`}>
      <AnalysisWrapper pageCode={analysisProps?.pageCode}>
        <div className={`${classPrefix}-container-box`}>
          <div className={`${classPrefix}-relations`}>
            <div className="form-item-title">
              <span></span>
              <span className="label">{t('relationship')}</span>
            </div>
            <FormItem
              {...relationFormItem}
              name={`${name}.${relationFormItem.name}`}
              // label={t('pleaseSelect')}
              {...others}
              type="input"
              analysis={
                analysisProps
                  ? {
                      analysisType: ['onClick'],
                      actionCode: `${analysisProps.pageCode}:ContactRelationShip-${index}`,
                    }
                  : undefined
              }
            >
              <div className="radio relation-radio">
                {relations?.map((item: any) => {
                  return (
                    <div
                      key={item['id']}
                      className={`${classPrefix}-form-radio`}
                      onClick={(e) => {
                        onChange({ ...value, [relationName]: item['id'] });
                      }}
                    >
                      <Radio checked={item['id'] == value?.relationId} name={item.name} />
                    </div>
                  );
                })}
              </div>
            </FormItem>
          </div>
          <div>
            <div className="form-item-title">
              <span></span>
              <span className="label">{t('contactName')}</span>
            </div>
            {/* @ts-ignore */}
            <FormItem
              {...phoneFormItem}
              name={`${name}.${nameName}`}
              label={t('pleaseEnter')}
              type="input"
              {...phoneOthers}
              {...others}
              // {...phoneClickProps}
              extraData={{
                icon: (
                  <AnalysisWrapper pageCode={analysisProps?.pageCode}>
                    <div className={'imgIcon'} {...phoneClickProps}>
                      <img src={imgIcon} alt="" />
                      {/* <span>{t('selectContact')}</span> */}
                    </div>
                  </AnalysisWrapper>
                ),
              }}
            >
              <InputItem
                maxLength={16}
                // disabled={!isIputMode}
                className={`${classPrefix}-container-formItem-control`}
                analysis={
                  analysisProps
                    ? {
                        analysisType: ['onFocus', 'onBlur'],
                        actionCode: `${analysisProps.pageCode}:ContactPhone-${index}`,
                      }
                    : undefined
                }
                customRender={() => <></>}
                onFocus={() => {
                  if (index === 2) {
                    cusOnFocus && cusOnFocus();
                  }
                }}
              />
            </FormItem>
          </div>
          <div>
            <div className="form-item-title">
              <span></span>
              <span className="label">{t('contactNumber')}</span>
            </div>
            <FormItem
              {...nameFormItem}
              name={`${name}.${phoneName}`}
              label={t('pleaseEnter')}
              // defaultValue={data[nameName]}
              type="input"
              {...nameOthers}
              {...others}
            >
              <InputItem
                type="digits"
                maxLength={32}
                className={`${classPrefix}-container-formItem-control`}
                analysis={
                  analysisProps
                    ? {
                        analysisType: ['onFocus', 'onBlur'],
                        actionCode: `${analysisProps.pageCode}:ContactName-${index}`,
                      }
                    : undefined
                }
                customRender={() => <></>}
                readOnly={!isIputMode}
              />
            </FormItem>
          </div>
        </div>
      </AnalysisWrapper>
    </div>
  );
}
