import { IonIcon } from '@ionic/react';
import { Analysis } from '@quark-base-plugin/analysis';
import classNames from 'clsx';
import { addOutline } from 'ionicons/icons';
import { useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';

import Contact, { Props as ContactProps } from './ContactItem';
import './index.scss';

type ContactItem = {
  name: string;
  contactNumber: string;
  relationId: number;
};

const classPrefix = 'qk-contact-group';

type Props = {
  relations: any;
  num?: number;
  data?: any;
  name: string;
  max?: number;
  defaultValue?: ContactItem[];
  className?: string;
  onAdd?: () => void;
  addText?: string;
  min?: number;
  title?: string;
  setpIndex?: number;
  cusOnFocus?: () => void;
} & Omit<ContactProps, 'index'>;

export default class {
  static Group({
    relations,
    num = 2,
    max = 5,
    min = 2,
    onAdd,
    name,
    className,
    defaultValue,
    formRef,
    addText,
    nameFormItem,
    relationFormItem,
    phoneFormItem,
    isKownFormItem,
    analysisProps,
    title,
    setpIndex,
    ...others
  }: Props) {
    const initValue = {
      [nameFormItem.name]: '',
      [phoneFormItem.name]: '',
      [relationFormItem.name]: '',
      [(isKownFormItem as any)?.name]: '',
    };
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { fields, append } = useFieldArray({
      control: formRef.control,
      name,
    });

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (!defaultValue?.length) {
        const arr = [];
        for (let i = 0; i < num; i++) {
          arr.push(initValue);
        }
        formRef.setValue(name, arr);
      } else {
        formRef.setValue(name, defaultValue);
      }
    }, [defaultValue]);

    //添加
    function handleAdd() {
      if (onAdd) {
        onAdd();
        return;
      }
      append(initValue);
      if (analysisProps?.pageCode) {
        Analysis.add({
          event: `${analysisProps.pageCode}:AddContact:Touch`,
          params: {
            pageCode: analysisProps.pageCode,
            actionCode: `${analysisProps.pageCode}:AddContact:Touch`,
            actionTime: Date.now(),
            content: '1',
          },
        });
      }
    }

    function renderAdd() {
      return (
        <div className={`${classPrefix}-add`} onClick={handleAdd}>
          {/* @ts-ignore */}
          <IonIcon icon={addOutline} size="small" />
          {addText || 'Add contact'}
        </div>
      );
    }
    return (
      <div className={`${classPrefix}`}>
        {fields.map((field, index) => {
          return (
            <div
              className={classNames(`${classPrefix}-groupItem`, className)}
              key={`contact_${index}`}
            >
              <div className="qk-contact-group-title">
                {`${title} ${index + 1}`}
                {/* <span className="qk-contact-group-required">*</span> */}
              </div>
              <Contact
                formRef={formRef}
                defaultValue={field}
                relations={relations}
                name={`${name}.${index}`}
                nameFormItem={nameFormItem}
                relationFormItem={relationFormItem}
                phoneFormItem={phoneFormItem}
                isKownFormItem={isKownFormItem}
                index={index + 1}
                analysisProps={analysisProps}
                {...others}
              />
            </div>
          );
        })}
        {/* {fields.length < max && renderAdd()} */}
      </div>
    );
  }

  render() {
    return Contact;
  }
}
