import { goBack, Toast } from '@quark-base-plugin/core';
import { useForm } from '@quark-base-plugin/core/src/components/form';
import { notificationStore, storage } from '@quark-base-plugin/storage';
import { useLocalStorageState, useMemoizedFn, useSafeState, useUpdateEffect } from 'ahooks';
import { useEffect, useMemo, useRef, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useHistory } from 'react-router';

import { useAuth } from '../app/auth';
import { useUserContext } from '../app/user';

import { checkRegisterStatus, VerifyCodeType } from './api/login';
import { useSendCode } from './hooks/useSendCode';
import { NativeBridge } from '@/native/index';
import { Urls } from '@/routes';
import { useTranslation } from 'react-i18next';

const TEL_CACHE_KEY = '_LOGIN_TEL';
const AGREE_CACHE_KEY = '_LOGIN_AGREE_MENT';

/**
 * 登录类型
 */
export enum LoginType {
  // 验证码登录
  LOGIN_BY_SMS,
  // 密码登录&验证码登录
  LOGIN_BY_PWDANDSMS,
}

/**
 * 登录步骤
 */
export enum LoginStep {
  INPUT_TEL, // 添加手机号
  INPUT_TELANDSMS,
  INPUT_SETPWD, // 设置密码
  INPUT_PWD, // 密码登录
  INPUT_SMS, // 验证码登录
}

export type LoginFormKeys = {
  telephoneNo: string;
  smsCode: string;
  password: string;
  repeatPassword: string;
};

export type LoginModuleProps = {
  loginType: LoginType;
  // zoneCode: string;
  // 自定义登录表单的key
  formKeys?: LoginFormKeys;
  localeStrings?: {
    agreePrivacyPolicy: string;
    passwordNotTally: string;
  };
  // 自定义返回操作, 如果不传的话调用 history.goBack();
  onBack?: () => void;
  // 注册/登录成功回调 user: 用户信息  userRegistered: 是否已注册（区分新老客）
  onSucess?: (user: any, userRegistered: boolean) => void;
  // 关闭登录状态弹窗
  onCancel?: (state: boolean) => void;
};

export function useLoginModule({
  loginType = LoginType.LOGIN_BY_SMS,
  // zoneCode,
  formKeys = {
    telephoneNo: 'telephoneNo',
    smsCode: 'smsCode',
    password: 'password',
    repeatPassword: 'repeatPassword',
  },
  localeStrings,
  onBack,
  onSucess,
  onCancel,
}: LoginModuleProps) {
  const { t } = useTranslation();
  const [isAgree, setIsAgree] = useLocalStorageState<boolean>(AGREE_CACHE_KEY);
  const initialStep = useMemo(() => {
    return loginType === LoginType.LOGIN_BY_SMS ? LoginStep.INPUT_TELANDSMS : LoginStep.INPUT_SMS;
  }, [loginType]);
  const [step, setStep] = useSafeState<LoginStep>(initialStep);
  const [userRegistered, setUserRegistered] = useSafeState<boolean>(false);
  // const [isCheckRegister, setIsCheckRegister] = useState(false); //校验是否注册
  const [pageSmsCode, setPageSmsCode] = useState<string>(''); // 保存验证码

  const { login, loginByPwd, isLoggingIn, register, isRegistering } = useAuth();
  const { refetchUser } = useUserContext();

  const onFinish: SubmitHandler<any> = async (data) => {
    // return loginWithSMSCode(data);
    switch (loginType) {
      case LoginType.LOGIN_BY_SMS:
        return loginWithSMSCode(data);
      case LoginType.LOGIN_BY_PWDANDSMS:
        return handleSubmitWithTypePwdAndSMS(data);
      default:
        break;
    }
  };

  const {
    Form,
    FormItem,
    formRef: { getValues, trigger, setValue, unregister, setError, watch, clearErrors },
    values,
    ...otherForm
  } = useForm<any>({
    onFinish,
    mode: 'onSubmit',
  });

  async function PluginGetDeviceInfo() {
    return await NativeBridge.Plugins.appPlugin.getEquipmentInfo();
  }

  const submitDisabled = useMemo(() => {
    switch (step) {
      case LoginStep.INPUT_TEL: {
        const length = values[formKeys.telephoneNo]?.length || 0;
        return length < 1;
      }
      case LoginStep.INPUT_SETPWD:
        // eslint-disable-next-line no-prototype-builtins
        if (values.hasOwnProperty(formKeys.repeatPassword)) {
          return !values[formKeys.password]?.length || !values[formKeys.repeatPassword]?.length;
        }
        return !values[formKeys.password]?.length;

      case LoginStep.INPUT_SMS:
        return !values[formKeys.smsCode]?.length || values[formKeys.telephoneNo]?.length != 10;
      case LoginStep.INPUT_PWD:
        return (
          !values[formKeys.password]?.length ||
          values[formKeys.password]?.length < 6 ||
          values[formKeys.telephoneNo]?.length != 10
        );
      case LoginStep.INPUT_TELANDSMS:
        return !values[formKeys.telephoneNo]?.length || !values[formKeys.smsCode]?.length;
    }
  }, [step, values, formKeys]);
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === formKeys.telephoneNo && type === 'change') {
        storage.set(TEL_CACHE_KEY, value[name]);
      }
    });
    return () => subscription.unsubscribe();
  }, [formKeys.telephoneNo, watch]);
  useEffect(() => {
    storage.get(TEL_CACHE_KEY).then((value) => setValue(formKeys.telephoneNo, value ?? ''));
  }, []);

  useUpdateEffect(() => {
    if (step !== LoginStep.INPUT_SMS) {
      setValue(formKeys.smsCode, '');
      // resetCode(); // todo: 暂时注释， 切换状态不清空上一次的验证码
    }
  }, [step]);

  const submit = async () => {
    const result = await trigger();
    if (!result) {
      return result;
    }
    const r = await onFinish(getValues());
    return r;
  };

  const checkAgree = useMemoizedFn(() => {
    if (!isAgree) {
      notificationStore.addToastNotification(localeStrings?.agreePrivacyPolicy || '');
      return false;
    }
    return true;
  });

  const sceneCode = useMemo(() => {
    return userRegistered ? 'LOGIN' : 'REGISTER';
  }, [userRegistered]);

  // const sceneCode = 'LOGIN';

  const {
    requestSMSCodeFn,
    isRequestingCode,
    canSendVoiceCode,
    code,
    resetCode,
    count,
    isCountDown,
  } = useSendCode({
    // zoneCode,
    checkAgree,
    sceneCode,
  });

  useUpdateEffect(() => {
    // setValue(formKeys.smsCode, code);  // todo: 暂时注释，取消自动回显验证码
    // clearErrors();
  }, [code]);

  const history = useHistory();
  const stepHistoryRef = useRef<LoginStep[]>([4]);
  const pushStep = useMemoizedFn((step: LoginStep) => {
    const steps = stepHistoryRef.current;
    steps.push(step);
    setStep(step);
    stepHistoryRef.current = steps;
  });

  const popStep = useMemoizedFn(() => {
    const steps = stepHistoryRef.current;
    const popedStep = steps.pop();
    stepHistoryRef.current = steps;
    if (steps.length > 0) {
      setStep(steps[steps.length - 1]);
    } else {
      if (onBack) {
        onBack();
      } else {
        goBack(history);
      }
    }

    if (popedStep === LoginStep.INPUT_SETPWD || popedStep === LoginStep.INPUT_PWD) {
      unregister([formKeys.password, formKeys.repeatPassword]);
    }
  });

  const requestSMSCode = async (shouldAgree: boolean | undefined = false) => {
    const result = await trigger(formKeys.telephoneNo);
    if (!result) {
      return false;
    }
    return requestSMSCodeFn(getValues(formKeys.telephoneNo), VerifyCodeType.SMS, shouldAgree);
  };

  const requestVoiceCode = async (shouldAgree: boolean | undefined = false) => {
    const result = await trigger(formKeys.telephoneNo);
    if (!result) {
      return false;
    }
    return requestSMSCodeFn(getValues(formKeys.telephoneNo), VerifyCodeType.VOICE, shouldAgree);
  };

  const callLogin = async (options: any, loginFn: (options: any) => Promise<any>) => {
    Toast.loading();
    const user = await loginFn({
      // zoneCode,
      ...options,
    });
    Toast.clear();
    if (user) {
      await refetchUser();
      onSucess?.(user, userRegistered);
    }
    return !!user;
  };

  const loginWithSMSCode = async (data: any) => {
    if (!checkAgree()) {
      return false;
    }
    return callLogin(data, login);
  };

  const loginByPwdFn = async (data: any) => {
    return await callLogin(data, loginByPwd);
  };

  const registerUser = async (data: any) => {
    return callLogin(data, register);
  };

  const handleSubmitWithTypePwdAndSMS = useMemoizedFn(async (data: any) => {
    const telephoneNo = data[formKeys.telephoneNo];
    const password = data[formKeys.password];
    const repeatPassword = data[formKeys.repeatPassword];
    const smsCode = data[formKeys.smsCode];
    switch (step) {
      case LoginStep.INPUT_TEL:
        // setIsCheckRegister(true);
        if (!checkAgree()) {
          // setIsCheckRegister(false);
          return false;
        }
        /** new */
        // 先判断该用户是否注册
        // eslint-disable-next-line no-case-declarations
        const response = await checkRegisterStatus({
          telephoneNo,
        });
        setUserRegistered(!!response.data);
        // 已经注册过的账号，直接切到密码登录状态
        if (!!response?.data) {
          pushStep(LoginStep.INPUT_PWD);
          return true;
        }
        // 直接跳转到获取验证码并自动获取验证码
        pushStep(LoginStep.INPUT_SMS);
        if (!isCountDown) requestSMSCode(); // 自动发送验证码
        return true;

      case LoginStep.INPUT_SETPWD:
        /** new */
        // setIsCheckRegister(true);
        if (repeatPassword) {
          // 需要再次输入密码
          if (password !== repeatPassword) {
            setError(formKeys.repeatPassword, { message: localeStrings?.passwordNotTally || '' });
            return false;
          }
          const result = await registerUser({
            ...data,
            smsCode: pageSmsCode,
            repeatPassword: undefined,
          });
          return result;
        }
        break;

      case LoginStep.INPUT_SMS:
        /** new */
        if (!checkAgree()) {
          return false;
        }
        // 判断验证码是否正确
        // if (smsCode != code) {
        //   Toast.show(t('verificationCodeError'));
        //   return false;
        // }
        // 用户注册过，调用登录接口
        if (userRegistered) {
          const result = await loginWithSMSCode(data);
          return result;
        }
        // 新客需要去设置密码，点击设置按钮则直接进行登录
        else {
          setPageSmsCode(smsCode);
          // pushStep(LoginStep.INPUT_SETPWD);
          onCancel?.(false);
          setTimeout(() => {
            history.push(Urls.APP_ME_SETPWD, { smsCode, telephoneNo });
          }, 200);
        }
        return true;

      case LoginStep.INPUT_PWD:
        /** new */
        if (!checkAgree()) {
          return false;
        }
        const result = await loginByPwdFn(data);
        return result;
      default:
        break;
    }
  });

  const togglePwdOrSmsStep = () => {
    // if (!userRegistered) {
    //   return;
    // }
    if (step === LoginStep.INPUT_PWD) {
      unregister(formKeys.password);
      // // 自动发送验证码
      // if (!isCountDown) requestSMSCode();
      setStep(LoginStep.INPUT_SMS);
    } else if (step === LoginStep.INPUT_SMS) {
      unregister(formKeys.smsCode);
      setStep(LoginStep.INPUT_PWD);
    }
  };

  return {
    Form,
    FormItem,
    requestSMSCode,
    requestVoiceCode,
    // isLoading: isRequestingCode || isLoggingIn || isRegistering || isCheckRegister,
    isLoading: isRequestingCode || isLoggingIn || isRegistering,
    sendCodeProps: {
      code,
      count,
      isCountDown,
      canSendVoiceCode,
      sceneCode,
    },
    agreementProps: {
      isAgree,
      toggleIsAgree: useMemoizedFn(() => {
        setIsAgree(!isAgree);
      }),
    },
    step,
    setStep,
    userRegistered,
    setUserRegistered,
    togglePwdOrSmsStep,
    back: popStep,
    submit,
    submitDisabled,

    getValues,
    trigger,
    setValue,
    unregister,
    setError,
    clearErrors,
    watch,
    values,
    ...otherForm,
  };
}
