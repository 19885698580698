import { NativeResult } from "../call";
import { BaseNativePlugin } from "./base";

export interface CallRecordsResult {
  name: string;
  telephoneNo: string;
  callType: number; // 1,接电话; 2,拨打电话 3,未接;
  callDate: string; //年月日 时分秒
  callDuration: number; // 秒数
}

export type CallRecordsPluginType = {
  getCallRecordsList(): Promise<NativeResult<CallRecordsResult[]>>
}

export class CallRecordsPlugin extends BaseNativePlugin implements CallRecordsPluginType {
  getCallRecordsList(): Promise<NativeResult<CallRecordsResult[]>> {
    return super.callPlugin<[CallRecordsResult]>(
      'CallRecordsPlugin',
      'getCallRecordsList',
    );
  }
}