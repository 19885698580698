import { History } from 'history';
import { useHistory } from 'react-router';

import Button from '../button';
import { BackOutline } from '../icons';

import './NavBackButton.scss';

export interface INavBackButtonProps {
  color?: 'dark' | 'light';
  icon?:
    | string
    | {
        ios: string;
        md: string;
      }
    | undefined;
  defaultHref?: string;
  text?: string;
  onClick?: (e: any) => void;
}

export function goBack(history: History<any>) {
  console.log(history.length);
  if (history.length > 1) {
    history.goBack();
  } else {
    history.replace('/');
  }
}

export function NavBackButton(props: INavBackButtonProps) {
  const history = useHistory();
  const { color = 'dark', icon, text, defaultHref, onClick } = props;
  const customProps = {} as any;
  // if (onClick) {
  //   customProps.onClick = onClick;
  // }
  customProps.onClick =
    onClick ||
    (async () => {
      goBack(history);
      // setTimeout(() => {
      //   console.log(window.location.pathname);
      // }, 200);
    });
  return (
    <Button
      //@ts-ignore
      id="qk-ion-back-button"
      // icon={icon || chevronBackOutline}
      text={text || ''}
      // color={color}
      defaultHref={defaultHref}
      className="qk-nav-back-button"
      {...customProps}
    >
      <BackOutline color={color === 'dark' ? '#121934' : '#fff'} />
    </Button>
  );
}
