import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { FieldErrorsImpl, DeepRequired, FieldValues } from 'react-hook-form';

import { LableItem } from '../../const/type';

import './index.scss';
export type RadioGroupProps = {
  errors?: FieldErrorsImpl<DeepRequired<FieldValues>>;
  label: string;
  value?: string | number;
  onChange?: (v: number | string) => void;
  data: LableItem[];
  valueKey?: string; //标识，默认id
};
export type RadioProps = {
  checked?: boolean;
  onChange?: () => void;
};

const classPrefix = 'radioGroup';

export const Radio = React.memo(({ checked }: RadioProps) => {
  return (
    <div className={classNames(`${classPrefix}-item`, checked && `${classPrefix}-item-checked`)}>
      <div />
    </div>
  );
});

Radio.displayName = 'Radio';

export const RadioGroup = React.forwardRef(
  (props: RadioGroupProps, ref: React.LegacyRef<HTMLDivElement>) => {
    const { onChange, value, data, valueKey = 'id', label } = props;
    function handleChange(value: string | number, e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
      e.stopPropagation();
      onChange && onChange(value);
    }

    return (
      <div className={`${classPrefix}-container`} ref={ref}>
        <span>{label}</span>
        <div className={`${classPrefix}-container-content`}>
          {data.map((item) => {
            return (
              <div
                key={item[valueKey]}
                className={`${classPrefix}-container-content-box`}
                onClick={(e) => handleChange(item[valueKey], e)}
              >
                <Radio checked={item[valueKey] == value} />
                <span>{item.name}</span>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
);
RadioGroup.displayName = 'RadioGroup';
