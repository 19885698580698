import { Http, HttpResponse } from '@quark-base-plugin/net';

import { useApplyStepModule } from '../step';

import { IdInfoProps, UseApplyStepIdProps } from './type';

//加载证件信息
async function onLoadId(): Promise<IdInfoProps> {
  const result = await Http.post('member/certificate-info/load-cert');
  return result?.data;
}

//保存证件信息
async function onSaveId(data: IdInfoProps): Promise<HttpResponse<IdInfoProps>> {
  return await Http.post('member/certificate-info/save-cert', data);
}

export function useApplyStepId({ defaultValues, ...others }: UseApplyStepIdProps) {
  const stepInfo = useApplyStepModule({
    code: 'useApplyStepId',
    defaultValues,
    onLoad: onLoadId,
    onSave: onSaveId,
    ...others,
  });

  return {
    ...stepInfo,
  };
}
