import { NativeResult } from '../call';
import { AppPermissionResult, AppPermissionState, BaseNativePlugin } from './base';

export type PhotoPluginType = {
  checkPermission(): Promise<NativeResult<AppPermissionResult>>;

  requestPermission(): Promise<
    NativeResult<AppPermissionResult>
  >;

  pickPhoto(options: {
    // 可以不传，传0～1之间, 是一个系统压缩后的质量的值
    qualit?: number;
  }): Promise<
    NativeResult<{
      img: string;
    }>
  >;

  getMediaInfos(options: {
    // (1.相册，2.视频, 3.音频)可不传，不传默认是相册
    type: number;
  }): Promise<NativeResult<any[]>>;
};

export class PhotoPlugin extends BaseNativePlugin implements PhotoPluginType {
  checkPermission(): Promise<NativeResult<AppPermissionResult>> {
    return super.callPlugin<AppPermissionResult>('PhotoPlugin', 'checkPermission');
  }
  requestPermission(): Promise<NativeResult<AppPermissionResult>> {
    return super.callPlugin<AppPermissionResult>(
      'PhotoPlugin',
      'requestPermission',
    );
  }
  pickPhoto(options?: {
    // 可以不传，传0～1之间, 是一个系统压缩后的质量的值
    qualit?: number | undefined;
  }): Promise<NativeResult<{ img: string; }>> {
    return super.callPlugin<{ img: string }>('PhotoPlugin', 'pickPhoto');
  }
  getMediaInfos(options: {
    // (1.相册，2.视频, 3.音频)可不传，不传默认是相册
    type: number;
  }): Promise<NativeResult<any[]>> {
    return super.callPlugin<Array<any>>(
      'PhotoPlugin',
      'getMediaInfos',
      options,
    );
  }

}