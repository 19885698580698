export default {
  noRepayment: 'No es necesario que realice un pago en este momento',
  repaymentSMS:
    'Recordatorios por mensaje de texto de la fecha de vencimiento y opción de pago anticipado',
  repaymentAmount: 'Monto de pago',
  borrowingDate: 'Plazo del préstamo',
  totalDuePeriod: 'Total a pagar este período',
  totalDuePeriod2: 'Factura total a pagar',
  principal: 'Principal',
  serviceFee: 'Cargo por servicio',
  interest: 'Interés',
  receivedAmount: 'Monto recibido',
  lateFee: 'Penalización por atraso',
  overdueDays: 'Número de días vencidos',
  orderNumber: 'Número de orden',
  penaltyInterest: 'Interés penalizado',
  overdue: 'Caducado',
  rollback: 'Revisar la reversión',
  finished: 'Completado',
  refuse: 'Rechazo de revisión',
  refuseExpire: 'Negarse a caducar',
  underReview: 'En revisión',
  success: 'Éxito',
  failure: 'Fallido',
  repaymentDate: 'Hora de pago',
  repayment: 'Pagar',
  payout: 'Préstamo en curso',
  paymentNow: 'Pagar ahora',
  latestPaymentDate: 'Última fecha de pago',
  repaymentMethod: 'Método de pago',
  repaymentTips: 'El monto del pago no debe ser inferior a 100',
  copySuc: 'Copiado',
  repayInfo1: 'Importe total a pagar',
  details: 'Detalles',
  loanDetails: 'Loan details >',
  loanInformation: 'Información del préstamo',
  duePeriod: 'Vence durante este período',
  repaymentPlan: 'Plan de pago',
  viewMore: 'Ver más',
  putitAway: 'Guardar',
  repayDetailInfo1:
    'Su factura está vencida. Las facturas vencidas generarán cargos adicionales. Por favor, devuélvelo con prontitud.',
  repayDetailInfo2: 'Después del pago, podrá disfrutar de másservicios de préstamo.',
  repayDetailInfo3:
    'Su factura ha caducado. Las facturas vencidas generarán cargos adicionales, pague lo antes posible',
  addRepay1: 'Detalles',
  addRepay2: 'Monto del préstamo',
  addRepay3: 'Plazo de préstamo',
  addRepay4: 'Período de préstamo',
  addRepay5: 'Importe total a pagar en este período',
  addRepay6: 'Tarifa de Extensión',
  addRepay7: 'Período',
  addRepay8: 'Semanas / Período',
  addRepay9: 'Este período/período total'
};
