import { IonItem } from '@ionic/react';
import clsx from 'clsx';
import { chevronForwardOutline } from 'ionicons/icons';
import { CSSProperties, MouseEventHandler } from 'react';

import './Item.scss';

export type ItemProps = {
  disabled?: boolean;
  detail?: boolean;
  detailIcon?: string;
  onClick?: MouseEventHandler<any> | undefined;
  children: any;
  size?: 'normal' | 'large' | 'small';

  background?: string;
  /**
   * 左右 padding
   */
  hPadding?: 'none' | 'small' | 'normal';

  align?: 'center' | 'end';

  border?: boolean;

  borderRadius?: string;
};

export function Item(props: ItemProps) {
  const {
    children,
    detail,
    background,
    size = 'normal',
    border = true,
    hPadding = 'none',
    align = 'center',
    detailIcon,
    onClick,
    disabled,
    borderRadius,
  } = props;
  const styles = {
    '--ion-item-background': background || 'white',
    '--border-color': border ? '#E1E4EA' : 'transparent',
    '--inner-border-width': border ? '0px 0px 1px 0px' : '0px',
    '--border-radius': borderRadius ? borderRadius : '0px',
    // '--padding-start': 0px;
    // '--padding-end': 0px;
    alignItems: align,
  } as CSSProperties;
  return (
    <IonItem
      disabled={disabled}
      detail={detail}
      detailIcon={detailIcon || chevronForwardOutline}
      onClick={onClick}
      className={clsx('qk-item', `qk-item-size-${size}`, `qk-item-h-${hPadding}`)}
      style={styles}
    >
      {children}
    </IonItem>
  );
}
