import { useLocalStorageState } from 'ahooks';

export const languages = [
  {
    language: 'en',
    code: 'en-US',
    label: 'English',
    shortName: 'EN',
  },
  {
    language: 'pe',
    code: 'es-PE',
    label: 'España',
    shortName: 'ES',
  },
];

export function useStoredLanguage(defaultValue?: string) {
  const [storedLannguage, setStoredLannguage] = useLocalStorageState<string>('StoredLannguage', {
    defaultValue,
  });
  return { storedLannguage, setStoredLannguage };
}
