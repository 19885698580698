let PluginHelper = {
	callPlugin : (pluginName, method, params, callbackMethod) => {
		if (window.native && window.native[pluginName] && typeof(window.native[pluginName][method]) === 'function') {
			return new Promise((resolve, rejct) => {
				window.native[pluginName][method](params).then(r=>{
					if (r.code == 0) {
						resolve(r.data)
					} else {
						rejct(r)
					}
				})
			})
		} else if (window.cici_android) {
			let uid = 1
			let data = {pluginName,uid,method,params,callback:callbackMethod }
			return new Promise((resolve,rejct) => {
				console.log('call ', JSON.stringify(data))
				window.cici_android.call(JSON.stringify(data))
				window[callbackMethod] = (da)=>{
					if (da.success === true) {
						resolve(da)
					} else {
						rejct(da)
					}
				}
			})
		} else if(window.webkit?.messageHandlers.call){
			let uid = 1
			let data = {pluginName,uid,method,params,callback:callbackMethod}
			return new Promise((resolve, rejct) => {
				console.log('call ', JSON.stringify(data))
				window.webkit?.messageHandlers.call.postMessage(JSON.stringify(data))
				window[callbackMethod] = (da)=>{
					if (da.success === true) {
						resolve(da)
					} else {
						rejct(da)
					}
				}
			})
		}else {
			return new Promise((resolve, rejct) => {
				rejct({code : -10, message : 'pugin or method nor found'})
			})
		}
	},
	getValue : (data, key) => {
		console.log('data is ', data, '\n key is ', key)
		const r = data[key]
		console.log('r is ',r)
		return r
	},
	registerHandler() {
		window.WebViewJavascriptBridge.registerHandler("AppAction", (pa, callBack)=>{
			console.log('pa is ', pa)
			console.log('callBack is ', callBack)
			callBack({"callbackData" : "123"})
		})
	},
};

export default PluginHelper;