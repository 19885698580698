import { useMutation, UseMutateAsyncFunction } from '@tanstack/react-query';
import React from 'react';
// import { useUserContext } from '../user';

export interface AuthContextValue<
  UserType = unknown,
  // ErrorType = unknown,
  LoginCredentialsType = unknown,
  RegisterCredentialsType = unknown,
  RequestVerifyCodeParamType = unknown
> {
  // user: UserType | undefined;
  requestVerifyCode: UseMutateAsyncFunction<any, any, RequestVerifyCodeParamType>;
  login: UseMutateAsyncFunction<UserType, any, LoginCredentialsType>;
  loginByPwd: UseMutateAsyncFunction<UserType, any, any>;
  logout: UseMutateAsyncFunction<any, any, void, any>;
  register: UseMutateAsyncFunction<UserType, any, RegisterCredentialsType>;
  isLoggingIn: boolean;
  isLoggingOut: boolean;
  isRegistering: boolean;
  isRequestingCode: boolean;
  // refetchUser: (
  //   options?: RefetchOptions | undefined
  // ) => Promise<QueryObserverResult<UserType, ErrorType>>;
  // error: Error | null;
}

export interface AuthProviderConfig<UserType = unknown> {
  key?: string;
  // loadUser: (data: any) => Promise<UserType>;
  requestVerifyCodeFunc: (data: any) => Promise<any>;
  loginFunc: (data: any) => Promise<UserType>;
  loginByPwdFunc: (data: any) => Promise<UserType>;
  registerFunc: (data: any) => Promise<UserType>;
  logoutFunc: () => Promise<any>;
  LoadingComponent?: () => JSX.Element;
  ErrorComponent?: ({ error }: { error: Error | null }) => JSX.Element;
}

export interface AuthProviderProps {
  children: React.ReactNode;
}

export interface UserContextConfig<UserType = unknown> {
  key?: string;
  loadUser: (data: any) => Promise<UserType>;
}

export interface UserContextValue<UserType = unknown> {
  user: UserType | undefined;
}

// export function initUserContext<UserType = unknown>(config: UserContextConfig<UserType>) {
//   const { key, loadUser } = config;

//   return { useUserContext };
// }

export function initReactQueryAuth<
  UserType = unknown,
  // ErrorType = unknown,
  LoginCredentialsType = unknown,
  RegisterCredentialsType = unknown,
  RequestVerifyCodeParamType = unknown
>(config: AuthProviderConfig<UserType>) {
  const AuthContext = React.createContext<AuthContextValue<
    UserType,
    // ErrorType,
    LoginCredentialsType,
    RegisterCredentialsType,
    RequestVerifyCodeParamType
  > | null>(null);
  AuthContext.displayName = 'AuthContext';

  const {
    // key = 'auth-user',
    // loadUser,
    requestVerifyCodeFunc,
    loginFunc,
    loginByPwdFunc,
    registerFunc,
    // logoutFunc,
    // LoadingComponent = () => <div>Loading...</div>,
    // ErrorComponent = (error: any) => (
    //   <div style={{ color: 'red' }}>{JSON.stringify(error, null, 2)}</div>
    // ),
  } = config;

  function AuthProvider({ children }: AuthProviderProps): JSX.Element {
    // const {
    //   user,
    //   error,
    //   status,
    //   isLoading,
    //   isSuccess,
    //   refetchUser: refetch,
    //   setUser,
    //   clearUser
    // } = useUserContext();

    // const {
    //   data: user,
    //   error,
    //   status,
    //   isLoading,
    //   // isIdle,
    //   isSuccess,
    //   refetch,
    // } = useQuery<UserType, Error>({
    //   queryKey: [key],
    //   queryFn: loadUser,
    // });

    // const queryClient = useQueryClient();

    // const setUser = React.useCallback(
    //   (data: UserType) => queryClient.setQueryData([key], data),
    //   [queryClient]
    // );

    const requestVerifyCodeMutation = useMutation({
      mutationFn: requestVerifyCodeFunc,
      onSuccess: () => {},
    });

    const loginMutation = useMutation({
      mutationFn: loginFunc,
      // onSuccess: (user: any) => {
      // setUser(user);
      // },
    });

    const loginByPwdMutation = useMutation({
      mutationFn: loginByPwdFunc,
      // onSuccess: (user: any) => {
      //   setUser(user);
      // },
    });

    const registerMutation = useMutation({
      mutationFn: registerFunc,
      // onSuccess: (user: any) => {
      //   setUser(user);
      // },
    });

    // const logoutMutation = useMutation({
    //   mutationFn: logoutFunc,
    //   onSuccess: () => {
    //     clearUser();
    //   },
    // });

    const value = React.useMemo(() => {
      return {
        // user,
        requestVerifyCode: requestVerifyCodeMutation.mutateAsync,
        login: loginMutation.mutateAsync,
        loginByPwd: loginByPwdMutation.mutateAsync,
        // logout: logoutMutation.mutateAsync,
        register: registerMutation.mutateAsync,
        isLoggingIn: loginMutation.isLoading || loginByPwdMutation.isLoading,
        // isLoggingOut: logoutMutation.isLoading,
        isRegistering: registerMutation.isLoading,
        isRequestingCode: requestVerifyCodeMutation.isLoading,
        // refetchUser: refetch,
        // error,
      } as AuthContextValue<
        UserType,
        // ErrorType,
        LoginCredentialsType,
        RegisterCredentialsType,
        RequestVerifyCodeParamType
      >;
    }, [
      // user,
      requestVerifyCodeMutation.mutateAsync,
      loginMutation.mutateAsync,
      loginByPwdMutation.mutateAsync,
      // logoutMutation.mutateAsync,
      loginByPwdMutation.isLoading,
      registerMutation.mutateAsync,
      loginMutation.isLoading,
      // logoutMutation.isLoading,
      registerMutation.isLoading,
      requestVerifyCodeMutation.isLoading,
      // refetch,
      // error,
    ]);

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;

    // if (isLoading) {
    //   return <LoadingComponent />;
    // }

    // if (error) {
    //   return <ErrorComponent error={error} />;
    // }

    // if (isSuccess) {

    // }

    // return <div>Unhandled status: {status}</div>;
  }

  function useAuth() {
    const context = React.useContext(AuthContext);
    if (!context) {
      throw new Error(`useAuth must be used within an AuthProvider`);
    }
    return context;
  }

  return { AuthProvider, useAuth };
}
