import home from './es-PE/home';
import me from './es-PE/me';
import info from './es-PE/info';
import common from './es-PE/common';
import login from './es-PE/login';
import reapay from './es-PE/repay';
import apply from './es-PE/apply';
import extension from './es-PE/extension';
export default {
  ...home,
  ...info,
  ...me,
  ...common,
  ...login,
  ...reapay,
  ...apply,
  ...extension
};
