import { NativeResult } from '../call';
import { AppPermissionResult, AppPermissionState, BaseNativePlugin } from './base';

export type ContactPerson = {
  name: string;
  phoneNo: string;
};

// 用在抓取联系人数据
export type ContactPersonDetail = {
  name: string;
  telephoneNo: string;
  originalTelephoneNo: string;
  nickname?: string;
  note?: string;
  organization?: string;
  dataAdded?: string;
  dateModified?: string;
  job?: string;
  department?: string;
  birthdayList?: { label: string; value: string }[];
  phoneList?: { label: string; value: string }[];
  emailList?: { label: string; value: string }[];
  addresseList?: { label: string; value: string }[];
  messageList?: { label: string; value: string }[];
  socialList?: { label: string; value: string }[];
  relationList?: { label: string; value: string }[];
  urlList?: { label: string; value: string }[];
};

export type ContactPluginType = {
  selectAPerson(): Promise<NativeResult<ContactPerson>>;

  checkPermission(): Promise<NativeResult<AppPermissionResult>>;

  getContactInfo(): Promise<NativeResult<ContactPersonDetail[]>>;

  requestPermission(): Promise<
    NativeResult<AppPermissionResult>
  >;
};

export class ContactPlugin extends BaseNativePlugin implements ContactPluginType {
  selectAPerson(): Promise<NativeResult<ContactPerson>> {
    return super.callPlugin<ContactPerson>('ContactPlugin', 'selectAPerson');
  }
  checkPermission(): Promise<NativeResult<AppPermissionResult>> {
    return super.callPlugin<AppPermissionResult>(
      'ContactPlugin',
      'checkPermission',
    );
  }
  getContactInfo(): Promise<NativeResult<ContactPersonDetail[]>> {
    return super.callPlugin<ContactPersonDetail[]>('ContactPlugin', 'getContactInfo');
  }
  requestPermission(): Promise<NativeResult<AppPermissionResult>> {
    return super.callPlugin<AppPermissionResult>(
      'ContactPlugin',
      'requestPermission',
    );
  }

}