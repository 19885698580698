import { AnalysisEvent, AnalysisProvider } from "./analysis";
import { Http } from '@quark-base-plugin/net';

async function log(event: AnalysisEvent): Promise<boolean> {
  return logEvents([event]);
}
async function logEvents(events: AnalysisEvent[]): Promise<boolean> {
  const data = events?.map(e => {
    const event = {
      ...e.params, actionCode: e.event,
    } as any;
    if (!event.actionTime) {
      event.actionTime = Date.now();
    }
    return event;
  })
  const result = await Http.post('/buried-point/save', data, { shouldToastOnError: false });
  return result?.success;
}

export const QKAnalysisProvider: AnalysisProvider = {
  init: () => { },
  log: log,
  logEvents: logEvents
}