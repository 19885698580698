import { closeFnSet } from './show';

export function clear() {
  closeFnSet.forEach((close) => {
    close();
  });
  closeFnSet.clear();
}

export function isShowed() {
  return closeFnSet.size > 0;
}
