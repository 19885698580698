import { NativeResult } from '../call';
import { BaseNativePlugin } from './base';

export type AppInfoResult = {
  platform: number;
  isDebug: boolean;
  version: string;
  whiteBarHeight: number;
  statusBarHeight: number;
  appName: string;
  appId: number;
  channelType: string;
};

export type DeviceAppParams = {
  appName: string;
  firstInstallTime: number;
  lastUpdateTime: number;
  packageName: string;
  systemApp: boolean;
};

export type DeviceResult = {
  batterypercent: number;
  ramTotalSpace: number;
  emulator: boolean;
  systemVersion: string;
  mobilePhoneBrand: string;
  mobilePhoneModel: string;
  bootTime: number;
  timezoneId: string;
  charging: boolean;
  languageName: string;
  romTotalSpace: number;
  screenResolution: string;
  romFreeSpace: number;
  deviceId: string;
  root: boolean;
  screenScale: number;
  routerMac: string;
  screenSize: number;
  wifiName: string;
  recentDatetime: string;
  manufacturer: string;
  screenLuminance: number;
  ramFreeSpace: number;
  romUsedSpace: number;
  cpuCount: number;
  network: string;
} & Record<string, any>;

export type AppPluginType = {
  getEquipmentInfo(): Promise<NativeResult<DeviceResult>>;
  getLanguages(): Promise<NativeResult<string[]>>;
  openUrl(options: { url: string; inApp: boolean; title?: string; async?: boolean }): Promise<void>;
  shutdown(): Promise<void>;
  callUp(options: { phone?: string }): Promise<void>;
  getFcmKey(options?: { phone?: string }): Promise<NativeResult<string>>;
  aboutApp(): Promise<NativeResult<AppInfoResult>>;
  getApplist(): Promise<NativeResult<DeviceAppParams[]>>;
  log(): Promise<void>;
  isLogin(options: { isLogin?: boolean }): Promise<void>;
};

export class AppPlugin extends BaseNativePlugin implements AppPluginType {
  getEquipmentInfo(): Promise<NativeResult<DeviceResult>> {
    return super.callPlugin<DeviceResult>('AppPlugin', 'getEquipmentInfo', {}, () => {
      console.log('noapp');
    });
  }
  getLanguages(): Promise<NativeResult<string[]>> {
    return super.callPlugin<string[]>('AppPlugin', 'getLanguages');
  }
  // @ts-ignore
  openUrl(options: { url: string; inApp: boolean; title?: string | undefined; async?: boolean | undefined; }): Promise<void> {
    super.callPlugin('AppPlugin', 'openUrl', options);
  }
  // @ts-ignore
  shutdown(): Promise<void> {
    super.callPlugin('AppPlugin', 'shutdown');
  }
  // @ts-ignore
  callUp(options: { phone?: string | undefined }): Promise<void> {
    super.callPlugin('AppPlugin', 'callUp', options);
  }
  getFcmKey(options?: { phone?: string | undefined } | undefined): Promise<NativeResult<string>> {
    return super.callPlugin<string>('AppPlugin', 'getFcmKey', options);
  }
  aboutApp(): Promise<NativeResult<AppInfoResult>> {
    return super.callPlugin<AppInfoResult>('AppPlugin', 'aboutApp',{}, () => {
      console.log('noapp');
    });
  }
  getApplist(): Promise<NativeResult<DeviceAppParams[]>> {
    return super.callPlugin<DeviceAppParams[]>('AppPlugin', 'getApplist');
  }
  log(options?: { data?: string }): Promise<void> {
    super.callPlugin<DeviceAppParams[]>('AppPlugin', 'log', options, (d: any) => {
      console.log(d?.data);
    });
    return Promise.resolve();
  }
  isLogin(options?: { isLogin?: boolean }): Promise<void> {
    super.callPlugin('AppPlugin', 'isLogin', options, (d: any) => {
      console.log(d);
    });
    return Promise.resolve();
  }
  sysUIColor(options?: {
    isStatusBarDark?: boolean;
    isNavigationBarDark?: boolean;
    navigaitonBarColor?: boolean;
  }): Promise<void> {
    super.callPlugin('AppPlugin', 'sysUIColor', options, (d: any) => {
      console.log(d);
    });
    return Promise.resolve();
  }
}
