import { Analysis as AnalysisCls } from "./analysis";
import { QKAnalysisProvider } from "./provider";
import { AnalysisWrapper } from "./AnalysisWrapper";

export { AnalysisThirdEventName } from "./analysis";

const Analysis = new AnalysisCls();
Analysis.register([QKAnalysisProvider]);

export {
  Analysis,
  AnalysisWrapper
};