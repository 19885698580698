export default {
  home: 'Home',
  repay: 'Repay',
  me: 'My',
  msg: 'Message',
  commonCancel: 'Cancelar',
  commonConfirm: 'Aceptar',
  principalAmountAndOtherFees: 'Capital {{principal}} + Interés {{fees}}',
  'format.invalid': '{{label}} Formato no válido',
  selectRegion: 'Región',
  selecting: 'Seleccione',
  contactExitModalTitle: 'Aviso',
  contactExitModalDesc:
    'La cuenta bancaria receptora debe estar a su nombre. De lo contrario, cualquier pérdida correrá por su cuenta.',
  iKnow: 'Entendido',
  lengthLimit: 'Sólo se pueden introducir 20 dígitos',
  search: 'Buscar',
  OK: 'Aceptar',
  passwordNotTally: 'La contraseña no coincide',
  save: 'Guardar',
  signOutTips: 'Sólo nos queda un paso, ¿seguro que se quiere retirar?',
  InLending: 'en proceso',
  loanSucceeded: 'exitoso',
  loanCancel: 'cancelado',
  payableAmount: 'Saldo a pagar',
  noAllowRepeatNumber: 'Número de celular duplicado',
  homeApplyTime: 'Fecha del préstamo',
  loanReview: 'Aprobación de préstamos',
  uploadBasicInfo: 'Subir información',
  smartAudit: 'Revisión inteligente',
  closingDate: 'Fecha límite',
  ocrTips1: 'Por favor suministre una foto del documento correspondiente',
  ocrTips2: 'No se ha encontrado información válida, por favor inténtelo de nuevo',
  exitTitle: 'Confirmar salida COCO CREDITO',
  // comparisonFailed: 'La certificación no coincide con la comparación, por favor vuelva a subir su foto de identificación.',
  rightCorner: 'Por favor, descargue la aplicación de nuevo y acepte la licencia.',
  reOpen: 'Por favor, vuelva a abrir la aplicación y haga clic en Aceptar para autorizar',
  tryAgain: 'intentar otra vez',
  systemError: 'Error del sistema, por favor, inténtalo de nuevo～',
  networkError: 'Error de red, inténtalo de nuevo～',
  smsTips:
    'Solo recopilamos SMS relacionados con la evaluación del crédito. No se supervisan, leen, almacenan ni comparten datos personales de SMS. Lo anterior nos permite identificar al usuario adecuadamente y verificar si califica como persona a ser financiada por nosotros.',
  restartApp:
    'Para garantizar la seguridad de sus fondos, necesitamos su consentimiento para acceder a los permisos correspondientes. Por favor, descargue de nuevo la aplicación y acepte la autorización.',
  noAllowFillMinePhone: 'No se permite completar con el número de teléfono actual del usuario',
  homeTips:
    'Debemos recolectar todos los mensajes de texto SMS, pero únicamente procesaremos aquellos mensajes SMS de carácter financiero que sean relevantes. Por ejemplo, consideraremos los mensajes financieros enviados por bancos y entidades crediticias, evaluando su origen para verificar que provengan de instituciones bancarias habituales. Específicamente, requerimos reunir información sobre préstamos, reembolsos y detalles de transacciones contenidos en los mensajes financieros, con el propósito de evaluar su deuda actual y su historial de pagos. Esto nos permitirá generar una calificación crediticia precisa y tomar decisiones sobre la concesión de préstamos basadas en su calificación crediticia, todo ello con el fin de prevenir posibles fraudes. Solo en caso de que usted haga clic en "Aceptar" procederemos a recopilar el contenido de los SMS. En ningún momento compartiremos esta información con terceros.',
  logoffTips:
    'Hemos recibido su solicitud de cierre de cuenta. Nos pondremos en contacto con usted más adelante para verificar su información y cerrar su cuenta.',
  gradeTitle: 'Su puntuación de crédito de riesgo',
  gradeTitleText: 'Esta puntuación puede diferir de otras puntuaciones de la oficina.',
  grade: 'La puntuacion',
  gradeDesc:
    'La puntuación de riesgo crediticio se obtiene después de llevar a cabo una evaluación de riesgo de crédito utilizando los datos proporcionados por usted y nuestros propios análisis de riesgo de la plataforma.',
  gradeBtn: '¡Comprendido!',
  fatherSurname: 'Padre',
  motherSurname: 'Apellido Materno',
  surname: 'apellido',
  pleaseSelect: 'Por favor elija',
  details: 'Detalles',
  basicPleaseEnter: 'Por favor, introduzca',
  pleaseEntergender: 'Por favor, elija el sexo',
  productIntroduction: 'Introducción del producto',
  idFormatError: 'Error de formato de número de identificación',
  checkFalse: 'En proceso de préstamo, por favor espere pacientemente.',
  basicTipDesc:
    'Toda la información debe ser verídica y válida; datos falsos pueden rechazar la solicitud.',
  basicTips1: '1. El número de DNI debe contener 8 dígitos y ser composado únicamente por números.',
  basicTips2:
    '2. Nombre debe coincidir con el nombre de la cuenta bancaria para recibir el préstamo.',
  withdrawTips:
    'Asegúrese de completar la información de la tarjeta bancaria real y válida; de lo contrario, el préstamo no se emitirá a su cuenta',
  livingBody: 'Reconocimiento facial',
};
