import { useMemoizedFn } from "ahooks";
import React from "react";
import { Analysis } from ".";
import { AnalysisProps } from "./react_augmented";
import { recursiveMap } from "./recursiveMap";

export type AnalysisEventProps = {
  pageCode?: string;
  actionCode: string;
  actionTime?: number;
  content?: string;
};

const getTime = () => {
  return Date.now();
};

type AnalysisWrapperProps = {
  pageCode?: string;
  children?: any;
};

function logEvent(analysis: AnalysisProps, content?: any) {
  const event = {
    pageCode: analysis.pageCode,
    actionCode: analysis.actionCode,
    actionTime: getTime(),
    content:
      content != undefined && content != null ? content : analysis.content,
  };
  Analysis.add({
    event: analysis.actionCode,
    params: { ...event },
  });
  // console.log("log event: ", analysis.actionCode);
  // console.log(event);
}

function propsWithTrackEvents(props: any, pageCode?: string) {
  let p: any = {};
  const analysis: AnalysisProps = props.analysis;
  const analysisType = analysis.analysisType || [];
  if (!analysis.pageCode) {
    analysis.pageCode = pageCode;
  }

  if (analysisType.includes("onClick")) {
    const reactClick = props.onClick;
    p.onClick = (e: any) => {
      // 按钮点击，不带网络请求，传入pageCode、actionCode、actionTime， content(传入1)
      logEvent(
        {
          ...analysis,
          actionCode: `${analysis.actionCode}:Touch`,
        },
        "1"
      );
      reactClick && reactClick(e);
    };
  }

  if (analysisType.includes("onAfterClick")) {
    const reactClick = props.onAfterClick;
    p.onAfterClick = (e: any) => {
      //按钮点击，带网络请求, 或者单选框
      logEvent(
        {
          ...analysis,
          actionCode: `${analysis.actionCode}:Touch`,
        },
        Object.prototype.toString.call(e) === "[object Object]"
          ? e.target.value
          : e
      );
      reactClick && reactClick(e);
    };
  }

  if (analysisType.includes("onFocus")) {
    const reactFocus = props.onFocus;
    p.onFocus = (e: any) => {
      const content = e && typeof e === "string" ? e : e?.target?.value;
      logEvent(
        {
          ...analysis,
          actionCode: `${analysis.actionCode}:EditBegin`,
        },
        content
      );
      reactFocus && reactFocus(e);
    };
  }

  if (analysisType.includes("onBlur")) {
    const reactBlur = props.onBlur;
    p.onBlur = (e: any) => {
      const content = e && typeof e === "string" ? e : e?.target?.value;
      logEvent(
        {
          ...analysis,
          actionCode: `${analysis.actionCode}:EditEnd`,
        },
        content
      );
      reactBlur && reactBlur(e);
    };
  }

  if (analysisType.includes("onChange")) {
    const reactChange = props.onChange;
    p.onChange = (e: any, ...args: any[]) => {
      let content = e;
      if (Object.prototype.toString.call(e) === "[object Object]") {
        content =
          e.code || e.attachId || e.id || e.name || e.target?.value || "1";
      }
      if (content && Array.isArray(content)) {
        content = content
          .map((val) => val.id || val.code || val.attachId || "1 ")
          ?.toString();
      }
      logEvent(
        {
          ...analysis,
          actionCode: `${analysis.actionCode}:Changed`,
        },
        content
      );
      reactChange && reactChange(e, ...args);
    };
  }
  return p;
}

//@ts-ignore
export const AnalysisWrapper: React.FC<AnalysisWrapperProps> = (
  props: AnalysisWrapperProps
) => {
  const { pageCode, children } = props;

  const propsWithTrackEventsFn = useMemoizedFn(propsWithTrackEvents);

  const fn = (el: any) => {
    let props: any = {};

    //@ts-ignore
    if (el.props && el.props.analysis) {
      props = propsWithTrackEventsFn(el.props || {}, pageCode);
    }
    return React.cloneElement(el, {
      ...el.props,
      ...props,
      key: undefined,
    });
  };

  return recursiveMap(children, fn);
};
