import { NativeResult } from '../call';
import { AppPermissionResult, BaseNativePlugin } from './base';

export interface SmsResult {
  name: string;
  body: string; // 内容
  telephoneNo: string;
  sendDate: 0; //
  didRead: boolean;
  type: string; // 'RECEIVED'; // 类型
}

export type SmsPluginType = {
  getSMSList(options?: { count?: number; days?: number }): Promise<NativeResult<SmsResult[]>>;

  checkPermission(): Promise<NativeResult<AppPermissionResult>>;

  requestPermission(): Promise<NativeResult<AppPermissionResult>>;
};

export class SmsPlugin extends BaseNativePlugin implements SmsPluginType {
  getSMSList(options?: { count?: number; days?: number }): Promise<NativeResult<SmsResult[]>> {
    return super.callPlugin<[SmsResult]>('SMSPlugin', 'getSMSList', options);
  }
  checkPermission(): Promise<NativeResult<AppPermissionResult>> {
    return super.callPlugin<AppPermissionResult>('SMSPlugin', 'checkPermission');
  }
  requestPermission(): Promise<NativeResult<AppPermissionResult>> {
    return super.callPlugin<AppPermissionResult>('SMSPlugin', 'requestPermission', {}, () => {
      return {};
    });
  }
}
