import NativeCall, { NativeResult } from "../call";

/**
  未请求过授权 = 0
  受限制的权限 = 1
  拒绝 = 2
  同意 = 3 （完全访问）
  有限制的访问 = 4 （有限制的访问）
 */
export enum AppPermissionState {
  notDetermined = 0,
  restricted = 1,
  denied = 2,
  authorized = 3,
  limitedAuthorized = 4,
}

export interface AppPermissionResult {
  permissionOk: boolean; //(true: 有权限, flase：拒绝权限)
  jumpUrl?: string; //(可空，false 会有，该url是跳转app设置的链接,配合openUrl使用)
  state: AppPermissionState; //int （原生权限枚举值）
}

export class BaseNativePlugin {
  /**
   * 
   * @param pluginName 插件名
   * @param method 方法
   * @param options 参数
   * @param webImplementation 非原生Hybird应用时，针对 web 的实现
   * @returns 
   */
  callPlugin<T>(
    pluginName: string,
    method: string,
    options?: any,
    webImplementation?: any,
  ): Promise<NativeResult<T>> {
    if (NativeCall.isNative()) {
      return NativeCall.callPlugin<T>(pluginName, method, options);
    } else if (webImplementation && typeof webImplementation === 'function') {
      return webImplementation(options);
    } else {
      console.warn(`Plugin: ${pluginName} not implemented Method: ${method}`);
      throw new Error('Function not implemented.');
    }
  }
}