import { IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonLabel } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Redirect, useLocation } from 'react-router';
import { useUserContext } from '@quark-base-plugin/modules/app/user';

import './Tabs.scss';

import tab_home_n from '@/assets/tabbar/icon-tab-home-n.png';
import tab_home_s from '@/assets/tabbar/icon-tab-home-s.png';
import tab_me_n from '@/assets/tabbar/icon-tab-me-n.png';
import tab_me_s from '@/assets/tabbar/icon-tab-me-s.png';

import Routes, { RoutePage } from './Routes';
import { t } from 'i18next';

const Home = React.lazy(() => import(`@/pages/home`));
const Me = React.lazy(() => import(`@/pages/me`));
const Repay = React.lazy(() => import(`@/pages/repay`));
const Msg = React.lazy(() => import(`@/pages/msg`));

export type TabItem = {
  /**
   * 显示的名称
   */
  name: string;
  /**
   * 未选择的样式图标
   */
  unselectedIcon: string;
  /**
   * 选择的样式图标
   */
  selectedIcon: string;
  /**
   * 路由
   */
  route: RoutePage;
};

const tabs: TabItem[] = [
  {
    name: 'homeTab',
    unselectedIcon: tab_home_n,
    selectedIcon: tab_home_s,
    route: {
      path: '/app/home',
      component: Home,
      exact: true,
      protected: false,
    },
  },
  // {
  //   name: 'repayTab',
  //   unselectedIcon: tab_record_n,
  //   selectedIcon: tab_record_s,
  //   route: {
  //     path: '/app/repay',
  //     component: Repay,
  //     exact: true,
  //     protected: false,
  //   },
  // },
  // {
  //   name: 'messageTab',
  //   unselectedIcon: tab_msg_n,
  //   selectedIcon: tab_msg_s,
  //   route: {
  //     path: '/app/msg',
  //     component: Msg,
  //     exact: true,
  //     protected: false,
  //   },
  // },
  {
    name: 'myTab',
    unselectedIcon: tab_me_n,
    selectedIcon: tab_me_s,
    route: {
      path: '/app/me',
      component: Me,
      exact: true,
      protected: false,
    },
  },
];

export type TabsProps = {
  routes?: RoutePage[];
};

const Tabs: React.FC<TabsProps> = (props) => {
  const { routes } = props;
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <IonTabs>
      {/* @ts-ignore */}
      <IonRouterOutlet>
        <Routes routes={routes} />
        {tabs.map((tab, i) => {
          return (
            <Route
              exact={tab.route.exact}
              path={tab.route.path}
              key={i}
              //@ts-ignore
              component={tab.route.component}
            />
          );
        })}
        <Route exact path="/app">
          <Redirect to="/app/home" />
        </Route>
        <Route exact path="/">
          <Redirect to={'/app'} />
        </Route>
        {/* <Route render={() => <ErrorBlock fullPage description={'404'} />} /> */}
      </IonRouterOutlet>
      {/* @ts-ignore */}
      <IonTabBar
        slot="bottom"
        className={
          !location.pathname.startsWith('/app/') || location.pathname == '/app/repay'
            ? 'tab-hidden'
            : 'tab-show'
        }
        onIonTabsDidChange={({ detail }) => {}}
        style={{
          backgroundColor: 'rgb(255, 255, 255)',
        }}
      >
        {tabs.map((tab, i) => (
          <IonTabButton tab={tab.route.path} href={tab.route.path} key={i} className="tab">
            <img
              src={location.pathname === tab.route.path ? tab.selectedIcon : tab.unselectedIcon}
              alt=""
            />
            {/* @ts-ignore */}
            <IonLabel style={{ marginTop: '5px' }}>{t(tab.name)}</IonLabel>
          </IonTabButton>
        ))}
      </IonTabBar>
    </IonTabs>
  );
};
export default Tabs;
