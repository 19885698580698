import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react';
import { Analysis, AnalysisWrapper } from '@quark-base-plugin/analysis';
import { useThrottleFn } from 'ahooks';
import React, { useEffect } from 'react';
import './BasePage.scss';
import { useLocation } from 'react-router';

import { NavBar, NavBarProps } from '../navbar/NavBar';
import { Mask } from '../popup/mask';
import { Spinner } from '../spinner';

export interface BaseContentProps {
  background?: string;
  contentBackground?: string;
  children?: any;
}

export const BaseContent: React.FC<BaseContentProps> = ({
  background,
  contentBackground,
  children,
}) => {
  return (
    <IonContent fullscreen style={{ '--background': background }} className="qk-content-keyboard">
      <div
        className="qk-page-background"
        style={{ background: contentBackground || 'transparent' }}
      ></div>
      {children}
      {/* <div className="qk-content"></div> */}
    </IonContent>
  );
};

export interface BasePageProps {
  /**
   * 埋点code
   */
  code?: string;
  /**
   * 是否启用埋点, 默认 true
   */
  enableAnalysis?: boolean;

  children?: React.ReactNode;

  /**
   * 页面标题
   */
  title?: string;
  /**
   * 页面背景
   */
  background?: string;
  /**
   * 内容背景
   */
  contentBackground?: string;
  /**
   * 是否需要导航栏，默认true
   */
  hasNav?: boolean;
  /**
   * 顶部固定元素
   */
  fixed?: React.ReactElement;
  /**
   * 底部固定元素
   */
  bottomfixed?: React.ReactElement;
  /**
   * 自定义导航栏
   */
  navProps?: NavBarProps;
  /**
   * 加载
   */
  isLoading?: boolean;

  viewDidEnter?: () => void;
  viewDidLeave?: () => void;
  viewWillEnter?: () => void;
  viewWillLeave?: () => void;
}

export const BasePage: React.FC<BasePageProps> = (props: BasePageProps) => {
  const {
    code,
    enableAnalysis = true,
    title,
    background,
    contentBackground,
    hasNav = true,
    navProps,
    fixed,
    bottomfixed,
    isLoading = false,
    children,
  } = props;

  const location = useLocation();
  const eventCode = code || location.pathname;

  useEffect(() => {
    // console.log('page ' + eventCode + 'enter complete');
    return () => {
      // console.log('page ' + eventCode + 'leave complete');
    };
  }, []);

  const viewdidEnter = useThrottleFn(
    () => {
      props.viewDidEnter?.();

      // Analysis.add({
      //   event: `${eventCode}:Enter`,
      // });
    },
    { wait: 80, trailing: false }
  );

  const viewdidLeave = useThrottleFn(
    () => {
      props.viewDidLeave?.();

      // Analysis.add({
      //   event: `${eventCode}:Leave`,
      // });
    },
    { wait: 0 }
  );

  useIonViewDidEnter(() => {
    viewdidEnter.run();
  }, [eventCode]);

  useIonViewDidLeave(() => {
    viewdidLeave.run();
  });

  useIonViewWillEnter(() => {
    props.viewWillEnter?.();
  });

  useIonViewWillLeave(() => {
    props.viewWillLeave?.();
  });

  return (
    <IonPage>
      {hasNav && <NavBar title={title} {...navProps} />}
      {fixed && (
        <IonHeader>
          <div className="qk-base-page-fixed">{fixed}</div>
        </IonHeader>
      )}
      <BaseContent
        background={background || 'white'}
        contentBackground={contentBackground || 'transparent'}
      >
        {enableAnalysis ? (
          <AnalysisWrapper pageCode={eventCode}>{children}</AnalysisWrapper>
        ) : (
          children
        )}
      </BaseContent>
      {isLoading && (
        <Mask style={{ background: 'transparent' }}>
          <Spinner />
        </Mask>
      )}
      {bottomfixed && (
        <IonToolbar
          style={{
            '--background': 'transparent',
            '--padding-top': '0',
            '--padding-left': '0',
            '--padding-bottom': '0',
            '--padding-right': '0',
            '--padding-start': '0',
            '--padding-end': '0',
          }}
        >
          {<>{bottomfixed}</>}
        </IonToolbar>
      )}
    </IonPage>
  );
};
