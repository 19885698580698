import { useEffect, useMemo, useState } from 'react';

import { LableItem } from '../../const/type';

export type UseInitProps = {
  data?: LableItem[];
  labelKey: string;
};

export function useInit({ data, labelKey }: UseInitProps) {
  const [value, setValue] = useState<string>();
  const [list, setList] = useState<LableItem[]>();

  //初始化数据
  useEffect(() => {
    setList(data);
  }, [data]);

  //list更新显示数据
  const { rightLink, displayData } = useMemo(() => {
    const set = new Set<string>();
    const displayData = new Map<string, LableItem[]>();
    list?.forEach((item) => {
      const indexValue = item[labelKey].substring(0, 1);
      const indexData = displayData.get(indexValue) || [];
      indexData.push(item);
      displayData.set(indexValue, indexData);
      set.add(indexValue);
    });

    return { rightLink: Array.from(set), displayData: Array.from(displayData) };
  }, [list]);

  //搜索
  function handleChangeSearch(v?: string | null) {
    const filterData = data?.filter((val) => val[labelKey].indexOf(v) > -1);
    setList(filterData);
  }

  return {
    value,
    setValue,
    rightLink,
    displayData,
    handleChangeSearch,
  };
}
