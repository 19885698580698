import { Children, ReactNode } from 'react';
import clsx from 'clsx';

import { mergeProps } from '../../utils/with-default-props';
// import { getDefaultConfig } from '../config-provider';

import Button from '../button';
import { Label } from '../label';
import { Space } from '../space';

import { clear } from './clear';
import { show } from './show';
import './confirm.scss';

import { IModalProps as ModalProps } from './index';

export type ModalConfirmProps = Omit<ModalProps, 'visible' | 'closeOnAction' | 'actions'> & {
  title?: string;
  desc?: string;
  confirmText?: ReactNode;
  cancelText?: ReactNode;
  onConfirm?: () => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
};

const defaultProps = {
  title: 'Remind',
  desc: 'Loan can be transferred in 5 mins, are you sure you want to exit?',
  confirmText: 'Confirm',
  cancelText: 'Cancel',
  showCloseButton: false,
};

export function confirm(p: ModalConfirmProps) {
  // const { locale } = getDefaultConfig();
  const props = mergeProps(
    defaultProps,
    {
      // confirmText: locale.common.confirm,
      // cancelText: locale.common.cancel,
    },
    p
  );

  return new Promise<boolean>((resolve) => {
    show({
      ...props,
      onClose: () => {
        props.onClose?.();
        resolve(false);
      },
      children: (
        <Space direction="vertical" block align="center" style={{ '--gap-vertical': '16px' }} className='confirm-model'>
          <div className='confirm-title'>{props.title}</div>
          <div className='confirm-desc'>{props.desc}</div>
          <div  className={clsx('confirm-btn',!props.confirmText&&props.cancelText ? 'only' : '')}>
            {
                props.confirmText?
                <Button
                expand="full"
                color={'ghost'}
                className="qk-modal-button"
                onClick={async () => {
                  await props.onConfirm?.();
                  // props.onClose?.();
                  //TODO: 临时解决方案
                  clear();
                  resolve(true);
                }}
              >
                {props.confirmText}
              </Button>:<></>
              }
              <Button
                color={'ghost'}
                className="qk-modal-button2"
                onClick={async () => {
                  await props.onCancel?.();
                  // props.onClose?.();
                  //TODO: 临时解决方案
                  clear();
                  resolve(false);
                }}
              >
                {props.cancelText}
              </Button>
            </div>
          {/* <Label color="#333" style={{ fontSize: '20px' }} medium>
            {props.title}
          </Label>
          <Label color="#666" size="text">
            {props.desc}
          </Label>
          <Space direction="horizontal" justify="between" block>
            <Button
              expand="full"
              color={'ghost'}
              className="qk-modal-button"
              onClick={async () => {
                await props.onConfirm?.();
                // props.onClose?.();
                //TODO: 临时解决方案
                clear();
                resolve(true);
              }}
            >
              {props.confirmText}
            </Button>
            <Button
              color={'primary'}
              className="qk-modal-button"
              onClick={async () => {
                await props.onCancel?.();
                // props.onClose?.();
                //TODO: 临时解决方案
                clear();
                resolve(false);
              }}
            >
              {props.cancelText}
            </Button>
          </Space> */}
        </Space>
      ),
    });
  });
}
