import { IonHeader, IonToolbar, IonButtons, IonTitle } from '@ionic/react';

import './NavBar.scss';
import React, { CSSProperties } from 'react';

import { NavBackButton } from './NavBackButton';

export type NavBarItemProps = {
  icon?: any;
  onClick?: () => void;
};

export type NavBarProps = {
  /**
   * 标题
   */
  title?: string;
  /**
   * 字体颜色、返回按钮颜色， dark | light
   * 默认 dark
   */
  tintColor?: 'dark' | 'light';
  /**
   * 导航栏背景色
   */
  background?: string;
  /**
   * 是否border
   */
  hasBorder?: boolean;
  /**
   * 是否border
   */
  shadow?: 'none' | string;
  /**
   * 是否需要返回按钮，通过 push 的会自动添加上 backbutton，点击后会返回上一个页面，也可以自定义设置 defaultHref
   * 默认true
   */
  hasBackButton?: boolean;
  /**
   * 自定义设置返回按钮 defaultHref
   */
  backButtonDefaultHref?: string;
  /**
   * 自定义设置返回按钮点击事件，设置后backButtonDefaultHref会失效
   */
  backButtonOnclick?: (e: any) => void;
  /**
   * 自定义导航栏左边按钮
   */
  leftItems?: React.ReactNode[];
  /**
   * 自定义导航栏右边按钮
   */
  rightItems?: NavBarItemProps[];
  /**
   * 向下扩展导航栏的内容
   */
  expandItem?: React.ReactElement | any;
  /**
   * 自定义元素
   */
  children?: any;
  /**
   * 自定义样式
   */
  customStyles?: CSSProperties;
};

export function NavBar(props: NavBarProps) {
  const {
    title,
    hasBorder,
    hasBackButton = true,
    shadow = '0px 4px 6px 0px rgba(0,0,0,0.04)',
    tintColor = 'dark',
    background = 'var(--qk-color-light)',
    backButtonDefaultHref,
    backButtonOnclick,
    leftItems,
    rightItems,
    expandItem,
    children,
    customStyles,
  } = props;
  const styles: CSSProperties | any = { '--background': 'transparent' };
  if (!hasBorder) {
    styles['--border-color'] = 'transparent';
    styles['--border-width'] = '0';
  }
  if (shadow) {
    styles.boxShadow = shadow;
  }
  return (
    <IonHeader style={{ background: background }}>
      <IonToolbar id="qk-navbar" style={{ ...styles, ...customStyles }}>
        <IonButtons slot="start">
          {hasBackButton && (
            <NavBackButton
              color={tintColor}
              defaultHref={backButtonDefaultHref}
              onClick={backButtonOnclick}
            />
          )}
          {leftItems &&
            leftItems.map((item, i) => {
              return <React.Fragment key={`navbar-left-${i}`}>{item}</React.Fragment>;
            })}
        </IonButtons>
        {children}
        {title && (
          <IonTitle id="qk-navbar-title" color={tintColor}>
            {title}
          </IonTitle>
        )}
        <IonButtons slot="end">
          {rightItems &&
            rightItems.map((item, i) => {
              return <React.Fragment key={`navbar-right-${i}`}>{item}</React.Fragment>;
            })}
        </IonButtons>
      </IonToolbar>
      {expandItem}
    </IonHeader>
  );
}
