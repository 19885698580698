import { userStorage } from '@quark-base-plugin/storage';
import { NativeBridge } from '@/native';
import { AppInfoResult, DeviceResult } from '@/native/plugins/app';
import { AnalysisThirdEventName } from '@quark-base-plugin/analysis';

let deviceInfo: DeviceResult | undefined;
let appInfo: AppInfoResult | undefined;

export async function H5logEvent(event: any): Promise<boolean> {
  const user = await userStorage.loadUser();
  if (!deviceInfo) {
    try {
      deviceInfo = (await NativeBridge.Plugins.appPlugin.getEquipmentInfo()).data;
    } catch (error) {
      console.log('Error');
    }
  }
  if (!appInfo) {
    try {
      appInfo = (await NativeBridge.Plugins.appPlugin.aboutApp()).data;
    } catch (error) {
      console.log('Error');
    }
  }
  const customData = {
    eventName: event,
    params: {
      jzDeviceId: deviceInfo?.deviceId || '',
      jzVersion: appInfo?.version,
      jzWaistcoatId: appInfo?.appId,
      jzPlatform: appInfo?.platform,
      jzMemberId: user?.memberId,
    },
  };
  console.log('【log event】: ', event, JSON.stringify(customData));
  adjustEvent(customData);
  faceBookEvent(customData);
  return true;
}

function faceBookEvent(event: { eventName: string; params: any }) {
  NativeBridge.Plugins.eventPlugin.faceBookEvent(event);
}

function adjustEvent(event: { eventName: string; params: any }) {
  // 设置 Adjust 提供的识别码，只有对应的事件才发送 Adjust 埋点
  const keyMap: Record<string, any> = {
    [AnalysisThirdEventName.CustomAppStart]: 'ow1u8t',
    [AnalysisThirdEventName.CustomFirstOpen]: '30ls61',
    [AnalysisThirdEventName.CustomRegistered]: 'i77bup',
    [AnalysisThirdEventName.CustomSubmitApplication]: 't3s30n',
    [AnalysisThirdEventName.CustomNewSubmitApplication]: '6skhx1',
  };
  if (keyMap[event.eventName]) {
    const data = {
      eventName: keyMap[event.eventName],
      params: event.params,
    };
    console.log('【log event】adjust: ', event, JSON.stringify(data));
    NativeBridge.Plugins.eventPlugin.adjustEvent(data);
  }
}
