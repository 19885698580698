import { NativeResult } from '../call';
import { BaseNativePlugin } from './base';

// eslint-disable-next-line no-irregular-whitespace
// code -2：LICENSE  过期
// code -3：app ID 不匹配
// code -4：相机没给权限
// code -5：识别失败,第三方会返回一个失败的字典，不清楚具体有什么，所以就把它转json放message里了
export type FacePluginType = {
  startVerify(options: {
    // advance的license
    license: string;
  }): Promise<
    NativeResult<{
      livenessId: string;
      bestImg: string; //  图片base64
      height: number; // 图片高
      width: number; // 图片宽
    }>
  >;
};

export class FacePlugin extends BaseNativePlugin implements FacePluginType {
  startVerify(options: {
    // advance的license
    license: string;
  }): Promise<NativeResult<{
    livenessId: string;
    bestImg: string;
    height: number; // 图片高
    width: number; // 图片宽
  }>> {
    return super.callPlugin<{
      livenessId: string; bestImg: string; //  图片base64
      height: number; // 图片高
      width: number; // 图片宽
    }>('FacePlugin', 'startVerify', options);
  }
}
