import { NativeResult } from '../call';
import { AppPermissionResult, AppPermissionState, BaseNativePlugin } from './base';

export type CameraPluginType = {
  checkPermission(): Promise<NativeResult<AppPermissionResult>>;

  requestPermission(): Promise<
    NativeResult<AppPermissionResult>
  >;

  startCamera(options?: {
    // 可以不传，传0～1之间, 是一个系统压缩后的质量的值
    qualit?: number;
    // 是否开启前置摄像头
    isFront?: boolean;
  }): Promise<
    NativeResult<{
      img: string;
    }>
  >;
};

export class CameraPlugin extends BaseNativePlugin implements CameraPluginType {
  checkPermission(): Promise<NativeResult<AppPermissionResult>> {
    return super.callPlugin<AppPermissionResult>(
      'CameraPlugin',
      'checkPermission',
    );
  }
  requestPermission(): Promise<NativeResult<AppPermissionResult>> {
    return super.callPlugin<AppPermissionResult>(
      'CameraPlugin',
      'requestPermission',
    );
  }
  startCamera(options?: {
    // 可以不传，传0～1之间, 是一个系统压缩后的质量的值
    qualit?: number | undefined;
    isFront?: boolean | undefined;
  } | undefined): Promise<NativeResult<{ img: string; }>> {
    return super.callPlugin<{ img: string; }>('CameraPlugin', 'startCamera', options,()=>{console.log('noapp');
    });
  }

}
