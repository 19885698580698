import { storage } from './storage';

const _USER_KEY = '_user';
const _TOKEN_KEY = '_token';

export const userStorage = {
  loadUser: async () => {
    const value = await storage.get(_USER_KEY);
    if (!value) {
      return null;
    }
    return JSON.parse(value!);
  },
  setUser: async (value: any) => {
    if (!value) return;
    await storage.set(_USER_KEY, JSON.stringify(value));
  },
  clearUser: async () => {
    await storage.remove(_USER_KEY);
  },

  getToken: async () => {
    const value = await storage.get(_TOKEN_KEY);
    return value;
  },
  setToken: async (token: string) => {
    await storage.set(_TOKEN_KEY, token);
  },
  clearToken: async () => {
    await storage.remove(_TOKEN_KEY);
  },
};
