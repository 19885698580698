import { createErrorBlock } from '@quark-base-plugin/core/src/components/error';
import { ReactElement } from 'react';

import imageDisconnect from './disconnect-img.png';
import imageError from './error-img.png';
import imageRecordLoan from './record-empty.png';
import imageRecordRepay from './record-empty.png';
import imageEmpty from './record-empty.png';

const imageRecord: Record<
  'default' | 'disconnected' | 'error' | 'loanRecordEmpty' | 'repayRecordEmpty',
  ReactElement
> = {
  default: <img src={imageEmpty} alt="" />,
  disconnected: <img src={imageDisconnect} alt="" />,
  error: <img src={imageError} alt="" />,
  loanRecordEmpty: <img src={imageRecordLoan} alt="" style={{ width: '150px', height:'150px' }} />,
  repayRecordEmpty: <img src={imageRecordRepay} alt=""  style={{ width: '150px', height:'150px' }}/>,
};

export const CustomErrorBlock = createErrorBlock(imageRecord);
