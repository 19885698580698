import { IonIcon } from '@ionic/react';
import { close } from 'ionicons/icons';
import React, { useMemo, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@quark-base-plugin/core';

import './index.scss';
import { LableItem } from '../../../const/type';
import { Modal } from '../../modal';
import PickerView, { Props as PickerViewProps } from '../../pickerView/pickerView';

export type PickerProps = Omit<PickerViewProps, 'onChange' | 'value'> & {
  className?: string;
  shouldRenderValue?: boolean; // 是否显示当前value
  title?: React.ReactNode;
  onBlur?: (e: any) => void;
  onChange?: (value: any) => void;
  value?: string | number;
  suffix?: React.ReactNode;
};

const classPrefix = 'qk-piker';

export default React.forwardRef(function Picker(props: PickerProps, onRef: any) {
  const {
    // value: testV,
    valueKey = 'id',
    labelKey = 'name',
    shouldRenderValue = true,
    onChange,
    onBlur,
    title,
    value,
    suffix,
    ...others
  } = props;

  const [visible, setVisible] = React.useState<boolean>(false);

  const [selectedValue, setSelectedValue] = useState(value);

  const { t } = useTranslation();

  const nativeRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(onRef, () => ({
    //实现聚焦显示Modal
    focus: () => {
      setVisible(true);
      // nativeRef.current?.scrollIntoView({ block: 'center' });
      // @ts-ignore
      props?.onFocus?.();
    },
    get nativeElement() {
      return nativeRef.current;
    },
  }));

  const labelValue = useMemo(() => {
    //初始化数据
    const item = others.data?.find((v) => v[valueKey] + '' === value + '');
    return item;
  }, [value, others.data]);

  function handleChange(e: any) {
    // onChange?.(val[valueKey]);
    onChange?.(selectedValue);
    setVisible(false);
    onBlur?.(e);
  }
  function handleClose(e: any) {
    e.stopPropagation();
    setVisible(false);
    onBlur?.(e);
  }

  function renderModal() {
    return (
      <div className={`${classPrefix}-container`}>
        <Modal
          className={`${classPrefix}-container-modal`}
          align="bottom"
          visible={visible}
          showCloseButton={false}
        >
          <div className={`${classPrefix}-container-header`}>
            <span
              className={`${classPrefix}-container-header-cancel`}
              onClick={(e) => {
                handleClose(e);
              }}
            >
              {t('cancel')}
            </span>
            {title}
            {/* <IonIcon
              icon={close}
              className={`${classPrefix}-container-header-close`}
              onClick={handleClose}
            /> */}
            <span
              className={`${classPrefix}-container-header-confirm`}
              onClick={(e) => {
                handleChange(e);
              }}
            >
              {t('confirm')}
            </span>
          </div>
          <PickerView
            valueKey={valueKey}
            onChange={(val) => {
              setSelectedValue(val);
            }}
            value={selectedValue}
            {...others}
          />
        </Modal>
      </div>
    );
  }

  return (
    <>
      <div ref={nativeRef} className={classPrefix}>
        {shouldRenderValue && labelValue && <span onClick={() => setVisible(true)}>{labelValue[labelKey]}</span>}
        {suffix &&
          <button style={{ backgroundColor: 'transparent' }} onClick={() => setVisible(true)}>{suffix}</button>
        }
      </div>
      {renderModal()}
    </>
  );
});
