import { Modal, Button } from '@quark-base-plugin/core/index';
import { useTranslation } from 'react-i18next';
import tipsImage from '@/assets/apply/takePhoto-tips.png';

const classPrefix = 'qk-TakePhoto';

export type TakePhotoTipsProps = {
  onClose: () => void,
  visible: boolean,
  onOk: () => void
}

export default function TakePhotoTips(props: TakePhotoTipsProps) {
  const { onClose, visible, onOk } = props;
  const { t } = useTranslation();

  return (
    <Modal
      onClose={onClose}
      visible={visible || false}
      closeOnMaskClick
      align="center"
      className="takePhoto-tips-modal"
      showCloseButton={false}
    >
      <div  className='takePhoto-tips'>
        <div className='takePhoto-tips-title'>
          Ejemplo de carga de documentos
        </div>
        <div className='takePhoto-tips-content'>
          <div className='content-photo'>
            <div className='content-photo-img'>
              <img src={tipsImage} alt="" />
            </div>
            <div className='tips'>
              <div className='tips-item'>
                <span></span><span>{t('takePhotoTips1')}</span>
              </div>
              <div className='tips-item'>
                <span></span><span>{t('takePhotoTips2')}</span>
              </div>
              <div className='tips-item'>
                <span></span><span>{t('takePhotoTips3')}</span>
              </div>
            </div>
          </div>
          <div className='to-take'>
            <Button
              onClick={async () => {
                const result = await onOk();
                return result;
              }}
              className={`${classPrefix}-tips-ok-btn`}
            >
              { t('OK') }
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}