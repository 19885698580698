export default {
  verificationCode: 'Código de verificación',
  contactNumber: 'Número de contacto',
  pleaseEnterPhoneNumber: 'Por favor ingrese el número de teléfono',
  pleaseEnterValidPhoneNumber: 'Por favor ingrese un número valido',
  verificationCodeError: 'Por favor ingresa el código de verificación correcto',
  logIn: 'Acceso',
  send: 'Enviar',
  haveRead: 'He leído y acepto la ',
  termsConditions: 'Términos',
  andThe: 'y con la',
  privacyPolicy: 'Política de Privacidad',
  invalidPhoneNumber: 'Número de teléfono incorrecto',
  incorrectVerificationCode: 'Código de verificación incorrecto',
  codeHeader: 'Por favor, ingrese el código SMS.',
  codeHeader2: 'El código de verificación SMS ha sido enviado a: {{phone}}',
  countDown: 'Reintentar después de {{count}}s',
  reSendCode: 'Reenviar código SMS',
  usePasswordLogin: 'Usar contraseña de inicio de sesión',
  welcomeMeg:
    'Bienvenido/a a N Money. Inicia sesión o regístrate con tu número de teléfono y código SMS.',
  phoneNumber: 'Número de teléfono',
  password: 'Contraseña',
  yourPassword: 'Tu contraseña',
  yourPasswordPlaceholder: 'Por favor establezca su contraseña',
  confirmPassword: 'Confirmar la contraseña',
  ConfirmPasswordPlaceholder: 'Ingrese la contraseña establecida nuevamente',
  passwordsNotMatch: 'Las contraseñas ingresadas no coinciden, por favor verifica.',
  phoneNumPlaceholder: 'Por favor, introduzca su número de teléfono',
  passwordPlaceholder: 'Por favor, ingresa tu contraseña.',
  loginNext: 'Siguiente',
  setPassword: 'Configurar la clave',
  setPasswordTip:
    'Por favor, ingrese una contraseña que pueda recordar. Ingrésela dos veces para asegurarse de que la recuerde correctamente.',
  getCode: 'obtener código de verificación',
  getNewCode: 'Obtener un nuevo código de verificación',
  passwordRequires: 'Por favor ingresa una contraseña de entre 6 y 20 caracteres.',
  sMSCode: 'código SMS',
  pleaseSMSCode: 'Por favor introduce el código SMS',
  notGettingCode: '¿No obtienes el código?',
  canNotCode: '¿No puedes obtener el código?',
  chooseTryAgain:
    'Puede elegir cualquiera de las dos opciones siguientes para volver a intentarlo.',
  smsVerificationCode: 'Código de verificación por SMS',
  voiceVerificationCode: 'Código de verificación de voz',
  //新
  useSMSCodeLogin: 'Código de verificación por SMS',
  accessPassword: 'Contraseña de acceso',
  forgotPassword: 'olvida la contraseña',
};
