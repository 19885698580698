import { Http, HttpResponse } from '@quark-base-plugin/net';
import { useRequest } from 'ahooks';

import { useApplyStepModule, UseApplyStepModuleProps } from '../step';

import { WithdrawInfoProps, PaymentChannel } from './type';

type UseApplyStepWithdrawProps = UseApplyStepModuleProps<WithdrawInfoProps>;

//保存收款信息
export async function onSaveWithdraw(params: WithdrawInfoProps) {
  return await Http.post('/member/bank-info/save', params);
}
//获取放款渠道
export async function onLoadPaymentChannel(): Promise<HttpResponse<PaymentChannel[]>> {
  return Http.post('/pay/payment-channel');
}

//加载收款信息
export async function onLoadWithdraw(): Promise<WithdrawInfoProps> {
  const result = await Http.post('/member/bank-info/load');
  return result?.data;
}

export function useApplyStepWithdraw({ defaultValues, ...others }: UseApplyStepWithdrawProps) {
  const loadChannelList = useRequest(onLoadPaymentChannel);

  const { formRef, ...step } = useApplyStepModule({
    code: 'useApplyStepWithdraw',
    defaultValues,
    onLoad: onLoadWithdraw,
    onSave: onSaveWithdraw,
    ...others,
  });

  return {
    formRef,
    ...step,
    loadChannelList,
  };
}
