import Search from '../search';

import List from './List';

import { NavBar } from '../navbar';
import { IModalProps } from '../modal';

import { IonIcon } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import React, { useMemo } from 'react';

import './index.scss';
import { LableItem } from '../../const/type';
import { Popup } from '../popup/popup';

import { useInit, UseInitProps } from './hooks';
import { t } from 'i18next';

type Props = Omit<IModalProps, 'visible' | 'children'> & {
  setfocused?: (v: boolean) => void;
  valueKey?: string;
  labelKey?: string;
  onBlur?: (e: React.MouseEvent<HTMLIonIconElement, MouseEvent>) => void;
  onChange?: (v: any) => void;
  loading?: boolean;
  value?: string;
  title?: string;
} & Omit<UseInitProps, 'labelKey'>;

const classPrefix = 'qk-cascader';

export default React.forwardRef(function Cascader(props: Props, onRef: any) {
  const {
    labelKey = 'name',
    value,
    data,
    title,
    valueKey = 'id',
    onChange,
    onBlur,
    loading,
  } = props;
  const [visible, setVisible] = React.useState<boolean>(false);

  const { setValue, rightLink, handleChangeSearch, displayData } = useInit({ data, labelKey });
  React.useImperativeHandle(onRef, () => ({
    focus: () => {
      setVisible(true);
      //@ts-ignore
      props.onFocus?.();
    },
  }));

  const label = useMemo(() => {
    const item = data?.find((val) => val[valueKey] === value);
    return item ? item[labelKey] : '';
  }, [data, labelKey, value, valueKey]);

  //选择并更新显示
  function handleChange(v: LableItem) {
    setValue(v[labelKey]);
    onChange && onChange(v[valueKey]);
    setVisible(false);
    //@ts-ignore
    onBlur?.(undefined);
  }

  //关闭弹窗
  function handleClose(e: React.MouseEvent<HTMLIonIconElement, MouseEvent>) {
    e.stopPropagation();
    setVisible(false);
    onBlur?.(e);
  }

  const navBarProps = {
    title,
    hasBackButton: false,
    leftItems: [
      // @ts-ignore
      <IonIcon
        key="colse"
        onClick={handleClose}
        icon={closeOutline}
        className={`${classPrefix}-close`}
      />,
    ],
  };

  return (
    <div className={classPrefix}>
      <span>{`${label}`}</span>
      <Popup visible={visible}>
        <div className={`${classPrefix}-popup-container`} style={{ height: window.innerHeight }}>
          <div className={`${classPrefix}-popup-container-top`}>
            <NavBar {...navBarProps} />
            <Search
              onIonInput={(e) => handleChangeSearch(e.target.value)}
              className={`${classPrefix}-popup-container-search`}
              placeholder={t('search')}
            />
          </div>
          <List
            loading={loading}
            select={value}
            list={displayData}
            rightLink={rightLink}
            onChange={handleChange}
            labelKey={labelKey}
          />
        </div>
      </Popup>
    </div>
  );
});
