export default {
  noRepayment: 'You do not currently have to make a repayment',
  repaymentSMS: 'Repayment date SMS reminders, with the option for early repayment',
  repaymentAmount: 'Repayment Amount',
  borrowingDate: 'Borrowing Date',
  totalDuePeriod: 'Total Due this Period',
  totalDuePeriod2: 'Total payable invoice amount',
  principal: 'Principal',
  serviceFee: 'Service Fee',
  interest: ' Interest',
  receivedAmount: 'Received Amount',
  lateFee: 'Late Fee',
  overdueDays: 'Number of Days Overdue',
  orderNumber: 'Order Number',
  penaltyInterest: 'Penalty interest',
  overdue: 'Overdue',
  rollback: 'Rollback',
  finished: 'Finished',
  refuse: 'Refuse',
  refuseExpire: 'Refuse expire',
  underReview: 'Under Review',
  success: 'Success',
  failure: 'Failure',
  repaymentDate: 'Repayment Date',
  repayment: 'Repayment',
  payout: 'Payout',
  paymentNow: 'Make Payment Now',
  latestPaymentDate: 'Latest payment date',
  repaymentMethod: 'Repayment Method',
  repaymentTips: 'The payment amount must not be less than 100',
  copySuc: 'Copied',
  repayInfo1: 'Total amount payable',
  details: 'details',
  loanDetails: 'detalles del préstamo >',
  loanInformation: 'Loan information',
  duePeriod: 'Due in this period',
  repaymentPlan: 'Repayment plan',
  viewMore: 'View more',
  putitAway: 'Put it away',
  repayDetailInfo1:
    'Your bill is past due. Overdue bills will incur additional charges. Please repay it promptly. ',
  repayDetailInfo2: 'After payment, you will be able to enjoy more loan services.',
  repayDetailInfo3:
    'Your bill has expired. Overdue invoices will incur additional fees, please pay as soon as possible',
  addRepay1: 'Details',
  addRepay2: 'Loan Amount',
  addRepay3: 'Borrowing Term',
  addRepay4: 'Loan Period',
  addRepay5: 'Total Amount Due This Period',
  addRepay6: 'Extension fee',
  addRepay7: 'Period',
  addRepay8: 'Weeks / Period',
  addRepay9: 'Current/Total'
};
