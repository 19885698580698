import { clear, isShowed } from './clear';
import { confirm } from './confirm';
import { IModal as Modal } from './Modal';
import { show } from './show';

import type { IModalProps } from './Modal';

const ModalPresent = {
  show,
  clear,
  confirm,
  isShowed,
};

export { IModalProps, Modal, ModalPresent };