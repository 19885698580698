import { Space, Label, Button, ModalPresent } from '@quark-base-plugin/core';
import { clear } from '@quark-base-plugin/core/src/components/modal/clear';

import './index.scss';

const classPrefix = 'custom-close-modal';

export type CustomModalProps = {
  title?: string;
  desc?: string | React.ReactNode;
  confirmText?: string;
  cancelText?: string;
  icon?: any;
  logo?: any;
  onConfirm?: () => void;
  onCancel?: () => void;
};

export function showCustomModal(props: CustomModalProps) {
  const { confirmText, cancelText } = props;
  const children = (
    <>
      <Space direction="vertical" block align="center" style={{ '--gap-vertical': '0px' }}>
        <div className={`${classPrefix}-top`}>
          {props.icon && <img src={props.icon} alt="" className="custom-modal-bg" />}
          <Space direction="vertical" block style={{ '--gap-vertical': '10px' }}>
            {props.logo && <img src={props.logo} alt="" className="logo" />}
            {props.title && (
              <Label color="#333333" bold size="subtitle">
                {props.title}
              </Label>
            )}
            {props.desc && (
              <Label color="#7C8187" size="text" medium>
                {props.desc}
              </Label>
            )}
          </Space>
        </div>
        <div className={`${classPrefix}-bottom`}>
          <Space block className="flex justify-between">
            <Button
              className={`${classPrefix}-button-cancel`}
              style={{ '--background-activated': 'transparent' }}
              onClick={async () => {
                await props.onCancel?.();
                // props.onClose?.();
                //TODO: 临时解决方案
                clear();
              }}
            >
              <div className="custom-modal-cancel">{cancelText}</div>
            </Button>
            <Button
              expand="full"
              className={`${classPrefix}-button-confirm`}
              style={{ '--background-activated': 'transparent' }}
              onClick={async () => {
                await props.onConfirm?.();
                // props.onClose?.();
                //TODO: 临时解决方案
                clear();
              }}
            >
              {confirmText}
            </Button>
          </Space>
        </div>
      </Space>
    </>
  );

  ModalPresent.show({
    align: 'center',
    showCloseButton: false,
    children,
    className: classPrefix,
  });
}
