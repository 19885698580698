import { Encrypt, Decrypt } from './aes';
export const isEncyptedResult = ({ data, flag, secreKey, ivStr }: any) => {
    if (flag) {
        return Encrypt(data, secreKey, ivStr);
    } else {
        return data
    }
};
export const isDecryptResult = ({ data, flag, secreKey, ivStr }: any) => {
    if (flag) {
        if(typeof data == 'string'){
            return JSON.parse(Decrypt(data, secreKey, ivStr));
        }else{
            return data
        }
      
    } else {
        return data
    }
}